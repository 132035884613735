import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Papa from 'papaparse';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import logo from '../../assets/logo.png';
import Footer from "../../components/footer/Footer";
import { AlertCircle } from 'lucide-react';

const popularServices = [
  { name: 'Gmail', host: 'smtp.gmail.com' },
  { name: 'Outlook', host: 'smtp-mail.outlook.com' },
  { name: 'Yahoo', host: 'smtp.mail.yahoo.com' },
  { name: 'Custom', host: '' }
];

const ProspectPage = () => {
  const [prospects, setProspects] = useState([]);
  const [productOrService, setProductOrService] = useState('');
  const [features, setFeatures] = useState('');
  const [painPoint, setPainPoint] = useState('');
  const [emailStyle, setEmailStyle] = useState('');
  const [loadingProspects, setLoadingProspects] = useState({});
  const [error, setError] = useState('');
  const [emailsLeft, setEmailsLeft] = useState(0);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const { userInfo } = useSelector((state) => state.auth);
  const [showCsvInfoPopup, setShowCsvInfoPopup] = useState(false);
  const [showBetaInfoPopup, setShowBetaInfoPopup] = useState(true);
  const [showInputMissingPopup, setShowInputMissingPopup] = useState(false);
  const [showAddProspectPopup, setShowAddProspectPopup] = useState(false);
  const [newProspect, setNewProspect] = useState({ name: '', website: '', email: '' });
  const [generatingAll, setGeneratingAll] = useState(false);
  const [generationProgress, setGenerationProgress] = useState(0);
  const [showConfigureDomainPopup, setShowConfigureDomainPopup] = useState(false);
  const [selectedEmailForSending, setSelectedEmailForSending] = useState(null);
  const [editableEmail, setEditableEmail] = useState({ subject: '', body: '' });
  const [view, setView] = useState('main');
  const [domains, setDomains] = useState([]);
  const [selectedService, setSelectedService] = useState(popularServices[0]);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [customHost, setCustomHost] = useState('');


  useEffect(() => {
    const fetchEmailsLeft = async () => {
      const userId = userInfo._id;
      try {
        const response = await axios.post('https://web-production-5ee8.up.railway.app/echidna/el', { userId: userId });
        setEmailsLeft(response.data.mails);
      } catch (error) {
        console.error('Error fetching emails left:', error);
        setError('Error fetching emails left');
      }
    };
    
    fetchEmailsLeft();
  }, [userInfo._id]);

  const isValidUrl = (string) => {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  };
  const generateUniqueId = (prospect) => {
    return `${prospect.name}-${prospect.website}`;
  };

  const handleSendEmail = (prospect) => {
    setSelectedEmailForSending(prospect);
    setEditableEmail(prospect.outreach);
  };

  const handleEditEmail = (field, value) => {
    setEditableEmail(prev => ({ ...prev, [field]: value }));
  };

  

  const handleConfirmSendEmail = async () => {
    if (!selectedEmailForSending) return;
  
    try {
      const response = await axios.post('https://web-production-5ee8.up.railway.app/echidna/sendEmail', {
        to: selectedEmailForSending.email,
        subject: editableEmail.subject,
        body: editableEmail.body,
        userId: userInfo._id,
        prospectData: {
          name: selectedEmailForSending.name,
          website: selectedEmailForSending.website,
          email: selectedEmailForSending.email
        }
      });
  
      if (response.data.success) {
        alert('Email sent successfully!');
        setSelectedEmailForSending(null);
      } else {
        alert(` ${response.data.message}`);
      }
    } catch (error) {
      console.error('Error sending email:', error);
      if (error.response && error.response.data && error.response.data.message) {
        alert(`An error occurred while sending the email: ${error.response.data.message}`);
      } else {
        alert('An error occurred while sending the email. Please try again.');
      }
    }
  };
  
  
 
  

  const ConfigureDomainPopup = ({ setShowConfigureDomainPopup }) => {
  
    useEffect(() => {
      if (view === 'viewDomains') {
        fetchDomains();
      }
    }, [view]);
  
    const fetchDomains = async () => {
      try {
        const response = await fetch('/api/domains');
        if (!response.ok) throw new Error('Failed to fetch domains');
        const data = await response.json();
        setDomains(data);
      } catch (err) {
        setError('Failed to fetch domains. Please try again.');
      }
    };
  }
  
    const handleAddDomain = async () => {
      try {
        const response = await fetch('https://web-production-5ee8.up.railway.app/auth/configuredomain', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            email,
            password,
            host: selectedService.name === 'Custom' ? customHost : selectedService.host,
            userId: userInfo._id,

          }),
        });
        if (!response.ok) throw new Error('Failed to add domain');
        setView('main');
        setError('');
      } catch (err) {
        setError('Failed to add domain. Please check your inputs and try again.');
      }
    };
  
    const CustomAlert = ({ error }) => {
      return (
        <div className="flex items-center mt-4 p-4 border-l-4 border-red-500 bg-red-100 rounded-md">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 text-red-600 mr-2"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 8v4m0 4h.01m-6.938 4h13.856C19.346 21.657 20 20.978 20 20.156V7.844c0-.822-.654-1.5-1.482-1.5H5.482C4.654 6.344 4 7.022 4 7.844v12.312c0 .822.654 1.5 1.482 1.5z"
            />
          </svg>
          <div>
            <strong className="text-red-600">Error: </strong>
            <p className="text-red-600">{error}</p>
          </div>
        </div>
      );
    };
    

  const copyToClipboard = (email) => {
    const fullEmail = `Subject: ${email.subject}\n\nBody:\n${email.body}`;
    navigator.clipboard.writeText(fullEmail)
      .then(() => {
        alert('Email copied to clipboard!');
      })
      .catch((err) => {
        console.error('Could not copy text: ', err);
        alert('Failed to copy email. Please try again.');
      });
  };

  const downloadCSV = () => {
    const data = prospects.map(prospect => ({
        Name: prospect.name,
        Website: prospect.website,
        'Generated Email': prospect.outreach || ''
    }));

    const csv = Papa.unparse(data);

    const downloadLink = document.createElement('a');
    downloadLink.setAttribute('href', `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`);
    downloadLink.setAttribute('download', 'prospects.csv');
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
}
const openDemo = () => {
  window.open('https://youtu.be/slMYbAwTOZY', '_blank');
};
  

  const generateAllEmails = async () => {
    if (!productOrService || !features || !painPoint) {
      setShowInputMissingPopup(true);
      return;
    }

    setGeneratingAll(true);
    setGenerationProgress(0);
    setError('');

    const totalProspects = prospects.filter(p => !p.isGenerated && !p.error).length;
    let completedProspects = 0;

    for (const prospect of prospects) {
      if (!prospect.isGenerated && !prospect.error) {
        await generateEmail(prospect);
        completedProspects++;
        setGenerationProgress((completedProspects / totalProspects) * 100);
      }
    }

    setGeneratingAll(false);
    setGenerationProgress(0);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      Papa.parse(file, {
        complete: (results) => {
          const newProspects = results.data
            .filter(row => row.length >= 2 && row[0] && row[1]) // Ensure row has at least 2 non-empty values
            .map((row) => ({
              name: row[0],
              website: isValidUrl(row[1]) ? row[1] : '',
              outreach: '',
              isGenerated: false,
              id: generateUniqueId({ name: row[0], website: row[1] })
            }));
          setProspects(newProspects);
        },
        header: false,
        error: (error) => {
          console.error('Error parsing CSV:', error);
          setError('Failed to parse CSV file. Please check the file format.');
        }
      });
    } else {
      setError('No file selected. Please choose a CSV file.');
    }
  };
  

  const generateEmail = async (prospect) => {
    if (!prospect.name || !prospect.website || !productOrService || !features || !painPoint) {
      setShowInputMissingPopup(true);
      return;
    }

    const prospectId = generateUniqueId(prospect);
    setLoadingProspects(prev => ({ ...prev, [prospectId]: true }));
    setError('');
    try {
      const userId = userInfo._id;
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), 40000);

      const response = await axios.post('https://web-production-5ee8.up.railway.app/echidna/outreachGen', {
        prospectName: prospect.name,
        companyWebsite: prospect.website,
        productOrService,
        features,
        painPoint,
        emailStyle,
        userId: userId,
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
        signal: controller.signal
      });

      clearTimeout(timeoutId);

      setProspects(prevProspects => prevProspects.map((p) =>
        p.id === prospectId ? { 
          ...p, 
          outreach: { 
            subject: response.data.subject, 
            body: response.data.body 
          }, 
          isGenerated: true, 
          error: null 
        } : p
      ));
      setEmailsLeft(prevEmailsLeft => prevEmailsLeft - 1);
    } catch (err) {
      console.error('Error:', err.response ? err.response.data : err.message);
      setProspects(prevProspects => prevProspects.map((p) =>
        p.id === prospectId ? { ...p, error: 'Failed to generate email' } : p
      ));
    } finally {
      setLoadingProspects(prev => ({ ...prev, [prospectId]: false }));
    }
  };
  

  const regenerateEmail = async (prospect) => {
    const updatedProspect = { ...prospect, outreach: '', isGenerated: false };
    const updatedProspects = prospects.map(p => 
      p.id === prospect.id ? updatedProspect : p
    );
    setProspects(updatedProspects);
    await generateEmail(updatedProspect);
  };

  const addManualProspect = () => {
    setShowAddProspectPopup(true);
  };

  const deleteProspect = (prospectToDelete) => {
    setProspects(prospects.filter(prospect => prospect.id !== prospectToDelete.id));
  };



  const handleAddProspect = () => {
    if (newProspect.name && isValidUrl(newProspect.website) && isValidEmail(newProspect.email)) {
      const newProspectWithId = { 
        ...newProspect, 
        outreach: '', 
        isGenerated: false, 
        id: generateUniqueId(newProspect)
      };
      setProspects([...prospects, newProspectWithId]);
      setShowAddProspectPopup(false);
      setNewProspect({ name: '', website: '', email: '' });
    } else {
      alert('Please enter a valid name, website URL, and email address.');
    }
  };
  const isValidEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const sendEmail = async (prospect) => {
    if (!prospect.outreach) {
      alert('Please generate an email before sending.');
      return;
    }

    try {
      const response = await axios.post('https://web-production-5ee8.up.railway.app/echidna/send-email', {
        to: prospect.email,
        subject: prospect.outreach.subject,
        body: prospect.outreach.body,
        userId: userInfo._id
      });

      if (response.data.success) {
        alert('Email sent successfully!');
      } else {
        alert('Failed to send email. Please try again.');
      }
    } catch (error) {
      console.error('Error sending email:', error);
      alert('An error occurred while sending the email.');
    }
  };

  return (
    <div className="bg-gradient-to-br from-blue-50 to-white min-h-screen">
      <div className="container mx-auto px-4 py-8 sm:px-6 lg:px-8">
      <Link to="/" className="block mb-8" style={{ width: '48px', height: '48px' }}>
  <img src={logo} alt="Logo" className="h-12 w-12" />
</Link>

<div className="flex justify-between items-center mb-8">
          <div className="flex items-center space-x-4 ml-auto">
            <p className="text-gray-600">Emails left: {emailsLeft}</p>
            <a 
              href="/echidnamail/buycredits" 
              className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 transition-colors duration-150"
            >
              Get More Emails
            </a>
            <button 
              onClick={() => setShowConfigureDomainPopup(true)}
              className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors duration-150"
            >
              Configure Sending Domain Settings
            </button>
          </div>
        </div>

        
        
        
        {/* File upload section */}
        <div className="bg-white rounded-xl shadow-lg p-6 mb-8">
          <label className="flex items-center justify-center w-full h-32 px-4 transition bg-white border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none">
            <span className="flex items-center space-x-2">
              <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
              </svg>
              <span className="font-medium text-gray-600">
                Drop files to Attach, or
                <span className="text-blue-600 underline ml-1">browse</span>
              </span>
            </span>
            <input 
              type="file" 
              name="file_upload" 
              className="hidden" 
              accept=".csv" 
              onChange={handleFileUpload} 
              onClick={() => setShowCsvInfoPopup(true)}
            />
          </label>
        </div>
        
        {/* Input fields */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Explain your product or service that you wish to pitch</label>
            <input 
              type="text" 
              value={productOrService} 
              onChange={(e) => setProductOrService(e.target.value)}
              className="mt-1 block w-full rounded-md bg-white border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50" 
            />
          </div>
          
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Features of your product/service (20-45 words)</label>
            <textarea 
              value={features} 
              onChange={(e) => setFeatures(e.target.value)}
              className="mt-1 block w-full rounded-md bg-white border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50" 
              rows="3"
            />
          </div>
          
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Pain Point to be fixed by your product</label>
            <input 
              type="text" 
              value={painPoint} 
              onChange={(e) => setPainPoint(e.target.value)}
              className="mt-1 block w-full rounded-md bg-white border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50" 
            />
          </div>
          
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Additional information you wish to provide (optional)</label>
            <input 
              type="text" 
              value={emailStyle} 
              onChange={(e) => setEmailStyle(e.target.value)}
              className="mt-1 block w-full rounded-md bg-white border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50" 
            />
          </div>
        </div>
        <div className="flex space-x-4">
  <button 
    onClick={addManualProspect}
    className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors duration-150"
  >
    Manually add prospect
  </button>
  <button 
    onClick={generateAllEmails}
    disabled={generatingAll}
    className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 transition-colors duration-150 disabled:bg-gray-400"
  >
    {generatingAll ? `Generating All Emails (${Math.round(generationProgress)}%)` : 'Generate All Emails'}
  </button>
  <button
    onClick={downloadCSV}
    className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors duration-150"
  >
    Download Generated Emails (CSV)
  </button>
</div>


        {/* Prospects table */}
        <div className="bg-white rounded-xl shadow-lg overflow-hidden">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Website</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {prospects.map((prospect) => (
                <tr key={prospect.id}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{prospect.name}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{prospect.website}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{prospect.email}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
              <div className="flex space-x-2">
                {loadingProspects[prospect.id] ? (
                  <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-blue-500"></div>
                ) : prospect.isGenerated ? (
                  <>
                    <button 
                      className="px-4 py-2 rounded-md text-sm font-medium text-white bg-green-500 hover:bg-green-600 transition-colors duration-150"
                      onClick={() => setSelectedEmail(prospect.outreach)}
                    >
                      View Email
                    </button>
                    <button 
                      className="px-4 py-2 rounded-md text-sm font-medium text-white bg-yellow-500 hover:bg-yellow-600 transition-colors duration-150"
                      onClick={() => regenerateEmail(prospect)}
                      disabled={generatingAll}
                    >
                      Regenerate
                    </button>
                    <button 
                      className="px-4 py-2 rounded-md text-sm font-medium text-white bg-blue-500 hover:bg-blue-600 transition-colors duration-150"
                      onClick={() => handleSendEmail(prospect)}
                      disabled={generatingAll}
                    >
                      Send Email
                    </button>
                  </>
                ) : (
                  <button 
                    className="px-4 py-2 rounded-md text-sm font-medium text-white bg-blue-500 hover:bg-blue-600 transition-colors duration-150"
                    onClick={() => generateEmail(prospect)}
                    disabled={loadingProspects[prospect.id] || generatingAll}
                  >
                    Generate Email
                  </button>
                )}
                <button 
                  className="px-4 py-2 rounded-md text-sm font-medium text-white bg-red-500 hover:bg-red-600 transition-colors duration-150"
                  onClick={() => deleteProspect(prospect)}
                  disabled={generatingAll}
                >
                  Delete
                </button>
              </div>
              {prospect.error && (
                <p className="text-red-500 text-sm mt-2">{prospect.error}</p>
              )}
            </td>
          </tr>
        ))}
      </tbody>
          </table>
        </div>



        {/* Email popup */}
        {selectedEmail && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" onClick={() => setSelectedEmail(null)}>
        <div className="relative top-20 mx-auto p-5 border w-3/4 max-w-2xl shadow-lg rounded-md bg-white" onClick={e => e.stopPropagation()}>
          <button
            onClick={() => setSelectedEmail(null)}
            className="absolute top-2 right-2 text-gray-400 hover:text-gray-600 focus:outline-none"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
          <div className="mt-3">
            <h3 className="text-lg leading-6 font-medium text-gray-900 mb-2">Generated Email</h3>
            <div className="mt-2 px-7 py-3 bg-white rounded-md">
              <h4 className="text-md font-medium text-gray-800 mb-2">Subject:</h4>
              <pre className="text-sm text-gray-700 whitespace-pre-wrap font-sans mb-4">
                {selectedEmail.subject}
              </pre>
              <h4 className="text-md font-medium text-gray-800 mb-2">Body:</h4>
              <pre className="text-sm text-gray-700 whitespace-pre-wrap font-sans">
                {selectedEmail.body}
              </pre>
            </div>
            <div className="items-center px-4 py-3">
              <button
                onClick={() => copyToClipboard(selectedEmail)}
                className="px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-300"
              >
                Copy Email
              </button>
            </div>
          </div>
        </div>
      </div>
      
      )}

        {/* [NEW] Beta Info Popup */}
        {showBetaInfoPopup && (
          <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" onClick={() => setShowBetaInfoPopup(false)}>
            <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white" onClick={e => e.stopPropagation()}>
              <div className="mt-3 text-center">
                <h3 className="text-lg leading-6 font-medium text-gray-900">Beta Version</h3>
                <div className="mt-2 px-7 py-3">
                  <p className="text-sm text-gray-500">
                  ⚠️ This is a beta model. You may come across bugs, model hallucinations, etc. Please use at your own risk. 
                  </p>
                  <p className="text-sm text-gray-500 pt-5">
                  AI models operate on the principle of 'garbage in, garbage out,' meaning that poor input data leads to poor output results. Please ensure you provide accurate and high-quality inputs.
                  </p>
                </div>
                <div className="items-center px-4 py-3">
                  <button
                    onClick={() => setShowBetaInfoPopup(false)}
                    className="px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-300"
                  >
                    I Understand
                  </button>
                  <div className='pt-5'> 
                  <button
              onClick={openDemo}
              className="px-4 py-2 bg-yellow-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-300"
            >
              Watch Demo (recomended)
            </button>
            </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* [NEW] Input Missing Popup */}
        {showInputMissingPopup && (
          <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" onClick={() => setShowInputMissingPopup(false)}>
            <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white" onClick={e => e.stopPropagation()}>
              <div className="mt-3 text-center">
                <h3 className="text-lg leading-6 font-medium text-gray-900">Missing Input</h3>
                <div className="mt-2 px-7 py-3">
                  <p className="text-sm text-gray-500">
                    Please fill in all required fields (Product or Service, Features, Pain Point) before generating an email.
                  </p>
                </div>
                <div className="items-center px-4 py-3">
                  <button
                    onClick={() => setShowInputMissingPopup(false)}
                    className="px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-300"
                  >
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {showAddProspectPopup && (
          <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" onClick={() => setShowAddProspectPopup(false)}>
            <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white" onClick={e => e.stopPropagation()}>
              <div className="mt-3">
                <h3 className="text-lg leading-6 font-medium text-gray-900 mb-2">Add New Prospect</h3>
                <div className="mt-3">
                  <input
                    type="text"
                    placeholder="Prospect Name"
                    value={newProspect.name}
                    onChange={(e) => setNewProspect({...newProspect, name: e.target.value})}
                    className="mt-1 block w-full rounded-md bg-white border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 mb-3"
                  />
                  <input
                    type="text"
                    placeholder="Company Website (e.g., https://example.com)"
                    value={newProspect.website}
                    onChange={(e) => setNewProspect({...newProspect, website: e.target.value})}
                    className="mt-1 block w-full rounded-md bg-white border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 mb-3"
                  />
                  <input
                    type="email"
                    placeholder="Prospect Email"
                    value={newProspect.email}
                    onChange={(e) => setNewProspect({...newProspect, email: e.target.value})}
                    className="mt-1 block w-full rounded-md bg-white border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                  />
                </div>
                <div className="items-center px-4 py-3">
                  <button
                    onClick={handleAddProspect}
                    className="px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-300"
                  >
                    Add Prospect
                  </button>
                </div>
              </div>
            </div>
          </div>
        )} {showConfigureDomainPopup && (
          <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" onClick={() => setShowConfigureDomainPopup(false)}>
          <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white" onClick={e => e.stopPropagation()}>
            <div className="mt-3">
              <h3 className="text-lg leading-6 font-medium text-gray-900 mb-2">Configure Sending Domain Settings</h3>
              
              {view === 'main' && (
                <>
                  <p className="text-sm text-gray-500 mb-4">
                    Please choose an option to configure your sending domain settings.
                  </p>
                  <div className="space-y-2">
                    <button
                      onClick={() => setView('addDomain')}
                      className="w-full px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-300"
                    >
                      Add New Domain
                    </button>
                    <button
  onClick={async () => {
    try {
      const response = await axios.post('https://web-production-5ee8.up.railway.app/auth/domaininfo', {
        userId: userInfo._id,
      });
      alert(`Email: ${response.data.email}\nDomain: ${response.data.domain}`);
    } catch (error) {
      console.error('Error fetching user info:', error);
      alert('An error occurred while fetching user info.');
    }
  }}
  className="w-full px-4 py-2 bg-green-500 text-white text-base font-medium rounded-md shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-300"
>
  View Current Domains
</button>

                  </div>
                </>
              )}
    
              {view === 'addDomain' && (
                <>
                  <p className="text-sm text-gray-500 mb-4">
                    Add a new sending domain by selecting a service and providing your credentials.
                  </p>
                  <div className="space-y-4">
                    <select
                      value={JSON.stringify(selectedService)}
                      onChange={(e) => setSelectedService(JSON.parse(e.target.value))}
                      className="w-full p-2 border rounded-md bg-white" 
                    >
                      {popularServices.map((service) => (
                        <option key={service.name} value={JSON.stringify(service)}>
                          {service.name}
                        </option>
                      ))}
                    </select>
                    {selectedService.name === 'Custom' && (
                      <input
                        type="text"
                        placeholder="Custom SMTP Host"
                        value={customHost}
                        onChange={(e) => setCustomHost(e.target.value)}
                        className="w-full p-2 border rounded-md bg-white"
                      />
                    )}
                    <input
                      type="email"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="w-full p-2 border rounded-md bg-white"
                    />
                    <input
                      type="password"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="w-full p-2 border rounded-md bg-white"
                    />
                    <button
                      onClick={handleAddDomain}
                      className="w-full px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-300"
                    >
                      Add Domain
                    </button>
                  </div>

                </>
              )}
    
         
    
    {error && (
  <CustomAlert error={error} />
)}

    
              <div className="mt-4">
                <button
                  onClick={() => view === 'main' ? setShowConfigureDomainPopup(false) : setView('main')}
                  className="w-full px-4 py-2 bg-gray-300 text-gray-800 text-base font-medium rounded-md shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-300"
                >
                  {view === 'main' ? 'Close' : 'Back'}
                </button>
              </div>
            </div>
          </div>
        </div>
        )}
        
        {selectedEmailForSending && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" onClick={() => setSelectedEmailForSending(null)}>
        <div className="relative top-20 mx-auto p-5 border w-3/4 max-w-2xl shadow-lg rounded-md bg-white" onClick={e => e.stopPropagation()}>
          <button
            onClick={() => setSelectedEmailForSending(null)}
            className="absolute top-2 right-2 text-gray-400 hover:text-gray-600 focus:outline-none"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
          <div className="mt-3">
            <h3 className="text-lg leading-6 font-medium text-gray-900 mb-2">Send Email</h3>
            <div className="mt-2 px-7 py-3">
              <h4 className="text-md font-medium text-gray-800 mb-2">Subject:</h4>
              <textarea
                className="w-full px-3 py-2 bg-white text-gray-700 border rounded-lg focus:outline-none border-gray-300"
                rows="2"
                value={editableEmail.subject}
                onChange={(e) => handleEditEmail('subject', e.target.value)}
              />
              <h4 className="text-md font-medium text-gray-800 mb-2 mt-4">Body:</h4>
              <textarea
                className="w-full px-3 py-2 bg-white text-gray-700 border rounded-lg focus:outline-none border-gray-300"
                rows="10"
                value={editableEmail.body}
                onChange={(e) => handleEditEmail('body', e.target.value)}
              />
            </div>
            <div className="items-center px-4 py-3">
              <button
                onClick={handleConfirmSendEmail}
                className="px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-300"
              >
                Confirm and Send Email
              </button>
            </div>
          </div>
        </div>
      </div>
      )}

      <Footer />
    </div>
  );
};

export default ProspectPage;
