import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { ArrowRight, Check, ChevronDown, Menu, X } from 'lucide-react';
import Footer from "../../components/footer/Footer";
import microsoft from '../../assets/ContentImages/microsoft.png';
import amazon from '../../assets/ContentImages/amazon.png';


function Echidna() {
  const [menuOpen, setMenuOpen] = useState(false);

  const products = [
    { name: "Echidna Mail", description: "AI-powered outreach generator", icon: "📧" },
    { name: "Cyberlake Cloud", description: "AI-powered analytic cloud + VPS", icon: "☁️" },
    { name: "Neptaverse", description: "XR engine", icon: "🥽" },
    { name: "FineSecure", description: "AI-based API securer", icon: "🔒" },
    { name: "AI Connect", description: "Host enterprise AI models securely and privately", icon: "🗄️" },
    { name: "Androforce", description: "AI sales team", icon: "🤖" }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white text-gray-900 font-sans">
      <Helmet>
        <title>Metabees - Leading Enterprise AI Solutions</title>
        <meta name="description" content="Revolutionize your business with Metabees' suite of enterprise AI solutions. From AI-powered outreach to secure model hosting, we've got your AI needs covered." />
        <meta name="keywords" content="Enterprise AI, Echidna Mail, Cyberlake Cloud, Neptaverse, FineSecure, AI Connect, AI solutions" />
        <meta property="og:title" content="Metabees - Comprehensive Enterprise AI Solutions" />
        <meta property="og:description" content="Unlock the power of AI for your enterprise with Metabees' cutting-edge solutions. Boost productivity, enhance security, and drive innovation." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.metabees.com" />
        <meta property="og:image" content="https://cybverlake-test.s3.ap-south-1.amazonaws.com/MBEES+2+transparent.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Metabees - Enterprise AI Experts" />
        <meta name="twitter:description" content="Transform your business with Metabees' suite of enterprise AI solutions. From outreach to analytics, security to XR, we've got you covered." />
        <meta name="twitter:image" content="https://cybverlake-test.s3.ap-south-1.amazonaws.com/MBEES+2+transparent.png" />
        <link href="https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap" rel="stylesheet" />
      </Helmet>
  
      <header className="bg-white shadow-lg fixed top-0 left-0 right-0 z-50">
        <div className="container mx-auto px-4 py-4 flex justify-between items-center">
          <img src="https://cybverlake-test.s3.ap-south-1.amazonaws.com/MBEES+2+transparent.png" alt="Metabees Logo" className="h-10 md:h-12" />
          <nav className="hidden md:block">
            <div className="flex items-center space-x-6">
              {products.map((product, index) => (
                <a key={index} href={`/${product.name.toLowerCase().replace(' ', '-')}`} className="text-gray-700 hover:text-blue-600 font-medium transition-colors duration-300">{product.name}</a>
              ))}
              <a href="/contact" className="bg-blue-600 text-white px-6 py-2 rounded-full font-semibold hover:bg-blue-700 transition-colors duration-300">Get Started</a>
            </div>
          </nav>
        </div>
      </header>
  
      <main className="pt-20">
        <section className="bg-gradient-to-r from-blue-600 to-indigo-600 text-white py-20 md:py-32">
          <div className="container mx-auto px-4">
            <div className="max-w-3xl mx-auto text-center">
              <h1 className="text-4xl md:text-6xl font-bold mb-6 leading-tight">Enterprise AI Solutions for the Modern Business</h1>
              <p className="text-xl md:text-2xl mb-8 text-blue-100">Metabees delivers a comprehensive suite of AI-powered tools to drive innovation, enhance security, and boost productivity across your organization.</p>
              <a href="/get-demo" className="bg-white text-blue-600 px-8 py-3 rounded-full text-lg font-semibold hover:bg-blue-50 transition-colors duration-300 inline-flex items-center">
                Get a Free Enterprise Demo
                <ArrowRight className="ml-2 h-5 w-5" />
              </a>
            </div>
          </div>
        </section>
  
        <section className="py-20 bg-gray-50">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl md:text-4xl font-bold mb-12 text-center">Our Enterprise AI Product Suite</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-12">
              {products.map((product, index) => (
                <div key={index} className="bg-white p-8 rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300">
                  <div className="text-4xl mb-4">{product.icon}</div>
                  <h3 className="text-2xl font-semibold mb-4">{product.name}</h3>
                  <p className="mb-6 text-gray-600">{product.description}</p>
                  <a href={`/${product.name.toLowerCase().replace(' ', '-')}`} className="text-blue-600 font-semibold hover:text-blue-700 transition-colors duration-300 inline-flex items-center">
                    Learn More
                    <ArrowRight className="ml-2 h-5 w-5" />
                  </a>
                </div>
              ))}
            </div>
          </div>
        </section>
  
        <section className="py-20 bg-blue-900 text-white">
          <div className="container mx-auto px-4">
            <div className="max-w-3xl mx-auto text-center">
              <h2 className="text-3xl md:text-4xl font-bold mb-6">Transform Your Outreach with Echidna Mail</h2>
              <p className="text-xl mb-8">Our AI-driven outreach solution uses advanced algorithms to craft personalized, high-converting messages at scale.</p>
              <a href="/echidna" className="bg-white text-blue-900 px-8 py-3 rounded-full font-semibold hover:bg-blue-50 transition-colors duration-300 inline-flex items-center">
                Explore Echidna Mail
                <ArrowRight className="ml-2 h-5 w-5" />
              </a>
            </div>
          </div>
        </section>
  
        <section className="py-20">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl md:text-4xl font-bold mb-12 text-center">Why Choose Metabees for Enterprise AI Solutions?</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
              {[
                { title: "Comprehensive Suite", description: "From outreach to analytics, security to XR, we cover all your AI needs." },
                { title: "Scalable Solutions", description: "Our products grow with your business, ensuring long-term value." },
                { title: "Secure and Private", description: "We prioritize data security and offer private AI model hosting." },
                { title: "Cutting-Edge Technology", description: "Stay ahead with our continually evolving AI capabilities." }
              ].map((reason, index) => (
                <div key={index} className="flex flex-col items-center text-center">
                  <div className="bg-blue-100 text-blue-600 p-4 rounded-full mb-4">
                    <Check className="h-8 w-8" />
                  </div>
                  <h3 className="text-xl font-semibold mb-2">{reason.title}</h3>
                  <p className="text-gray-600">{reason.description}</p>
                </div>
              ))}
            </div>
          </div>
        </section>
  
        <section className="py-16 bg-gray-50">
  <div className="container mx-auto px-4 text-center">
    <h2 className="text-2xl font-bold mb-8">Trusted by pioneers</h2>
    <div className="flex justify-center gap-20">
      <img src={microsoft} alt="Logo 1" className="w-[100px] h-[100px] object-contain" />
      <img src={amazon} alt="Logo 2" className="w-[100px] h-[100px] object-contain" />
      <img src="https://cybverlake-test.s3.ap-south-1.amazonaws.com/The_Times_Group_logo.png" alt="Logo 3" className="w-[100px] h-[100px] object-contain" />
    </div>
  </div>
</section>




        <section className="bg-white py-16 md:py-24">
          <div className="container mx-auto px-4 text-center">
            <h2 className="text-3xl md:text-4xl font-semibold mb-6 md:mb-8">Our Enterprise AI Data Policy</h2>
            <p className="text-xl mb-8 max-w-3xl mx-auto">At Metabees, we understand the critical nature of data in enterprise environments. Our commitment to you:</p>
            <div className="bg-blue-50 p-6 rounded-lg inline-block text-left">
              <ul className="list-disc pl-5 space-y-2">
                <li>Your data remains completely separate and is never used to train our AI models</li>
                <li>We offer private AI model hosting for ultimate control and security</li>
                <li>Our solutions comply with industry-standard security protocols and regulations</li>
                <li>Transparent data handling practices with regular audits and reports</li>
              </ul>
            </div>
            <div className="mt-8">
              <a href="/enterprise-ai-data-policy" className="btn-secondary text-lg inline-block">Read Full Enterprise AI Data Policy</a>
            </div>
          </div>
        </section>
  
        <section className="py-20 bg-blue-600 text-white">
          <div className="container mx-auto px-4">
            <div className="max-w-3xl mx-auto text-center">
              <h2 className="text-3xl md:text-4xl font-bold mb-6">Ready to Elevate Your Enterprise with AI?</h2>
              <p className="text-xl mb-8">Let's discuss how Metabees' suite of enterprise AI solutions can transform your business operations and drive growth.</p>
              <a href="/contact" className="bg-white text-blue-600 px-8 py-3 rounded-full font-semibold hover:bg-blue-50 transition-colors duration-300 inline-flex items-center">
                Schedule an Enterprise Consultation
                <ArrowRight className="ml-2 h-5 w-5" />
              </a>
            </div>
          </div>
        </section>
      </main>
  
    <Footer />
    </div>
  );
}

export default Echidna;