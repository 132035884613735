import React from 'react';
import { Mail, Send, Users, BarChart, Zap, Cloud, MessageSquare, User, ArrowUp, ArrowDown } from 'lucide-react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const SidebarButton = ({ icon: Icon, text, to, active }) => (
  <button className={`p-3 rounded-xl ${active ? 'bg-indigo-100 text-indigo-600' : 'text-gray-400 hover:bg-gray-100'}`}>
    <Icon className="w-6 h-6" />
  </button>
);

const StatCard = ({ title, value, change, icon: Icon }) => (
  <div className="bg-white p-6 rounded-lg shadow">
    <div className="flex items-center justify-between">
      <div>
        <p className="text-sm font-medium text-gray-500 mb-1">{title}</p>
        <p className="text-2xl font-semibold text-gray-900">{value}</p>
      </div>
      <div className={`p-3 rounded-full ${change >= 0 ? 'bg-green-100' : 'bg-red-100'}`}>
        <Icon className={`w-6 h-6 ${change >= 0 ? 'text-green-600' : 'text-red-600'}`} />
      </div>
    </div>
    <div className={`flex items-center mt-4 ${change >= 0 ? 'text-green-600' : 'text-red-600'}`}>
      {change >= 0 ? <ArrowUp className="w-4 h-4 mr-1" /> : <ArrowDown className="w-4 h-4 mr-1" />}
      <span className="text-sm font-medium">{Math.abs(change)}% from last month</span>
    </div>
  </div>
);

const data = [
  { name: 'Jan', value: 4000 },
  { name: 'Feb', value: 3000 },
  { name: 'Mar', value: 5000 },
  { name: 'Apr', value: 4500 },
  { name: 'May', value: 6000 },
  { name: 'Jun', value: 5500 },
];

const Analytics = () => {
  return (
    <div className="flex h-screen bg-gray-100 relative">
      {/* Coming Soon Overlay */}
      <div className="absolute inset-0 backdrop-blur-sm bg-white/20 z-50 flex items-center justify-center">
        <div className="text-center bg-white/80 p-8 rounded-xl shadow-lg">
          <h2 className="text-4xl font-bold text-gray-900 mb-4">Coming Soon</h2>
          <p className="text-xl text-gray-600">We're working on powerful analytics features for you!</p>
        </div>
      </div>

      {/* Sidebar */}
      <aside className="w-20 bg-white shadow-md">
        <div className="flex flex-col items-center py-6 space-y-8">
          <div className="p-3 bg-indigo-600 rounded-xl">
            <Mail className="w-8 h-8 text-white" />
          </div>
          <SidebarButton icon={Send} text="Send Emails" to="/send-emails" />
          <SidebarButton icon={Users} text="Manage Prospects" to="/prospects" />
          <SidebarButton icon={BarChart} text="Analytics" to="/analytics" active />
          <SidebarButton icon={Zap} text="Automations" to="/automations" />
          <SidebarButton icon={Cloud} text="Integrations" to="/integrations" />
          <SidebarButton icon={MessageSquare} text="Templates" to="/templates" />
          <SidebarButton icon={User} text="Account" to="/account" />
        </div>
      </aside>
      
      {/* Main content */}
      <main className="flex-1 p-8 overflow-auto">
        <div className="max-w-7xl mx-auto">
          <header className="flex justify-between items-center mb-8">
            <h1 className="text-3xl font-bold text-gray-900">Analytics Dashboard</h1>
          </header>
          
          {/* Stats Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
            <StatCard title="Total Prospects" value="12,345" change={5.4} icon={Users} />
            <StatCard title="Emails Sent" value="54,321" change={2.1} icon={Send} />
            <StatCard title="Open Rate" value="68%" change={-1.5} icon={Mail} />
            <StatCard title="Response Rate" value="42%" change={3.2} icon={MessageSquare} />
          </div>
          
          {/* Chart */}
          <div className="bg-white p-6 rounded-lg shadow mb-8">
            <h2 className="text-xl font-semibold text-gray-900 mb-4">Email Performance Over Time</h2>
            <ResponsiveContainer width="100%" height={400}>
              <LineChart data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Line type="monotone" dataKey="value" stroke="#4F46E5" strokeWidth={2} />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Analytics;