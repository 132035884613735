import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useParams } from 'react-router-dom';
import Navbar from "../../components/navbar/Navbar";
import { useSelector } from 'react-redux';
const stripePromise = loadStripe("pk_test_51O9SlXSEHbit5Dze1t2GoWGzMbMuSL1JV5y4WFMg3WfA9T9s2Ll2aFdgkeEMWNeQoRs3Gebha6i2OK5fSD3319aZ00TdQ7RNBne");

function CheckoutForm() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const { userInfo } = useSelector((state) => state.auth); // Move it inside the component
  const userId = userInfo?._id;
  const [billingAddress, setBillingAddress] = useState({
    line1: "",
    line2: "",
    city: "",
    state: "",
    postalCode: "",
    country: "",
  });
  const [shippingAddress, setShippingAddress] = useState({
    line1: "",
    line2: "",
    city: "",
    state: "",
    postalCode: "",
    country: "",
  });
  const stripe = useStripe();
  const elements = useElements();
  let { plan } = useParams();
  console.log(plan);

  const createSubscription = async () => {
    try {
      const cardElement = elements?.getElement(CardElement);

      // Guard clause to check if cardElement is valid
      if (!cardElement) {
        throw new Error("Invalid card element");
      }

      const paymentMethod = await stripe?.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: {
          name,
          email,
          address: {
            line1: billingAddress.line1,
            line2: billingAddress.line2 || null,
            city: billingAddress.city,
            state: billingAddress.state,
            postal_code: billingAddress.postalCode,
            country: billingAddress.country,
          },
        },
      });

      // Guard clause to check if paymentMethod is valid
      if (!paymentMethod || paymentMethod.error) {
        throw new Error(paymentMethod.error ? paymentMethod.error.message : "Failed to create payment method");
      }
      const userId = userInfo._id;

      const response = await fetch("https://web-production-5ee8.up.railway.app/subscribe/cyberlake_monthly", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          paymentMethodId: paymentMethod.paymentMethod.id,
          name,
          email,
          billingAddress,
          shippingAddress,
          plan,
          userId
        }),
      }).then((res) => res.json());
      console.log(plan);

      const confirmPayment = await stripe?.confirmCardPayment(response.clientSecret);

      if (confirmPayment?.error) {
        alert(confirmPayment.error.message);
      } else {
        alert("Success! Check your email for the invoice.");
      }
    } catch (error) {
      console.log(error);
      alert(error.message);
    }
  };

  return (
    <div className="relative min-h-screen bg-gray-900 text-white overflow-hidden">
      <video
        autoPlay
        loop
        muted
        className="absolute z-0 w-auto min-w-full min-h-full max-w-none object-cover"
      >
        <source src="/path/to/your/video.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      
      <div className="relative z-10 min-h-screen bg-black bg-opacity-70 flex flex-col">
        <Navbar />
        <div className="flex-grow container mx-auto px-4 py-12 flex flex-col lg:flex-row items-center justify-center space-y-8 lg:space-y-0 lg:space-x-12">
          <div className="lg:w-1/2 max-w-md">
            <h1 className="text-4xl font-extrabold mb-6 text-purple-400">Complete Your Purchase</h1>
            <div className="bg-gray-800 rounded-xl p-6 shadow-lg">
              <h2 className="text-2xl font-bold mb-4 text-purple-300">$400/mo Marketing Suite</h2>
              <ul className="space-y-3">
                {['Neptaverse XR Engine', 'Cyberlake Storage', 'Echidna', 'Echidna Mail'].map((item, index) => (
                  <li key={index} className="flex items-center">
                    <svg className="h-5 w-5 mr-3 text-green-500" fill="currentColor" viewBox="0 0 20 20">
                      <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                    </svg>
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          
          <div className="lg:w-1/2 max-w-md w-full bg-gray-800 rounded-xl p-8 shadow-lg backdrop-filter backdrop-blur-lg bg-opacity-50">
            <h2 className="text-2xl font-bold mb-6 text-center text-purple-300">Payment Details</h2>
            <form className="space-y-4">
              {[
                { id: 'name', label: 'Name', type: 'text', value: name, onChange: setName },
                { id: 'email', label: 'Email', type: 'email', value: email, onChange: setEmail },
                { id: 'billingAddress.line1', label: 'Address', type: 'text', value: billingAddress.line1, onChange: (e) => setBillingAddress({ ...billingAddress, line1: e.target.value }) },
              ].map((field) => (
                <div key={field.id}>
                  <label htmlFor={field.id} className="block text-sm font-medium text-gray-300 mb-1">{field.label}</label>
                  <input
                    id={field.id}
                    type={field.type}
                    value={field.value}
                    onChange={(e) => field.onChange(e.target.value)}
                    className="w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500 text-white"
                  />
                </div>
              ))}
              <div className="flex space-x-4">
                <div className="w-1/2">
                  <label htmlFor="billingAddress.city" className="block text-sm font-medium text-gray-300 mb-1">City</label>
                  <input
                    id="billingAddress.city"
                    type="text"
                    value={billingAddress.city}
                    onChange={(e) => setBillingAddress({ ...billingAddress, city: e.target.value })}
                    className="w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500 text-white"
                  />
                </div>
                <div className="w-1/2">
                  <label htmlFor="billingAddress.postalCode" className="block text-sm font-medium text-gray-300 mb-1">Postal Code</label>
                  <input
                    id="billingAddress.postalCode"
                    type="text"
                    value={billingAddress.postalCode}
                    onChange={(e) => setBillingAddress({ ...billingAddress, postalCode: e.target.value })}
                    className="w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500 text-white"
                  />
                </div>
              </div>
              <div>
                <label htmlFor="card" className="block text-sm font-medium text-gray-300 mb-1">Card Details</label>
                <div className="bg-gray-700 p-3 rounded-md">
                  <CardElement options={{style: {base: {fontSize: '16px', color: 'white'}}}} />
                </div>
              </div>
              <button
                type="submit"
                onClick={createSubscription}
                disabled={!stripe}
                className="w-full bg-purple-600 text-white py-3 px-4 rounded-md hover:bg-purple-700 transition-colors duration-300 disabled:opacity-50 disabled:cursor-not-allowed font-medium text-lg"
              >
                Subscribe Now
              </button>
            </form>
            <div className="mt-6 flex items-center justify-center space-x-2">
              <img src="/path/to/stripe-logo.png" alt="Stripe" className="h-6" />
              <p className="text-sm text-gray-400">Secure payment through Stripe</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const PaymentPage = () => {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm />
    </Elements>
  );
};

export default PaymentPage;