import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Navbar from '../../components/navbar/Navbar';

function Echidnamail() {
  const { userInfo } = useSelector((state) => state.auth);
  const [isPopupOpen, setIsPopupOpen] = useState(true);
  const [formData, setFormData] = useState({
    prospectName: '',
    companyWebsite: '',
    productOrService: '',
    features: '',
    painPoint: '',
    emailStyle: '',
    userId: userInfo._id,
  });
  const [outreach, setOutreach] = useState('');
  const [emailsLeft, setEmailsLeft] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [isGenerated, setIsGenerated] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);
  const textAreaRef = useRef(null);
  const [prospects, setProspects] = useState([]);
  const copyToClipboard = () => {
    textAreaRef.current.select();
    document.execCommand('copy');
    setCopySuccess(true);
    setTimeout(() => setCopySuccess(false), 2000); // Reset success message after 2 seconds
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const WarningPopup = ({ isOpen, onClose }) => {
    if (!isOpen) return null;
  
    const openDemo = () => {
      window.open('https://youtu.be/slMYbAwTOZY', '_blank');
    };
  
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
        <div className="bg-gray-800 p-6 rounded-lg max-w-md w-full mx-4">
          <div className="flex items-center justify-center mb-4">
            <svg className="w-8 h-8 text-yellow-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
            </svg>
            <h2 className="text-2xl font-bold text-white">Warning</h2>
          </div>
          <p className="text-gray-300 mb-6 text-center">
            This is a beta model. You may come across bugs, model hallucinations, etc. Please use at your own risk.
          </p>
          <p className="text-gray-300 mb-6 text-center">
            AI models operate on the principle of 'garbage in, garbage out,' meaning that poor input data leads to poor output results. Please ensure you provide accurate and high-quality inputs
          </p>
          <div className="flex justify-center space-x-4">
            <button
              onClick={onClose}
              className="bg-yellow-500 hover:bg-yellow-600 text-black font-bold py-2 px-6 rounded transition duration-300"
            >
              I Understand
            </button>
           
          </div>
          <button
              onClick={openDemo}
              className="bg-yellow-500 hover:bg-yellow-600 text-black font-bold py-2 px-6 rounded transition duration-300"
            >
              Watch Demo
            </button>
        </div>
      </div>
    );
  };

  useEffect(() => {
    const fetchEmailsLeft = async () => {
      const userId = userInfo._id;
      try {
        const response = await axios.post('https://web-production-5ee8.up.railway.app/echidna/el', { userId: userId });
        setEmailsLeft(response.data.mails);
      } catch (error) {
        console.error('Error fetching emails left:', error);
        setError('Error fetching emails left');
      }
    };

    fetchEmailsLeft();
  }, [formData.userId, userInfo._id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');
    setOutreach('');

    let website = formData.companyWebsite;
    if (!website.startsWith('http://') && !website.startsWith('https://')) {
      website = 'https://' + website;
    }

    const wordCount = formData.features.trim().split(/\s+/).length;
    if (wordCount < 20 || wordCount > 45) {
      setError('Features must be between 20 and 45 words.');
      setIsLoading(false);
      return;
    }

    try {
      const response = await axios.post('http://localhost:5001/echidna/outreachGen', {
        ...formData,
        companyWebsite: website
      });
      setOutreach(response.data.message);
      setIsGenerated(true);
    } catch (err) {
      setError('An error occurred while generating the outreach message.');
      console.error('Error:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleReplenishCredits = () => {
    alert("Replenish credits functionality to be implemented");
  };

  const handleGenerateNew = () => {
    setFormData({
      prospectName: '',
      companyWebsite: '',
      productOrService: '',
      features: '',
      userId: userInfo._id,
    });
    setOutreach('');
    setIsGenerated(false);
    setError('');
  };

  const addProspect = () => {
    setProspects([...prospects, { ...formData }]);
    setFormData({
      prospectName: '',
      companyWebsite: '',
      productOrService: '',
      features: '',
      painPoint: '',
      emailStyle: '',
      userId: userInfo._id,
    });
  };
  const handleGenerateAll = async () => {
    setIsLoading(true);
    setError('');
    setOutreach('');
  
    try {
      const results = await Promise.all(
        prospects.map(prospect => 
          axios.post('http://localhost:5001/echidna/outreachGen', {
            ...prospect,
            companyWebsite: prospect.companyWebsite.startsWith('http') ? prospect.companyWebsite : `https://${prospect.companyWebsite}`
          })
        )
      );
  
      const generatedOutreach = results.map((result, index) => 
        `Outreach for ${prospects[index].prospectName}:\n\n${result.data.message}\n\n---\n\n`
      ).join('');
  
      setOutreach(generatedOutreach);
      setIsGenerated(true);
    } catch (err) {
      setError('An error occurred while generating the outreach messages.');
      console.error('Error:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRegenerate = () => {
    handleSubmit({ preventDefault: () => {} });
  };

  const Sidebar = ({ emailsLeft, handleReplenishCredits }) => {
    return (
      <div className="bg-gray-800 text-gray-300 w-64 h-full overflow-y-auto flex flex-col">
        <div className="p-6 flex-grow">
          <h2 className="text-xl font-semibold mb-8 text-white">Dashboard</h2>
          <nav>
            <ul className="space-y-4">
              <li>
                <Link
                  to="/cyberlake/dashboard"
                  className="flex items-center py-2 px-4 rounded transition-colors hover:bg-gray-700"
                  onClick={(e) => {
                    e.preventDefault();
                    alert("You don't have Neptaverse access");
                  }}
                >
                  <span className="text-xl mr-3" role="img" aria-label="analytics">📊</span>
                  Neptaverse Analytics
                </Link>
              </li>
              <li>
                <Link to="/cyberlake/upload" className="flex items-center py-2 px-4 rounded transition-colors hover:bg-gray-700">
                  <svg className="w-5 h-5 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
                  </svg>
                  Upload to cyberlake
                </Link>
              </li>
              <li>
                <Link to="/cyberlake/chat" className="flex items-center py-2 px-4 rounded transition-colors hover:bg-gray-700">
                  <span className="text-xl mr-3" role="img" aria-label="snake">🐍</span>
                  <span className="flex items-center">
                    Echidna chat
                    <span className="ml-2 text-xs bg-yellow-500 text-black px-1 rounded">beta</span>
                  </span>
                </Link>
              </li>
              <li>
                <Link to="/echidnamail" className="flex items-center py-2 px-4 rounded transition-colors hover:bg-gray-700">
                  <svg className="w-6 h-6 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
                    <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
                  </svg>
                  <span className="flex items-center">
                    Echidna mail
                    <span className="ml-2 text-xs bg-yellow-500 text-black px-1 rounded">beta</span>
                  </span>
                </Link>
              </li>
            </ul>
          </nav>
        </div>
        <div className="p-6 border-t border-gray-700">
          <div className="flex flex-col items-center space-y-4">
            <div className="flex items-center space-x-2">
              <span className="text-sm font-semibold text-gray-400">Messages Left:</span>
              <span className="text-2xl font-bold text-gray-300">{emailsLeft}</span>
            </div>
            <a href='/echidnamail/buycredits'>
            <button
              className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg transition duration-300 w-full"
            >
              Replenish Credits
            </button>
            </a>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="flex h-screen bg-gray-900">
      <WarningPopup isOpen={isPopupOpen} onClose={() => setIsPopupOpen(false)} />
      <Sidebar emailsLeft={emailsLeft} handleReplenishCredits={handleReplenishCredits} />
      <div className="flex-1 flex flex-col overflow-hidden">
        <Navbar />
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-900">
          <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8">
          
            <div className="bg-gray-800 rounded-xl shadow-2xl p-6 sm:p-8">
              <form onSubmit={handleSubmit} className="space-y-6">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                  <div>
                    <label htmlFor="prospectName" className="block text-sm font-medium text-gray-300 mb-1">
                      Prospect Name
                    </label>
                    <input
                      type="text"
                      id="prospectName"
                      name="prospectName"
                      value={formData.prospectName}
                      onChange={handleChange}
                      required
                      className="w-full px-4 py-2 bg-gray-700 border border-gray-600 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-200"
                      placeholder="Enter prospect name"
                    />
                  </div>
                  <div>
                    <label htmlFor="companyWebsite" className="block text-sm font-medium text-gray-300 mb-1">
                      Company Website
                    </label>
                    <input
                      type="url"
                      id="companyWebsite"
                      name="companyWebsite"
                      value={formData.companyWebsite}
                      onChange={handleChange}
                      required
                      className="w-full px-4 py-2 bg-gray-700 border border-gray-600 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-200"
                      placeholder="Enter company website"
                    />
                    <p className="mt-1 text-xs text-gray-400">Please include http:// or https:// in the URL</p>
                  </div>
                </div>
                <div>
                  <label htmlFor="productOrService" className="block text-sm font-medium text-gray-300 mb-1">
                    Product Or Service
                  </label>
                  <input
                    type="text"
                    id="productOrService"
                    name="productOrService"
                    value={formData.productOrService}
                    onChange={handleChange}
                    required
                    className="w-full px-4 py-2 bg-gray-700 border border-gray-600 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-200"
                    placeholder="Enter product or service"
                  />
                  <p className="mt-1 text-xs text-gray-400">This is the product or service you wish to pitch</p>
                </div>
                <div>
                  <label htmlFor="features" className="block text-sm font-medium text-gray-300 mb-1">
                    Features
                  </label>
                  <textarea
                    id="features"
                    name="features"
                    value={formData.features}
                    onChange={handleChange}
                    required
                    className="w-full px-4 py-2 bg-gray-700 border border-gray-600 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-200"
                    placeholder="Describe the features of your product/service (20-45 words)"
                    rows="3"
                  ></textarea>
                  <p className="mt-1 text-xs text-gray-400">Minimum 20 words, maximum 45 words</p>
                </div>
                <div>
                  <label htmlFor="painPoint" className="block text-sm font-medium text-gray-300 mb-1">
                    Pain Point to be Fixed
                  </label>
                  <textarea
                    id="painPoint"
                    name="painPoint"
                    value={formData.painPoint}
                    onChange={handleChange}
                    required
                    className="w-full px-4 py-2 bg-gray-700 border border-gray-600 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-200"
                    placeholder="Describe the pain point your service aims to fix"
                    rows="3"
                  ></textarea>
                </div>
                <div>
                  <label htmlFor="emailBodyExample" className="block text-sm font-medium text-gray-300 mb-1">
                    Email Body Example
                  </label>
                  <textarea
                    id="emailBodyExample"
                    name="emailBodyExample"
                    value={formData.emailBodyExample}
                    onChange={handleChange}
                    required
                    className="w-full px-4 py-2 bg-gray-700 border border-gray-600 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-200"
                    placeholder="Provide an example of the email body text you've been using"
                    rows="5"
                  ></textarea>
                </div>
              </form>
            </div>
            
            <div className="mt-8 flex justify-center space-x-4">
              {!isGenerated && (
                <button
                  onClick={handleSubmit}
                  disabled={isLoading}
                  className="px-6 py-3 bg-blue-600 hover:bg-blue-700 text-white font-semibold rounded-lg shadow-lg transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                >
                  {isLoading ? 'Charting Course...' : 'Generate Outreach'}
                </button>
              )}
              {isGenerated && (
                <>
                  <button
                    onClick={handleGenerateNew}
                    className="px-6 py-3 bg-green-600 hover:bg-green-700 text-white font-semibold rounded-lg shadow-lg transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
                  >
                    Generate New Mail
                  </button>
                  <button
                    onClick={handleRegenerate}
                    className="px-6 py-3 bg-yellow-600 hover:bg-yellow-700 text-white font-semibold rounded-lg shadow-lg transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-opacity-50"
                  >
                    Regenerate
                  </button>
                </>
              )}
            </div>
  
            {isLoading && (
              <div className="mt-8 text-center">
                <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-blue-500 mx-auto"></div>
                <p className="mt-4 text-gray-300 text-lg">Navigating the depths of outreach...</p>
              </div>
            )}
  
            {error && (
              <div className="mt-8">
                <div className="bg-red-900 border-l-4 border-red-500 text-red-200 p-4 rounded-lg shadow-md" role="alert">
                  <p className="font-bold text-lg mb-2">Error!</p>
                  <p>{error}</p>
                </div>
              </div>
            )}
  
  {outreach && (
  <div className="mt-8 bg-gray-800 rounded-lg p-6 shadow-xl">
    <h2 className="text-2xl font-semibold mb-4 text-white">Crafted Message:</h2>
    <div className="relative">
      <textarea
        ref={textAreaRef}
        readOnly
        className="w-full bg-gray-700 p-6 rounded-lg border border-gray-600 text-gray-300 shadow-inner resize-none"
        value={outreach}
        rows="10"
      />
      <button
        onClick={copyToClipboard}
        className="absolute top-2 right-2 bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300"
      >
        {copySuccess ? 'Copied!' : 'Copy to Clipboard'}
      </button>
    </div>
  </div>
)}
          </div>
        </main>
      </div>
    </div>
  );
}

export default Echidnamail;
