import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSignupMutation } from '../../slices/usersApiSlice';
import { setCredentials } from '../../slices/authSlice';
import { useNavigate } from 'react-router-dom';
import Navbar from '../../components/navbar/Navbar.jsx';

function SignUp() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [otp, setOtp] = useState('');
  const [emailVerified, setEmailVerified] = useState(false);
  const [loading, setLoading] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [register] = useSignupMutation();

  const ErrorPopup = ({ message, onClose }) => {
    if (!message) return null;
  
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
        <div className="bg-gray-800 p-6 rounded-lg max-w-md w-full mx-4">
          <div className="flex items-center justify-center mb-4">
            <svg className="w-8 h-8 text-red-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <h2 className="text-2xl font-bold text-white">Error</h2>
          </div>
          <p className="text-gray-300 mb-6 text-center">{message}</p>
          <div className="flex justify-center">
            <button
              onClick={onClose}
              className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-6 rounded transition duration-300"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    );
  };

  const handleError = (errorMessage) => {
    setErrorMessage(errorMessage);
    setLoading(false);
  };

  const WarningPopup = ({ isOpen, onClose }) => {
    if (!isOpen) return null;
  
    return (
      <div className="fixed inset-0 bg-black bg-opacity-70 z-50 flex justify-center items-center backdrop-blur-sm">
        <div className="bg-gray-900 bg-opacity-80 p-8 rounded-2xl max-w-md w-full mx-4 border border-blue-500 shadow-lg shadow-blue-500/50">
          <div className="flex items-center justify-center mb-6">
            <svg className="w-10 h-10 text-blue-400 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <h2 className="text-3xl font-bold text-white">Important Notice</h2>
          </div>
          <p className="text-gray-300 mb-8 text-center text-lg">
            Please disable all ad blockers and ad block extensions for security reasons. We assure you, no ads will be displayed.
          </p>
          
          <div className="flex justify-center">
            <button
              onClick={onClose}
              className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-8 rounded-lg transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
            >
              I Understand
            </button>
          </div>
        </div>
      </div>
    );
  };

  const sendEmailVerification = async () => {
    if (!email) {
      handleError('Please enter an email address.');
      return;
    }
  
    setLoading(true);
    try {
      console.log('Sending OTP generation request:', { email });
      const response = await fetch('https://web-production-5ee8.up.railway.app/auth/generateAndSendOTP', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
      
      if (!response.ok) {
        throw new Error('Failed to send OTP');
      }
      
      setOtpSent(true);
    } catch (error) {
      console.error(error);
      handleError('Failed to send OTP. Please try again.');
    }
    setLoading(false);
  };

  const verifyOtp = async () => {
    if (!otp) {
      handleError('Please enter the OTP.');
      return;
    }
  
    setLoading(true);
    try {
      console.log('Sending OTP verification request:', { email, otp });
      const response = await fetch('https://web-production-5ee8.up.railway.app/auth/verifyOtp', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, otp }),
      });
      console.log(otp + " " + email);
      const data = await response.json();
      console.log('OTP verification response:', data);
  
      if (data.status === "FAILED") {
        throw new Error(data.message);
      }
  
      if (data.status === "SUCCESS") {
        setEmailVerified(true);
        console.log('Email verified successfully');
      } else {
        throw new Error('Unexpected response from server');
      }
    } catch (error) {
      console.error('OTP verification error:', error);
      handleError(error.message || 'OTP verification failed. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    if (!name || !email || !password || !confirmPassword) {
      handleError('All fields are required.');
      return;
    }

    if (password !== confirmPassword) {
      handleError('Passwords do not match');
      return;
    }

    try {
      const res = await register({ name, email, password }).unwrap();
      dispatch(setCredentials({ ...res }));
      navigate('/');
    } catch (error) {
      console.error(error);
      handleError(error.data?.message || 'An error occurred during signup.');
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-900 via-indigo-800 to-blue-900 flex flex-col" style={{backgroundImage: "url('https://cybverlake-test.s3.ap-south-1.amazonaws.com/dashboard-bg.jpg')"}}>
      <Navbar />
      <WarningPopup isOpen={isPopupOpen} onClose={() => setIsPopupOpen(false)} />
      <ErrorPopup message={errorMessage} onClose={() => setErrorMessage('')} />
      <div className="flex-grow flex items-center justify-center px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8 bg-white bg-opacity-10 p-10 rounded-2xl backdrop-filter backdrop-blur-lg shadow-xl">
          <div>
            <h2 className="mt-6 text-center text-4xl font-extrabold text-white">
              Join the Future
            </h2>
            <p className="mt-2 text-center text-sm text-purple-200">
              Create your account and unlock a world of possibilities
            </p>
          </div>
          <form className="mt-8 space-y-6" onSubmit={submitHandler}>
            <div className="space-y-4">
              <div>
                <label htmlFor="name" className="sr-only">Full Name</label>
                <input
                  id="name"
                  name="name"
                  type="text"
                  required
                  className="appearance-none relative block w-full px-3 py-3 border border-purple-300 placeholder-purple-400 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm bg-purple-800 bg-opacity-50"
                  placeholder="Full Name"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                />
              </div>
              <div>
                <label htmlFor="email-address" className="sr-only">Email address</label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="appearance-none relative block w-full px-3 py-3 border border-purple-300 placeholder-purple-400 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm bg-purple-800 bg-opacity-50"
                  placeholder="Email address"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
              </div>
            </div>

            {otpSent && !emailVerified && (
              <div>
                <label htmlFor="otp" className="sr-only">Enter OTP</label>
                <input
                  id="otp"
                  name="otp"
                  type="text"
                  required
                  className="appearance-none relative block w-full px-3 py-3 border border-purple-300 placeholder-purple-400 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm bg-purple-800 bg-opacity-50"
                  placeholder="Enter OTP"
                  onChange={(e) => setOtp(e.target.value)}
                  value={otp}
                />
                <button
                  type="button"
                  className="mt-4 group relative w-full flex justify-center py-3 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 transition duration-150 ease-in-out"
                  onClick={verifyOtp}
                  disabled={loading}
                >
                  {loading ? 'Verifying...' : 'Verify OTP'}
                </button>
              </div>
            )}

            {!otpSent && (
              <div>
                <button
                  type="button"
                  className="group relative w-full flex justify-center py-3 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 transition duration-150 ease-in-out"
                  onClick={sendEmailVerification}
                  disabled={loading || !email}
                >
                  {loading ? 'Sending OTP...' : 'Verify Email'}
                </button>
              </div>
            )}

            {emailVerified && (
              <>
                <div className="space-y-4">
                  <div>
                    <label htmlFor="password" className="sr-only">Password</label>
                    <input
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="new-password"
                      required
                      className="appearance-none relative block w-full px-3 py-3 border border-purple-300 placeholder-purple-400 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm bg-purple-800 bg-opacity-50"
                      placeholder="Password"
                      onChange={(e) => setPassword(e.target.value)}
                      value={password}
                    />
                  </div>
                  <div>
                    <label htmlFor="confirm-password" className="sr-only">Confirm Password</label>
                    <input
                      id="confirm-password"
                      name="confirm-password"
                      type="password"
                      autoComplete="new-password"
                      required
                      className="appearance-none relative block w-full px-3 py-3 border border-purple-300 placeholder-purple-400 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm bg-purple-800 bg-opacity-50"
                      placeholder="Confirm Password"
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      value={confirmPassword}
                    />
                  </div>
                </div>
                <div>
                  <button
                    type="submit"
                    className="group relative w-full flex justify-center py-3 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 transition duration-150 ease-in-out"
                  >
                    Create Account
                  </button>
                </div>
              </>
            )}
          </form>
          <div className="text-center">
            <p className="text-sm text-purple-200">
              Already have an account?{' '}
              <a href="/login" className="font-medium text-white hover:text-purple-100 transition duration-150 ease-in-out">
                Log In
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUp;