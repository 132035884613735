import { useState } from 'react';
import axios from 'axios';
import Navbar from '../../components/navbar/Navbar.jsx';

export default function Waitlist() {
  const [isModalOpen, setModalOpen] = useState(false);
  const [num, setNum] = useState('');
  const [email, setEmail] = useState('');
  const [query, setQuery] = useState('');

  const validateEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const countWords = (str) => {
    return str.trim().split(/\s+/).length;
  };

  const submiter = async (e) => {
    e.preventDefault();

    if (!email || !query) {
      alert('Please fill all the fields');
      return;
    }

    if (!validateEmail(email)) {
      alert('Please enter a valid email address');
      return;
    }

    if (countWords(query) < 50) {
      alert('Query must be at least 50 words');
      return;
    }

    try {
      await axios.post(
        `https://web-production-5ee8.up.railway.app/auth/support`,
        {
          email: email,
          num: num,
          query: query,
        }
      );

      setModalOpen(true);
      setTimeout(() => {
        setModalOpen(false);
      }, 10000);
    } catch (err) {
      console.log(err);
    }
  };

  

  return (
    <div className="bg-black h-screen">
      <Navbar />
      <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
        <div className="max-w-xl mx-auto">
          <div className="text-center">
            <h1 className="text-3xl font-bold text-gray-800 sm:text-4xl dark:text-white">
              Contact us
            </h1>
            <p className="mt-1 text-gray-600 dark:text-neutral-400">
              We'd love to talk about how we can help you.
            </p>
          </div>
        </div>
        <div className="mt-12 max-w-lg mx-auto">
          <div className="flex flex-col border rounded-xl p-4 sm:p-6 lg:p-5 dark:border-neutral-700">
            <h2 className="mb-0 text-xl font-semibold text-gray-800 dark:text-neutral-200">
              Fill in the form
            </h2>
            <form onSubmit={submiter}>
              <div className="grid gap-4 lg:gap-6">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 lg:gap-6">
                  <div>
                    <label
                      htmlFor="hs-email-contacts-1"
                      className="block mb-2 text-sm text-gray-700 font-medium dark:text-white"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      name="hs-email-contacts-1"
                      id="hs-email-contacts-1"
                      autoComplete="email"
                      className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="hs-phone-number-1"
                      className="block mb-2 text-sm text-gray-700 font-medium dark:text-white"
                    >
                      Phone Number
                    </label>
                    <input
                      type="text"
                      name="hs-phone-number-1"
                      id="hs-phone-number-1"
                      className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                      onChange={(e) => setNum(e.target.value)}
                      value={num}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-1">
                  <div>
                    <label
                      htmlFor="hs-query"
                      className="block mb-2 text-sm text-gray-700 font-medium dark:text-white"
                    >
                      Query
                    </label>
                    <textarea
                      name="hs-query"
                      id="hs-query"
                      rows="5"
                      className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                      onChange={(e) => setQuery(e.target.value)}
                      value={query}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-6 grid">
                <button
                  type="submit"
                  className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
                >
                  Submit ticket
                </button>
              </div>
              <div className="mt-3 text-center">
                <p className="text-sm text-gray-500 dark:text-neutral-500">
                  We'll get back to you in 1-2 business days.
                </p>
              </div>
            </form>
          </div>
        </div>
        <div className="mt-12 grid sm:grid-cols-2 lg:grid-cols-3 items-center gap-4 lg:gap-8">
          
    
        </div>
      </div>
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="absolute inset-0 bg-gray-900 opacity-50"></div>
          <div className="bg-white dark:bg-neutral-900 rounded-lg p-8 shadow-lg z-10">
            <p className="text-xl font-bold dark:text-neutral-200">
              Your response has been recorded
            </p>
            <p className="mt-4 dark:text-neutral-400">
              Our support team will reach out to you shortly. Thank you for your
              patience.
            </p>
            <button
              className="mt-6 px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
              onClick={() => setModalOpen(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
