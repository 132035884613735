import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import DOMPurify from 'dompurify';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useSelector } from 'react-redux';  // Add this import
import Navbar from '../../components/navbar/Navbar';

const Sidebar = ({ dataLeft, acc }) => {
  return (
    <div className="bg-gray-900 text-gray-300 w-64 h-full overflow-y-auto flex flex-col">
      <div className="p-6 flex-grow">
        <h2 className="text-xl font-semibold mb-8 text-white">Dashboard</h2>
        <nav>
          <ul className="space-y-4">
            <li>
              <Link
                to="/cyberlake/dashboard"
                className="flex items-center py-2 px-4 rounded transition-colors hover:bg-gray-800"
                onClick={(e) => {
                  e.preventDefault();
                  alert("You don't have Neptaverse access");
                }}
              >
                <span className="text-xl mr-3" role="img" aria-label="analytics">📊</span>
                Neptaverse Analytics
              </Link>
            </li>
            <li>
              <Link to="/cyberlake/upload" className="flex items-center py-2 px-4 rounded transition-colors hover:bg-gray-800">
                <svg className="w-5 h-5 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
                </svg>
                Upload to cyberlake
              </Link>
            </li>
            <li>
            <li>
  <Link to="/cyberlake/chat" className="flex items-center py-2 px-4 rounded transition-colors hover:bg-gray-800">
    <span className="text-xl mr-3" role="img" aria-label="snake">🐍</span>
    <span className="flex items-center">
      Echidna chat
      <span className="ml-2 text-xs bg-yellow-500 text-black px-1 rounded">beta</span>
    </span>
  </Link>
</li>
<li>
  <Link to="/echidnamail" className="flex items-center py-2 px-4 rounded transition-colors hover:bg-gray-800">
    <svg className="w-6 h-6 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
      <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
    </svg>
    <span className="flex items-center">
      Echidna mail
      <span className="ml-2 text-xs bg-yellow-500 text-black px-1 rounded">beta</span>
    </span>
  </Link>
</li>
            </li>
          </ul>
        </nav>
      </div>
      {!acc && (
        <div className="p-6 border-t border-gray-700">
          
        </div>
      )}
    </div>
  );
};

const EchidnaChat = () => {
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef(null);
  const [hasCyberlakeAccess, setHasCyberlakeAccess] = useState(false);
  const { userInfo } = useSelector((state) => state.auth);


  useEffect(() => {
    checkCyberlakeAccess();
  }, [userInfo]);
  

  const checkCyberlakeAccess = async () => {
    try {
      const userId = userInfo._id;
      console.log("Frontend userId:", userId);
      const response = await axios.post('https://web-production-5ee8.up.railway.app/auth/cyberlake', { userId: userId });
      setHasCyberlakeAccess(response.data.hasAccess);
    } catch (error) {
      console.error('Error checking Cyberlake access:', error);
      setHasCyberlakeAccess(false);
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(scrollToBottom, [messages]);

  const renderMessage = (message) => {
    if (message.content.startsWith('```') && message.content.endsWith('```')) {
      const code = message.content.slice(3, -3);
      return (
        <SyntaxHighlighter language="javascript" style={vscDarkPlus}>
          {code}
        </SyntaxHighlighter>
      );
    }
    return <span>{message.content}</span>;
  };

  const handleSendMessage = async () => {
    if (!hasCyberlakeAccess) {
      alert('You don\'t have access to Echidna chat.');
      return;
    }

    if (inputMessage.trim() === '') return;
    const sanitizedInput = DOMPurify.sanitize(inputMessage, { ALLOWED_TAGS: [] });
    const newUserMessage = { role: 'user', content: sanitizedInput };
    setMessages(prevMessages => [...prevMessages, newUserMessage]);
    setInputMessage('');
    setIsLoading(true);

    try {
      const response = await axios.post('https://web-production-5ee8.up.railway.app/echidna/chat', {
        messages: [...messages, newUserMessage],
      });
      const newAiMessage = { role: 'assistant', content: response.data.message };
      setMessages(prevMessages => [...prevMessages, newAiMessage]);
    } catch (error) {
      console.error('Error sending message:', error);
      setMessages(prevMessages => [
        ...prevMessages,
        { role: 'system', content: `Error: ${error.message}` },
      ]);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="h-screen flex flex-col bg-gray-900">
      <Navbar />
      <div className="flex flex-1 overflow-hidden">
        <Sidebar dataLeft={100} acc={false} />
        <div className="flex-1 flex flex-col">
          <div className="flex-1 overflow-y-auto p-4 space-y-4">
            {messages.map((message, index) => (
              <div
                key={index}
                className={`flex ${message.role === 'user' ? 'justify-end' : 'justify-start'}`}
              >
                <div
                  className={`max-w-[70%] px-4 py-2 rounded-lg ${
                    message.role === 'user' ? 'bg-blue-600 text-white' : 'bg-gray-700 text-gray-200'
                  }`}
                >
                  {renderMessage(message)}
                </div>
              </div>
            ))}
            {isLoading && (
              <div className="flex justify-start">
                <div className="bg-gray-700 text-gray-300 px-4 py-2 rounded-lg">Thinking...</div>
              </div>
            )}
            <div ref={messagesEndRef} />
          </div>
          <div className="p-4 bg-gray-800 border-t border-gray-700">
            <div className="flex items-center">
              <input
                type="text"
                className="flex-grow px-4 py-2 bg-gray-700 text-white rounded-l-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={inputMessage}
                onChange={(e) => setInputMessage(e.target.value)}
                onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
                placeholder="Type your message..."
                disabled={isLoading}
              />
              <button
                onClick={handleSendMessage}
                disabled={isLoading}
                className="px-6 py-2 bg-blue-600 text-white rounded-r-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 disabled:opacity-50 transition duration-200"
              >
                Send
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EchidnaChat;