"use client";
import Footer from "../../components/footer/Footer";
import { motion, stagger, useAnimate, useInView } from "framer-motion";
import { useEffect } from "react";
import React, { useState } from 'react';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Navbar from "../../components/navbar/Navbar";
import microsoft from '../../assets/ContentImages/microsoft.png';
import amazon from '../../assets/ContentImages/amazon.png';
import { Upload, Mail, Shield, Download, CheckCircle } from 'lucide-react';
import Confetti from 'react-confetti';

export function cn(...classes) {
  return classes.filter(Boolean).join(' ');
}

const EmailComparisonSlider = () => {
  const [emailCount, setEmailCount] = useState(60);
  const aiCost = 25;
  const aiEmails = 1000;
  const humanHourlyRate = 30;
  const humanEmailsPerHour = 3;

  const calculateAICost = (emails) => {
    return Math.ceil(emails / aiEmails) * aiCost;
  };

  const calculateHumanCost = (emails) => {
    const hoursNeeded = emails / humanEmailsPerHour;
    return hoursNeeded * humanHourlyRate;
  };

  const aiCostForEmails = calculateAICost(emailCount);
  const humanCostForEmails = calculateHumanCost(emailCount);

  const aiCostPerEmail = aiCostForEmails / emailCount;
  const humanCostPerEmail = humanCostForEmails / emailCount;

  return (
    <div className="max-w-2xl mx-auto p-6 font-sans">
      <h2 className="text-2xl font-bold text-center mb-6">Cold Email ROI: AI vs. Human-Written</h2>
      <div className="mb-6">
        <input
          type="range"
          min="1"
          max="1000"
          value={emailCount}
          onChange={(e) => setEmailCount(parseInt(e.target.value))}
          className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
        />
        <p className="text-center mt-2">Number of personalized B2B emails: {emailCount}</p>
      </div>
      <div className="flex justify-between mb-4">
        <div className="text-center flex-1">
          <h3 className="text-lg font-semibold mb-2">AI Cost</h3>
          <p className="text-xl">${aiCostForEmails.toFixed(2)}</p>
          <p className="text-sm text-gray-600">(${aiCostPerEmail.toFixed(2)} per email)</p>
        </div>
        <div className="text-center flex-1">
          <h3 className="text-lg font-semibold mb-2">Human Cost</h3>
          <p className="text-xl">${humanCostForEmails.toFixed(2)}</p>
          <p className="text-sm text-gray-600">(${humanCostPerEmail.toFixed(2)} per email)</p>
        </div>
      </div>
      <div className="text-sm text-gray-600 text-center">
        <p>AI: $25 for 2000 emails (${(aiCost / aiEmails).toFixed(3)} per email when buying in bulk)</p>
        <p>Human: $30/hour, 3 super personalized emails/hour (${(humanHourlyRate / humanEmailsPerHour).toFixed(2)} per email)</p>
      </div>
    </div>
  );
};






const Statos = () => {
  return (
    <div className="bg-transparent shadow-lg rounded-lg p-6 max-w-sm mx-auto">
      <h2 className="text-2xl font-bold text-center mb-6">B2B Cold Email Campaign Performance</h2>
      
      <div className="space-y-4">
        <div className="flex items-center justify-between">
          <span className="text-gray-600 font-medium">Open Rate</span>
          <div className="flex items-center">
            <div className="w-32 bg-gray-200 rounded-full h-2.5 mr-2">
              <div className="bg-blue-600 h-2.5 rounded-full" style={{width: "75%"}}></div>
            </div>
            <span className="text-blue-600 font-bold">75%</span>
          </div>
        </div>

        <div className="flex items-center justify-between">
          <span className="text-gray-600 font-medium">Response Rate</span>
          <div className="flex items-center">
            <div className="w-32 bg-gray-200 rounded-full h-2.5 mr-2">
              <div className="bg-green-500 h-2.5 rounded-full" style={{width: "20%"}}></div>
            </div>
            <span className="text-green-500 font-bold">20%</span>
          </div>
        </div>

        <div className="flex items-center justify-between">
          <span className="text-gray-600 font-medium">Spam rate</span>
          <div className="flex items-center">
            <div className="w-32 bg-gray-200 rounded-full h-2.5 mr-2">
              <div className="bg-red-500 h-2.5 rounded-full" style={{width: "5%"}}></div>
            </div>
            <span className="text-red-500 font-bold">&lt;1%</span>
          </div>
        </div>
      </div>

      <div className="mt-6 text-center text-sm text-gray-500">
        Based on 10,000 B2B cold emails sent
      </div>
    </div>
  )
}

export function ArcadeEmbed() {
  return (
    <div style={{ position: 'relative', paddingBottom: 'calc(50.9375% + 41px)', height: 0, width: '100%' }}>
      <iframe
        src="https://demo.arcade.software/fAMuWsl7LbetJORNYtMG?embed&embed_mobile=tab&embed_desktop=inline&show_copy_link=true"
        title="Metabees Cold Email Demo"
        frameBorder="0"
        loading="lazy"
        allowFullScreen
        allow="clipboard-write"
        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', colorScheme: 'light' }}
      />
    </div>
  )
}
const Step = ({ icon: Icon, title, description, isCompleted, onComplete }) => (
  <div
    className={`flex flex-col items-center p-6 bg-gray-800 rounded-lg shadow-md transition-all duration-300 hover:shadow-xl hover:scale-105 transform ${
      isCompleted ? 'border border-green-500' : ''
    }`}
    onClick={onComplete}
  >
    <div className={`p-3 ${isCompleted ? 'bg-green-500' : 'bg-purple-600'} text-white rounded-full mb-4`}>
      <Icon size={28} />
    </div>
    <h3 className="text-xl font-semibold mb-2 text-gray-200">{title}</h3>
    <p className="text-center text-gray-400">{description}</p>
    {isCompleted && (
      <div className="mt-4 flex items-center text-green-500">
        <CheckCircle size={24} />
        <span className="ml-2">Step Completed!</span>
      </div>
    )}
  </div>
);

const ProgressBar = ({ currentStep, totalSteps }) => {
  const progress = (currentStep / totalSteps) * 100;

  return (
    <div className="w-full bg-gray-700 h-2 rounded-full my-6">
      <div className="bg-green-500 h-full rounded-full" style={{ width: `${progress}%` }} />
    </div>
  );
};

const RewardModal = ({ isVisible, onClose }) => (
  isVisible ? (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 z-50">
      <div className="bg-black-800 p-8 rounded-lg text-center shadow-lg">
        <h2 className="text-3xl text-white font-bold mb-4">Process Completed!</h2>
        <p className="text-gray-400 mb-6">You have successfully gone through all steps!</p>
        <button
          className="px-6 py-2 bg-purple-600 text-white font-semibold rounded-full hover:bg-purple-500"
          onClick={onClose}
        >
          Awesome! 🎉
        </button>
      </div>
    </div>
  ) : null
);

const EmailProcessShowcase = () => {
  const [completedSteps, setCompletedSteps] = useState([false, false, false, false]);
  const [points, setPoints] = useState(0);
  const [showConfetti, setShowConfetti] = useState(false);
  const [rewardVisible, setRewardVisible] = useState(false);

  const steps = [
    {
      icon: Upload,
      title: 'Upload CSV',
      description: 'Step 1: Upload your CSV with your lead data.',
    },
    {
      icon: Mail,
      title: 'Generate Emails',
      description: 'Step 2: AI generates personalized emails for your leads.',
    },
    {
      icon: Shield,
      title: 'Verify Emails',
      description: 'Step 3: Automatically verify emails for quality and deliverability.',
    },
    {
      icon: Download,
      title: 'Download Results',
      description: 'Step 4: Download your CSV with the enhanced lead information.',
    },
  ];

  const handleCompleteStep = (index) => {
    if (!completedSteps[index]) {
      const updatedSteps = completedSteps.map((step, i) => (i === index ? true : step));
      setCompletedSteps(updatedSteps);
      setPoints(points + 100); // Adding points for completing steps
      setShowConfetti(true);
      setTimeout(() => setShowConfetti(false), 3000); // Confetti animation for 3 seconds

      // Show reward modal after all steps are completed
      if (updatedSteps.every(step => step)) {
        setTimeout(() => setRewardVisible(true), 1000);
      }
    }
  };

  const closeRewardModal = () => {
    setRewardVisible(false);
  };

  const currentStep = completedSteps.filter(Boolean).length;

  return (
    <div className="bg-black py-16 px-4 sm:px-6 lg:px-8 relative">
      {showConfetti && <Confetti width={window.innerWidth} height={window.innerHeight} />}
      <div className="max-w-7xl mx-auto">
        <h2 className="text-4xl font-extrabold text-white text-center mb-8">
          Fun Steps to Perfect Sales Emails 🚀
        </h2>
        <div className="text-white text-center text-xl mb-4">
          Points: <span className="font-bold text-green-400">{points}</span>
        </div>
        <ProgressBar currentStep={currentStep} totalSteps={steps.length} />
        <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-4">
          {steps.map((step, index) => (
            <Step
              key={index}
              {...step}
              isCompleted={completedSteps[index]}
              onComplete={() => handleCompleteStep(index)}
            />
          ))}
        </div>
      </div>
      <RewardModal isVisible={rewardVisible} onClose={closeRewardModal} />
    </div>
  );
};

export const TypewriterEffect = ({
  words,
  className,
  cursorClassName
}) => {
  const wordsArray = words.map((word) => {
    return {
      ...word,
      text: (word.text + (word.text.endsWith(' ') ? '' : ' ')).split(''),
    };
  });

  const [scope, animate] = useAnimate();
  const isInView = useInView(scope);
  useEffect(() => {
    if (isInView) {
      animate("span", {
        display: "inline-block",
        opacity: 1,
      }, {
        duration: 0.3,
        delay: stagger(0.1),
        ease: "easeInOut",
      });
    }
  }, [isInView, animate]);

  const renderWords = () => {
    return (
      <motion.div ref={scope} className="inline">
        {wordsArray.map((word, idx) => {
          return (
            <div key={`word-${idx}`} className="inline-block">
              {word.text.map((char, index) => (
                <motion.span
                  initial={{}}
                  key={`char-${index}`}
                  className={cn(`dark:text-white text-black opacity-0`, word.className)}
                  style={{ display: 'inline-block', whiteSpace: 'pre' }}
                >
                  {char}
                </motion.span>
              ))}
            </div>
          );
        })}
      </motion.div>
    );
  };

  return (
    <div
      className={cn(
        "text-base sm:text-xl md:text-3xl lg:text-5xl font-bold text-center",
        className
      )}>
      {renderWords()}
      <motion.span
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
        }}
        transition={{
          duration: 0.8,
          repeat: Infinity,
          repeatType: "reverse",
        }}
        className={cn(
          "inline-block rounded-sm w-[4px] h-4 md:h-6 lg:h-10 bg-blue-500",
          cursorClassName
        )}></motion.span>
    </div>
  );
};

function Echidna() {
  const words = [
    { text: "Supercharge " },
    { text: "your " },
    { text: "AI-powered " },
    { text: "cold " },
    { text: "email campaigns.", className: "text-blue-500 dark:text-blue-500" },
  ];

  return (
    <div className="bg-black min-h-screen text-white">
      <Navbar />
      <div className="container mx-auto px-4 py-20 flex flex-col items-center justify-center min-h-[calc(100vh-80px)]">
     

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center"
        >
          <h1 className="text-4xl md:text-6xl font-extrabold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-violet-400 to-pink-600">
            AI Cold Email Generator
          </h1>
          <h2 className="text-xl md:text-2xl font-light mb-8 text-gray-300 max-w-2xl mx-auto">
            Revolutionize Your B2B Outreach with AI-Powered Personalization
          </h2>
          <div className="mb-12">
            <TypewriterEffect words={words} />
          </div>
          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <a href="/echidnamail" className="inline-block">
              <button className="bg-gradient-to-r from-violet-600 to-pink-600 text-white font-bold py-3 px-8 rounded-full shadow-lg hover:shadow-xl transition duration-300 ease-in-out transform hover:-translate-y-1">
                Try Our AI Cold Email Writer Now
              </button>
            </a>
          </motion.div>
        </motion.div>
        
        <motion.div
          initial={{ opacity: 0, y: 40 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.5, duration: 0.8 }}
          className="mt-16"
        >
        <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '50px' }}>
  <a href="https://www.producthunt.com/posts/echidna-mail?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-echidna&#0045;mail" target="_blank">
    <img
      src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=486584&theme=dark"
      alt="Echidna mail - B2B *super personalized* cold email generator | Product Hunt"
      style={{ width: '250px', height: '54px' }}
    />
  </a>
</div>

          
          <div className="pt-40">
            <h2 className="text-3xl font-bold text-center mt-16 mb-12">Interactive AI Cold Email Demo: See Our Generator in Action</h2>
            <ArcadeEmbed />
          </div>
        </motion.div>
      </div>
  
      <div className="max-w-7xl mx-auto px-4 py-16">
        <h2 className="text-3xl font-bold text-center mb-12">Trusted By B2B Leaders</h2>
        <div className="flex flex-wrap justify-center items-center gap-40 mb-16">
          <img src={microsoft} alt="Microsoft logo" className="h-12 object-contain" />
          <img src={amazon} alt="Amazon logo" className="h-12 object-contain" />
        
        </div>
        
        
        <div className="pt-60">
        <EmailProcessShowcase />
</div>
<div>
  
</div>

        
        <div className="container mx-auto px-4">
        <div className="container mx-auto px-4">
        <div className="container mx-auto px-4">
  
</div>

</div>

</div>

  
        <div className="pt-60">
          <h2 className="text-3xl font-bold text-center mb-12">See the Difference: AI vs. Traditional Cold Emails</h2>
          <EmailComparisonSlider />
        </div>
        
        <div className="pt-60">
          <h2 className="text-3xl font-bold text-center mb-12">Our AI Cold Email Performance</h2>
          <Statos />
        </div>
        
        <div className="pt-60">
          <h2 className="text-3xl font-bold text-center mb-12">Frequently Asked Questions About AI Cold Emails</h2>
          <div className="space-y-6">
            <div>
              <h3 className="text-xl font-semibold mb-2">How does an AI cold email generator create personalized emails at scale?</h3>
              <p className="text-gray-300">Our AI-powered cold email writer analyzes prospect data, incorporates dynamic content based on individual preferences, and tailors messaging to specific pain points and industry trends, enabling hyper-personalization at scale.</p>
            </div>
            <div>
              <h3 className="text-xl font-semibold mb-2">What advantages does an AI cold email writer offer over traditional methods?</h3>
              <p className="text-gray-300">AI cold email technology offers superior personalization, faster content creation, data-driven insights, and the ability to scale your outreach efforts while maintaining high-quality, targeted messaging.</p>
            </div>
            <div>
              <h3 className="text-xl font-semibold mb-2">How can I improve the effectiveness of B2B emails using AI?</h3>
              <p className="text-gray-300">Leverage our AI cold email generator to create hyper-personalized content, use data-driven insights for compelling value propositions, segment your audience precisely, and employ A/B testing to continually refine your approach.</p>
            </div>
            <div>
              <h3 className="text-xl font-semibold mb-2">How does AI help in avoiding spam filters with cold emails?</h3>
              <p className="text-gray-300">Our AI cold email writer is designed to create content that naturally avoids spam trigger words, maintains a professional tone, and follows email best practices, improving deliverability and engagement rates.</p>
            </div>
            <div>
              <h3 className="text-xl font-semibold mb-2">How can I measure the success of my AI-generated cold email campaigns?</h3>
              <p className="text-gray-300">Track key metrics such as open rates, click-through rates, response rates, and conversion rates. Our AI cold email platform provides detailed analytics to help you understand and optimize your campaign performance.</p>
            </div>
          </div>
        </div>
       
      </div>
      <Footer />
    </div>
  );
}

export default Echidna;