import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useParams } from 'react-router-dom';
import Navbar from "../../../components/navbar/Navbar";
import { useSelector } from 'react-redux';

const stripePromise = loadStripe("pk_live_51O9SlXSEHbit5Dzeg5RGcmFJiadNuWtZLkrZvEMJkdAM3BNd5a2ZDBer5e9dxNUCjG7tNWXvEvjzZkLL7EDoowh400AL2jT4zr");

const countries = [
  { name: 'United States', code: 'US' },
  { name: 'United Kingdom', code: 'GB' },
  { name: 'Canada', code: 'CA' },
  { name: 'Australia', code: 'AU' },
  { name: 'Germany', code: 'DE' },
  { name: 'France', code: 'FR' },
  { name: 'Japan', code: 'JP' },
  { name: 'Italy', code: 'IT' },
  { name: 'Spain', code: 'ES' },
  { name: 'Netherlands', code: 'NL' },
  { name: 'Sweden', code: 'SE' },
  { name: 'Norway', code: 'NO' },
  { name: 'Denmark', code: 'DK' },
  { name: 'Finland', code: 'FI' },
  { name: 'Switzerland', code: 'CH' },
  { name: 'Belgium', code: 'BE' },
  { name: 'Austria', code: 'AT' },
  { name: 'New Zealand', code: 'NZ' },
  { name: 'Ireland', code: 'IE' },
  { name: 'Singapore', code: 'SG' },
  { name: 'Hong Kong', code: 'HK' },
  { name: 'South Korea', code: 'KR' },
  { name: 'Brazil', code: 'BR' },
  { name: 'Mexico', code: 'MX' },
  { name: 'Argentina', code: 'AR' },
  { name: 'South Africa', code: 'ZA' },
  { name: 'United Arab Emirates', code: 'AE' },
  { name: 'Russia', code: 'RU' },
  { name: 'China', code: 'CN' },
  { name: 'Poland', code: 'PL' },
  { name: 'Turkey', code: 'TR' },
  { name: 'Greece', code: 'GR' },
  { name: 'Portugal', code: 'PT' },
  { name: 'Israel', code: 'IL' },
  { name: 'Saudi Arabia', code: 'SA' },
  { name: 'Malaysia', code: 'MY' },
  { name: 'Thailand', code: 'TH' },
  { name: 'Indonesia', code: 'ID' },
  { name: 'Philippines', code: 'PH' },
  { name: 'Vietnam', code: 'VN' },
  { name: 'Egypt', code: 'EG' },
  { name: 'Nigeria', code: 'NG' },
  { name: 'Kenya', code: 'KE' },
  { name: 'Chile', code: 'CL' },
  { name: 'Colombia', code: 'CO' },
  { name: 'Peru', code: 'PE' },
  { name: 'Czech Republic', code: 'CZ' },
  { name: 'Romania', code: 'RO' },
  { name: 'Hungary', code: 'HU' },
];

function CheckoutForm() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const { userInfo } = useSelector((state) => state.auth);
  const userId = userInfo?._id;
  const [billingAddress, setBillingAddress] = useState({
    line1: "",
    line2: "",
    city: "",
    state: "",
    postalCode: "",
    country: "",
  });
  const stripe = useStripe();
  const elements = useElements();
  let { plan } = useParams();

  const createSubscription = async (e) => {
    e.preventDefault();
    try {
      const cardElement = elements?.getElement(CardElement);
  
      if (!cardElement) {
        throw new Error("Invalid card element");
      }
  
      const paymentMethod = await stripe?.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: {
          name,
          email,
          address: {
            line1: billingAddress.line1,
            line2: billingAddress.line2 || undefined,
            city: billingAddress.city,
            state: billingAddress.state,
            postal_code: billingAddress.postalCode,
            country: billingAddress.country,
          },
        },
      });
  
      if (!paymentMethod || paymentMethod.error) {
        throw new Error(paymentMethod.error ? paymentMethod.error.message : "Failed to create payment method");
      }
  
      const response = await fetch("https://web-production-5ee8.up.railway.app/subscribe/echidnamail_150", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          paymentMethodId: paymentMethod.paymentMethod.id,
          name,
          email,
          billingAddress,
          plan,
          userId
        }),
      });
      
      if (!response.ok) {
        const errorText = await response.text();
        console.error('Server response:', errorText);
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const responseData = await response.json();
      
      if (!responseData.clientSecret) {
        throw new Error('No client secret received from the server');
      }
  
      const confirmPayment = await stripe?.confirmCardPayment(responseData.clientSecret);
  
      if (confirmPayment?.error) {
        alert(confirmPayment.error.message);
      } else {
        alert("Success! Check your email for the invoice.");
      }
    } catch (error) {
      console.log(error);
      alert(error.message);
    }
  };

  return (
    <div className="relative w-screen h-screen overflow-hidden bg-gray-900 text-white">
      <video
        autoPlay
        loop
        muted
        className="absolute inset-0 w-full h-full object-cover"
      >
        <source src="/path/to/your/video.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      
      <div className="relative z-10 w-full h-full bg-black bg-opacity-70 flex flex-col">
        <Navbar />
        <div className="flex-grow container mx-auto px-4 py-12 flex flex-col lg:flex-row items-center justify-center space-y-8 lg:space-y-0 lg:space-x-12 overflow-y-auto">
          <div className="lg:w-1/2 w-full max-w-md">
            <h1 className="text-4xl font-extrabold mb-6 text-purple-400">Complete Your Purchase</h1>
            <div className="bg-gray-800 rounded-xl p-6 shadow-lg">
              <h2 className="text-2xl font-bold mb-4 text-purple-300">$5/mo AI mail generator </h2>
              <ul className="space-y-3">
                {['20 Echidna Mail credits '].map((item, index) => (
                  <li key={index} className="flex items-center">
                    <svg className="h-5 w-5 mr-3 text-green-500" fill="currentColor" viewBox="0 0 20 20">
                      <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                    </svg>
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          
          <div className="lg:w-1/2 w-full max-w-2xl bg-gray-800 rounded-xl p-8 shadow-lg backdrop-filter backdrop-blur-lg bg-opacity-50">
            <h2 className="text-2xl font-bold mb-6 text-center text-purple-300">Payment Details</h2>
            <form onSubmit={createSubscription} className="space-y-4">
              <div className="grid grid-cols-2 gap-4">
                {[
                  { id: 'name', label: 'Name', type: 'text', value: name, onChange: setName },
                  { id: 'email', label: 'Email', type: 'email', value: email, onChange: setEmail },
                  { id: 'billingAddress.line1', label: 'Address', type: 'text', value: billingAddress.line1, onChange: (value) => setBillingAddress({ ...billingAddress, line1: value }) },
                  { id: 'billingAddress.city', label: 'City', type: 'text', value: billingAddress.city, onChange: (value) => setBillingAddress({ ...billingAddress, city: value }) },
                  { id: 'billingAddress.state', label: 'State', type: 'text', value: billingAddress.state, onChange: (value) => setBillingAddress({ ...billingAddress, state: value }) },
                  { id: 'billingAddress.postalCode', label: 'Postal Code', type: 'text', value: billingAddress.postalCode, onChange: (value) => setBillingAddress({ ...billingAddress, postalCode: value }) },
                ].map((field) => (
                  <div key={field.id}>
                    <label htmlFor={field.id} className="block text-sm font-medium text-gray-300 mb-1">{field.label}</label>
                    <input
                      id={field.id}
                      type={field.type}
                      value={field.value}
                      onChange={(e) => field.onChange(e.target.value)}
                      className="w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500 text-white"
                      required
                    />
                  </div>
                ))}
                <div>
                  <label htmlFor="billingAddress.country" className="block text-sm font-medium text-gray-300 mb-1">Country</label>
                  <select
                    id="billingAddress.country"
                    value={billingAddress.country}
                    onChange={(e) => setBillingAddress({ ...billingAddress, country: e.target.value })}
                    className="w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500 text-white"
                    required
                  >
                    <option value="">Select a country</option>
                    {countries.map((country) => (
                      <option key={country.code} value={country.code}>
                        {country.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div>
                <label htmlFor="card" className="block text-sm font-medium text-gray-300 mb-1">Card Details</label>
                <div className="bg-gray-700 p-3 rounded-md">
                  <CardElement options={{style: {base: {fontSize: '16px', color: 'white'}}}} />
                </div>
              </div>
              <button
                type="submit"
                disabled={!stripe}
                className="w-full bg-purple-600 text-white py-3 px-4 rounded-md hover:bg-purple-700 transition-colors duration-300 disabled:opacity-50 disabled:cursor-not-allowed font-medium text-lg"
              >
                Subscribe Now
              </button>
            </form>
            <div className="mt-6 flex items-center justify-center space-x-2">
              <img src="/path/to/stripe-logo.png" alt="Stripe" className="h-6" />
              <p className="text-sm text-gray-400">Secure payment through Stripe</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const PaymentPage5 = () => {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm />
    </Elements>
  );
};

export default PaymentPage5;