import React, { useState, useCallback, useEffect } from 'react';
import { Mail, Send, Users, BarChart2, Zap, Cloud, CheckCircle, MessageSquare, Upload, Database, BarChart, Smile, Frown, User, X, Edit, ChevronDown, Search, Plus, Calendar } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useSelector } from 'react-redux';
import Papa from 'papaparse';

const SidebarButton = ({ icon: Icon, text, to, isActive }) => {
  const navigate = useNavigate();
  return (
    <div 
      className={`flex items-center space-x-3 px-4 py-3 rounded-lg cursor-pointer transition-colors duration-200 ${
        isActive ? 'bg-blue-100 text-blue-600' : 'text-gray-600 hover:bg-gray-100'
      }`}
      onClick={() => navigate(to)}
    >
      <Icon className="w-5 h-5" />
      <span className="text-sm font-medium">{text}</span>
    </div>
  );
};

const ProspectTable = ({ prospects, selectedProspects, setSelectedProspects, onEdit, onDelete }) => {
  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedProspects(prospects.map(p => p.id));
    } else {
      setSelectedProspects([]);
    }
  };

  const handleSelectProspect = (id) => {
    setSelectedProspects(prev => 
      prev.includes(id) ? prev.filter(p => p !== id) : [...prev, id]
    );
  };

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              <input
                type="checkbox"
                checked={selectedProspects.length === prospects.length}
                onChange={handleSelectAll}
                className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
              />
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Company</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email Validity</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Lead Set</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Synced</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {prospects.map((prospect) => (
            <tr key={prospect.id}>
              <td className="px-6 py-4 whitespace-nowrap">
                <input
                  type="checkbox"
                  checked={selectedProspects.includes(prospect.id)}
                  onChange={() => handleSelectProspect(prospect.id)}
                  className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                />
              </td>
              <td className="px-6 py-4 whitespace-nowrap">{prospect.name}</td>
              <td className="px-6 py-4 whitespace-nowrap">{prospect.email}</td>
              <td className="px-6 py-4 whitespace-nowrap">{prospect.company}</td>
              <td className="px-6 py-4 whitespace-nowrap">
                <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                  prospect.emailValidity === 'valid' ? 'bg-green-100 text-green-800' :
                  prospect.emailValidity === 'invalid' ? 'bg-red-100 text-red-800' :
                  'bg-yellow-100 text-yellow-800'
                }`}>
                  {prospect.emailValidity}
                </span>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">{prospect.leadSet || 'Not Assigned'}</td>
              <td className="px-6 py-4 whitespace-nowrap">
                {prospect.source === 'backend' ? (
                  <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                    Synced
                  </span>
                ) : (
                  <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800">
                    Not Synced
                  </span>
                )}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                <button onClick={() => onEdit(prospect)} className="text-indigo-600 hover:text-indigo-900 mr-2">Edit</button>
                <button onClick={() => onDelete(prospect.id)} className="text-red-600 hover:text-red-900">Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
const NewLeadSetPopup = ({ onClose, selectedProspects, onCreateLeadSet }) => {
  const [leadSetName, setLeadSetName] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    onCreateLeadSet(leadSetName, selectedProspects);
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-8 rounded-lg max-w-md w-full shadow-xl">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold text-gray-900">Create New Lead Set</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700 transition duration-150 ease-in-out">
            <X className="w-6 h-6" />
          </button>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">Lead Set Name</label>
            <input 
              type="text" 
              value={leadSetName}
              onChange={(e) => setLeadSetName(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 bg-white" 
            />
          </div>
          <div className="mb-6">
            <p className="text-sm text-gray-600">Selected Prospects: <span className="font-semibold">{selectedProspects.length}</span></p>
          </div>
          <button type="submit" className="w-full px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-300 ease-in-out">
            Create Lead Set
          </button>
        </form>
      </div>
    </div>
  );
};

const AddProspectsPopup = ({ onClose, onProspectsAdd }) => {
  const [addMethod, setAddMethod] = useState('csv');

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      Papa.parse(file, {
        complete: (results) => {
          const prospects = results.data.map((row, index) => ({
            id: Date.now() + index,
            name: row[0],
            website: row[1],
            email: row[2],
            emailValidity: 'unknown',
            company: row[1] || 'N/A',
          }));
          onProspectsAdd(prospects);
          onClose();
        },
        header: false,
      });
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-8 rounded-lg max-w-md w-full shadow-xl">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold text-gray-900">Add New Prospects</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700 transition duration-150 ease-in-out">
            <X className="w-6 h-6" />
          </button>
        </div>
        <div className="mb-6">
          <label className="block text-sm font-medium text-gray-700 mb-2">Add Method</label>
          <select
            value={addMethod}
            onChange={(e) => setAddMethod(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 bg-white"
          >
            <option value="csv">Upload CSV</option>
            <option value="apollo" disabled>Connect to Apollo.io</option>
          </select>
        </div>
        {addMethod === 'csv' ? (
          <div className="mb-6">
            <label className="block text-sm font-medium text-gray-700 mb-2">Upload CSV File</label>
            <div className="bg-gray-50 p-4 rounded-md mb-4">
              <h3 className="text-sm font-medium text-gray-900 mb-2">Required CSV Format:</h3>
              <ul className="text-sm text-gray-600 space-y-1">
                <li>• Column A: Prospect Name</li>
                <li>• Column B: Prospect Website</li>
                <li>• Column C: Prospect Email</li>
              </ul>
              <p className="text-xs text-gray-500 mt-2">Please ensure your CSV follows this exact column order</p>
            </div>
            <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
              <div className="space-y-1 text-center">
                <Upload className="mx-auto h-12 w-12 text-gray-400" />
                <div className="flex text-sm text-gray-600">
                  <label htmlFor="file-upload" className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                    <span>Upload a file</span>
                    <input id="file-upload" name="file-upload" type="file" className="sr-only" onChange={handleFileUpload} accept=".csv" />
                  </label>
                  <p className="pl-1">or drag and drop</p>
                </div>
                <p className="text-xs text-gray-500">CSV up to 10MB</p>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};



const EditProspectPopup = ({ prospect, onClose, onSave }) => {
  const [editedProspect, setEditedProspect] = useState(prospect);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedProspect(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(editedProspect);
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-8 rounded-lg max-w-md w-full shadow-xl">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold text-gray-900">Edit Prospect</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700 transition duration-150 ease-in-out">
            <X className="w-6 h-6" />
          </button>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">Name</label>
            <input 
              type="text" 
              name="name"
              value={editedProspect.name}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 bg-white" 
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">Email</label>
            <input 
              type="email" 
              name="email"
              value={editedProspect.email}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 bg-white" 
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">Company</label>
            <input 
              type="text" 
              name="company"
              value={editedProspect.company}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 bg-white" 
            />
          </div>
          <button type="submit" className="w-full px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-300 ease-in-out">
            Save Changes
          </button>
        </form>
      </div>
    </div>
  );
};

const ProspectManagement = () => {
  const [prospects, setProspects] = useState([]);
  const [selectedProspects, setSelectedProspects] = useState([]);
  const [showNewLeadSetPopup, setShowNewLeadSetPopup] = useState(false);
  const [showAddProspectsPopup, setShowAddProspectsPopup] = useState(false);
  const [showEditProspectPopup, setShowEditProspectPopup] = useState(false);
  const [editingProspect, setEditingProspect] = useState(null);
  const [isSyncing, setIsSyncing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [leadSets, setLeadSets] = useState([]);
  const [sortBy, setSortBy] = useState('name');
  const [sortOrder, setSortOrder] = useState('asc');
  const [isBackendLoading, setIsBackendLoading] = useState(false);
  const [syncError, setSyncError] = useState(null);
  const [showSyncWarning, setShowSyncWarning] = useState(false);
  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => state.auth);

  // Fetch prospects function
  const fetchProspects = useCallback(async () => {
    if (!userInfo?._id) return;

    setIsLoading(true);
    setIsBackendLoading(true);
    try {
      const response = await axios.post('https://web-production-5ee8.up.railway.app/echidna/alleads', {
        userId: userInfo._id
      });

      if (response.data?.leads && Array.isArray(response.data.leads)) {
        const backendProspects = response.data.leads.map(lead => ({
          id: lead.did,
          name: lead.name,
          email: lead.email || lead.website,
          company: lead.company || 'N/A',
          emailValidity: lead.emailValidity || 'unknown',
          source: 'backend',
          leadSet: lead.leadSet || 'Not Assigned'
        }));

        setProspects(prevProspects => {
          const localProspects = prevProspects.filter(p => p.source !== 'backend');
          return [...localProspects, ...backendProspects];
        });
        setSyncError(null);
      } else {
        throw new Error('Invalid data format received from server');
      }
    } catch (error) {
      console.error('Failed to fetch prospects:', error);
      setSyncError(`Failed to fetch prospects: ${error.message}`);
    } finally {
      setIsLoading(false);
      setIsBackendLoading(false);
    }
  }, [userInfo]);

  useEffect(() => {
    if (userInfo?._id) {
      fetchProspects();
    }
  }, [userInfo, fetchProspects]);



  // Initial load effect
  useEffect(() => {
    if (userInfo?._id) {
      fetchProspects();
    }
  }, [userInfo, fetchProspects]);

  // Show warning dialog first
  const handleSyncToServer = useCallback(() => {
    setShowSyncWarning(true);
  }, []);

  // Handle actual sync after confirmation
  const handleConfirmedSync = useCallback(async () => {
    if (!userInfo?._id) {
      setSyncError('User information is not available. Please log in and try again.');
      return;
    }

    setIsSyncing(true);
    setIsBackendLoading(true);
    setSyncError(null);

    try {
      const response = await axios.post('https://web-production-5ee8.up.railway.app/echidna/addp', {
        prospects,
        leadSets,
        userId: userInfo._id
      });

      if (response.data.success) {
        const syncedProspects = response.data.updatedProspects.reduce((acc, p) => {
          const existingProspect = prospects.find(prospect => prospect.id === p.id);
          if (existingProspect) {
            return [...acc, { ...existingProspect, source: 'backend' }];
          } else {
            return [...acc, p];
          }
        }, []);

        setProspects(prev => [
          ...prev.filter(p => !syncedProspects.some(sp => sp.id === p.id)),
          ...syncedProspects
        ]);
        setLeadSets(response.data.updatedLeadSets);
        setSyncError(null);
        
        // Refresh the page after successful sync
        window.location.reload();
      } else if (response.data.message === 'Prospects processed successfully') {
        await fetchProspects();
        setSyncError(null);
        // Refresh the page after successful sync
        window.location.reload();
      } else {
        throw new Error(response.data.message || 'Unknown error');
      }
    } catch (error) {
      console.error('Sync failed:', error);
      setSyncError(`Sync failed: ${error.message}`);
    } finally {
      setIsSyncing(false);
      setIsBackendLoading(false);
      setShowSyncWarning(false);
    }
  }, [userInfo, prospects, leadSets, fetchProspects]);


  const handleProspectsAdd = useCallback((newProspects) => {
    setProspects(prevProspects => [...prevProspects, ...newProspects]);
  }, []);

  const handleSaveEdit = useCallback(async (editedProspect) => {
    if (editedProspect.source === 'backend') {
      setIsBackendLoading(true);
      try {
        // Assuming you have an endpoint for updating prospects
        const response = await axios.post('https://web-production-5ee8.up.railway.app/echidna/updateprospect', {
          userId: userInfo._id,
          prospectData: editedProspect
        });

        if (response.data.success) {
          await fetchProspects(); // Refresh data after successful update
        } else {
          throw new Error(response.data.message || 'Failed to update prospect');
        }
      } catch (error) {
        console.error('Error updating prospect:', error);
        setSyncError(`Error updating prospect: ${error.message}`);
      } finally {
        setIsBackendLoading(false);
      }
    } else {
      // Handle local prospect updates
      setProspects(prev => 
        prev.map(p => p.id === editedProspect.id ? editedProspect : p)
      );
    }
  }, [userInfo, fetchProspects]);

  const handleEdit = (prospect) => {
    setEditingProspect(prospect);
    setShowEditProspectPopup(true);
  };

 // Revised handleDelete with synced/unsynced handling
 const handleDelete = useCallback(async (id) => {
  const prospectToDelete = prospects.find(p => p.id === id);

  if (prospectToDelete?.source === 'backend') {
    setIsBackendLoading(true);
    try {
      const response = await axios.post('https://web-production-5ee8.up.railway.app/echidna//deteteppros', {
        userId: userInfo._id,
        prospectData: prospectToDelete
      });

      if (response.data.success) {
        // Update local states
        setProspects(prev => prev.filter(p => p.id !== id));
        setSelectedProspects(prev => prev.filter(selectedId => selectedId !== id));
        setLeadSets(prev => prev.map(set => ({
          ...set,
          prospectIds: set.prospectIds.filter(prospectId => prospectId !== id)
        })));
        setSyncError(null); // Clear any previous error
      } else {
        throw new Error(response.data.message || 'Failed to delete prospect');
      }
    } catch (error) {
      console.error('Error deleting prospect:', error);
      setSyncError(`Error deleting prospect: ${error.message}`);
    } finally {
      await fetchProspects(); // Refresh data after deletion
      setIsBackendLoading(false);
    }
  } else {
    // Handle local prospect deletion
    setProspects(prev => prev.filter(p => p.id !== id));
    setSelectedProspects(prev => prev.filter(selectedId => selectedId !== id));
    setLeadSets(prev => prev.map(set => ({
      ...set,
      prospectIds: set.prospectIds.filter(prospectId => prospectId !== id)
    })));
  }
}, [prospects, userInfo, fetchProspects]);


  const handleCreateLeadSet = useCallback(async (name, selectedIds) => {
    const newLeadSet = {
      id: Date.now(),
      name,
      prospectIds: selectedIds
    };

    try {
      // Update local state first for immediate feedback
      setProspects(prev =>
        prev.map(prospect =>
          selectedIds.includes(prospect.id) ? { ...prospect, leadSet: name } : prospect
        )
      );
      setLeadSets(prev => [...prev, newLeadSet]);
      setSelectedProspects([]);

      // If any of the selected prospects are from backend, sync changes
      const hasBackendProspects = prospects
        .filter(p => selectedIds.includes(p.id))
        .some(p => p.source === 'backend');

      if (hasBackendProspects) {
        setIsBackendLoading(true);
        await handleSyncToServer();
        setIsBackendLoading(false);
      }
    } catch (error) {
      console.error('Error creating lead set:', error);
      setSyncError(`Error creating lead set: ${error.message}`);
    }
  }, [prospects, handleSyncToServer]);

  const getLeadSetName = (prospectId) => {
    const leadSet = leadSets.find(set => set.prospectIds.includes(prospectId));
    return leadSet ? leadSet.name : 'Not Assigned';
  };

  const sortedProspects = [...prospects].sort((a, b) => {
    let aValue, bValue;
    
    if (sortBy === 'leadSet') {
      aValue = getLeadSetName(a.id);
      bValue = getLeadSetName(b.id);
    } else {
      aValue = a[sortBy];
      bValue = b[sortBy];
    }

    if (aValue < bValue) return sortOrder === 'asc' ? -1 : 1;
    if (aValue > bValue) return sortOrder === 'asc' ? 1 : -1;
    return 0;
  });

  const filteredProspects = sortedProspects.filter(prospect =>
    prospect.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    prospect.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
    prospect.company.toLowerCase().includes(searchTerm.toLowerCase()) ||
    getLeadSetName(prospect.id).toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSort = (column) => {
    if (sortBy === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(column);
      setSortOrder('asc');
    }
  };

  return (
    <div className="flex h-screen bg-gray-100">
      {showNewLeadSetPopup && (
        <NewLeadSetPopup 
          onClose={() => setShowNewLeadSetPopup(false)} 
          selectedProspects={selectedProspects}
          onCreateLeadSet={handleCreateLeadSet}
        />
      )}
      {showAddProspectsPopup && (
        <AddProspectsPopup 
          onClose={() => setShowAddProspectsPopup(false)} 
          onProspectsAdd={handleProspectsAdd}
        />
      )}
      {showEditProspectPopup && (
        <EditProspectPopup 
          prospect={editingProspect}
          onClose={() => setShowEditProspectPopup(false)}
          onSave={handleSaveEdit}
        />
      )}
       {showSyncWarning && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4">
            <div className="mb-4">
              <h3 className="text-lg font-semibold mb-2">⚠️ Warning: Sync Operation</h3>
              <p className="text-red-600 font-semibold mb-2">
                This action will permanently remove all unsynced prospects from your view.
              </p>
              <p className="text-gray-600">
                Only synced prospects will remain visible after this operation. Are you sure you want to proceed?
              </p>
            </div>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setShowSyncWarning(false)}
                className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-md transition duration-200"
              >
                Cancel
              </button>
              <button
                onClick={handleConfirmedSync}
                className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 transition duration-200"
              >
                Yes, Sync Now
              </button>
            </div>
          </div>
        </div>
      )}

        
        <aside className="w-64 bg-white shadow-md">
        <div className="p-6">
          <div className="flex items-center space-x-3 mb-8">
            <div className="w-10 h-10 bg-blue-500 rounded-full flex items-center justify-center">
              <Mail className="w-6 h-6 text-white" />
            </div>
            <span className="text-xl font-bold text-gray-800">Echidna mail</span>
          </div>
          <nav className="space-y-1">
            <SidebarButton icon={Send} text="Campaigns" to="/echidnamail/campaigns"  />
            <SidebarButton icon={Users} text="Contacts" to="/echidnamail/prospects" isActive={true}/>
            <SidebarButton icon={BarChart2} text="Analytics" to="/echidnamail/analytics" />
            <SidebarButton icon={Zap} text="Automations" to="/echidnamail/automations" />
          
            <SidebarButton icon={MessageSquare} text="Templates" to="/echidnamail/templates" />
      
          </nav>
        </div>
      </aside>
        
      <main className="flex-1 p-8 overflow-auto">
        <div className="max-w-7xl mx-auto">
          <header className="flex justify-between items-center mb-8">
            <h1 className="text-3xl font-bold text-gray-900">Prospect Management</h1>
          </header>
            
          <div className="bg-white rounded-lg shadow-lg overflow-hidden">
            <div className="p-6 border-b border-gray-200">
              <div className="flex flex-wrap items-center justify-between gap-4">
                <div className="flex items-center space-x-4 flex-grow">
                  <div className="relative flex-grow max-w-md">
                    <input
                      type="text"
                      placeholder="Search prospects..."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 bg-white"
                    />
                    <Search className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
                  </div>
                  <div className="flex items-center space-x-2">
                    <span className="text-sm text-gray-500">Sort by:</span>
                    <select
                      value={sortBy}
                      onChange={(e) => handleSort(e.target.value)}
                      className="px-2 py-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 bg-white"
                    >
                      <option value="name">Name</option>
                      <option value="email">Email</option>
                      <option value="company">Company</option>
                      <option value="leadSet">Lead Set</option>
                    </select>
                    <button
                      onClick={() => setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')}
                      className="p-1 text-gray-500 hover:text-indigo-600 focus:outline-none"
                    >
                      {sortOrder === 'asc' ? '↑' : '↓'}
                    </button>
                  </div>
                </div>
                <div className="flex space-x-4">
                  <button 
                    onClick={() => setShowNewLeadSetPopup(true)}
                    className={`px-4 py-2 rounded-md transition duration-300 ease-in-out ${
                      selectedProspects.length === 0
                        ? 'bg-gray-200 text-gray-500 cursor-not-allowed'
                        : 'bg-green-500 text-white hover:bg-green-600'
                    }`}
                    disabled={selectedProspects.length === 0}
                  >
                    Create Lead Set
                  </button>
                  <button 
                    onClick={() => setShowAddProspectsPopup(true)}
                    className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 transition duration-300 ease-in-out flex items-center"
                  >
                    <Plus className="w-5 h-5 mr-2" />
                    Add Prospects
                  </button>
                  <button 
          onClick={handleSyncToServer}
          disabled={isSyncing || prospects.length === 0 || !userInfo}
          className={`px-4 py-2 rounded-md transition duration-300 ease-in-out ${
            isSyncing || prospects.length === 0 || !userInfo
              ? 'bg-gray-200 text-gray-500 cursor-not-allowed'
              : 'bg-blue-500 text-white hover:bg-blue-600'
          }`}
        >
          {isSyncing ? 'Syncing...' : 'Sync to Server'}
        </button>
                </div>
              </div>
              {}
            </div>
            {isLoading ? (
              <div className="p-6 text-center">Loading prospects...</div>
            ) : (
              <ProspectTable 
              prospects={filteredProspects} 
              selectedProspects={selectedProspects}
              setSelectedProspects={setSelectedProspects}
              onEdit={handleEdit}
              onDelete={handleDelete}
            />
            )}
          </div>
        </div>
      </main>
      
      {isBackendLoading && (
      <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-60 z-50">
      <div className="bg-white p-8 rounded-lg shadow-xl flex flex-col items-center space-y-4">
        <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-blue-500 border-opacity-75"></div>
        <p className="text-gray-800 font-semibold">Please wait, loading...</p>
      </div>
    </div>
    
    )}
    a
  </div>
);
};

export default ProspectManagement;