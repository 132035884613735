import React, { useState, useEffect } from 'react';
import { Mail, Send, Users, BarChart2,Trash2 ,AlertTriangle, Zap, Cloud, CheckCircle, MessageSquare,Eye, Smile, Frown, User, X, Edit, ChevronDown, Search, Plus, Calendar } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import toast from 'react-hot-toast';



const InteractiveCampaignCreator = ({ onClose }) => {
  const [step, setStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [campaignName, setCampaignName] = useState('');
  const [campaignType, setCampaignType] = useState('');
  const [selectedLeadSet, setSelectedLeadSet] = useState('');
  const [leadSets, setLeadSets] = useState([]);
  const [productDescription, setProductDescription] = useState('');
  const [productFeatures, setProductFeatures] = useState('');
  const [painPoint, setPainPoint] = useState('');
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [scheduleDate, setScheduleDate] = useState('');
  const [scheduleTime, setScheduleTime] = useState('');
  const [timeBetweenEmails, setTimeBetweenEmails] = useState('');
  const [catchupSchedule, setCatchupSchedule] = useState([]);
  const [selectedCatchupType, setSelectedCatchupType] = useState('');
  const [emailCopies, setEmailCopies] = useState([]);
  const [mood, setMood] = useState('neutral');
  const [writingStyle, setWritingStyle] = useState('');
  const { userInfo } = useSelector((state) => state.auth);

  const catchupTypes = {
    type1: "Hi there! Just following up on our previous conversation. Have you had a chance to consider our offer?",
    type2: "Hello! I wanted to touch base and see if you have any questions about our product/service.",
    type3: "Greetings! I hope this message finds you well. I'm reaching out to see if you're interested in scheduling a quick call to discuss how we can help your business."
  };

  useEffect(() => {
    fetchLeadSets();
  }, []);

  const fetchLeadSets = async () => {
    try {
      const userId = userInfo._id;
      console.log('userId:', userId);
      const response = await axios.post('https://web-production-5ee8.up.railway.app/echidna/userleads', { userId });
      const data = response.data;
      console.log('Lead sets:', data);
      
      // Handle the new response format
      if (data && data.leadList && Array.isArray(data.leadList)) {
        setLeadSets(data.leadList);
      } else {
        console.error('Unexpected data format received:', data);
        setLeadSets([]);
      }
    } catch (error) {
      console.error('Error fetching lead sets:', error);
      setLeadSets([]);
    }
  };

  const handleNext = async (e) => {
    e.preventDefault();

    // Validate the current step
    switch (step) {
      case 1:
        if (!campaignName) {
          setMood('sad');
          return;
        }
        break;
      case 2:
        if (!campaignType) {
          setMood('sad');
          return;
        }
        break;
      case 3:
        if (!productDescription || !productFeatures || !painPoint) {
          setMood('sad');
          return;
        }
        break;
      case 4:
        if (!scheduleDate || !scheduleTime || !timeBetweenEmails) {
          setMood('sad');
          return;
        }
        break;
      case 6:
        if (!selectedLeadSet) {
          setMood('sad');
          return;
        }
        break;
    }

    if (step < 7) {
      setStep(step + 1);
      setMood('happy');
    } else {
      setIsLoading(true);
      const campaignData = {
        campaignName,
        campaignType,
        selectedLeadSet,
        productDescription,
        productFeatures,
        painPoint,
        additionalInfo,
        writingStyle,
        scheduleDate,
        scheduleTime,
        timeBetweenEmails,
        catchupSchedule: ['2023-12-01', '2023-12-08'],
        selectedCatchupType: 'type1',
        userId: userInfo._id
      };

      try {
        const response = await axios.post('https://web-production-5ee8.up.railway.app/echidna/campaign', campaignData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        console.log('Campaign created successfully:', response.data);
        setIsLoading(false);
        onClose();
        window.location.reload(); // Reload the page
      } catch (error) {
        console.error('Error creating campaign:', error.response ? error.response.data : error.message);
        setIsLoading(false);
        // Optionally add error handling UI here
      }
    }
  };

  const handleBack = () => {
    if (step > 1) {
      setStep(step - 1);
      setMood('neutral');
    }
  };

  const renderMoodIcon = () => {
    switch (mood) {
      case 'happy':
        return <Smile className="w-8 h-8 text-yellow-400 animate-bounce" />;
      case 'sad':
        return <Frown className="w-8 h-8 text-blue-400 animate-pulse" />;
      default:
        return null;
    }
  };

  const stepIcons = [Mail, Users, Zap, Calendar, MessageSquare, Send, CheckCircle];

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <div className="space-y-4">
            <label htmlFor="campaignName" className="block text-lg font-medium text-gray-700">
              What's your campaign called?
            </label>
            <input
              type="text"
              id="campaignName"
              value={campaignName}
              onChange={(e) => {
                const value = e.target.value;
                // Allow input only if it doesn't contain spaces or '/'
                if (!value.includes(' ') && !value.includes('/')) {
                  setCampaignName(value);
                }
              }}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-white"
              placeholder="e.g., SummerB2BOutreach"
            />
          </div>
        );
      case 2:
        return (
          <div className="space-y-4">
            <label htmlFor="campaignType" className="block text-lg font-medium text-gray-700">
              Select campaign type
            </label>
            <select
              id="campaignType"
              value={campaignType}
              onChange={(e) => setCampaignType(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-white"
            >
              <option value="">Select a campaign type</option>
              <option value="b2b_personalized">B2B Personalized Campaign</option>
              <option value="b2c_campaign">B2C Campaign</option>
            </select>
          </div>
        );
      case 3:
        return (
          <div className="space-y-4">
            <div>
              <label htmlFor="productDescription" className="block text-lg font-medium text-gray-700">
                Explain your product or service that you wish to pitch
              </label>
              <textarea
                id="productDescription"
                value={productDescription}
                onChange={(e) => setProductDescription(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-white"
                rows="3"
              />
            </div>
            <div>
              <label htmlFor="productFeatures" className="block text-lg font-medium text-gray-700">
                Features of your product/service (20-45 words)
              </label>
              <textarea
                id="productFeatures"
                value={productFeatures}
                onChange={(e) => setProductFeatures(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-white"
                rows="3"
              />
            </div>
            <div>
              <label htmlFor="painPoint" className="block text-lg font-medium text-gray-700">
                Pain Point to be fixed by your product
              </label>
              <textarea
                id="painPoint"
                value={painPoint}
                onChange={(e) => setPainPoint(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-white"
                rows="2"
              />
            </div>
            <div>
              <label htmlFor="additionalInfo" className="block text-lg font-medium text-gray-700">
                Additional information you wish to provide (optional)
              </label>
              <textarea
                id="additionalInfo"
                value={additionalInfo}
                onChange={(e) => setAdditionalInfo(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-white"
                rows="2"
              />
            </div>
            <div>
              <label htmlFor="writingStyle" className="block text-lg font-medium text-gray-700">
                Writing Style
              </label>
              <textarea
                id="writingStyle"
                value={writingStyle}
                onChange={(e) => setWritingStyle(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-white"
                rows="2"
                placeholder="Describe your preferred writing style (e.g., formal, casual, persuasive)"
              />
            </div>
          </div>
        );
      case 4:
        return (
          <div className="space-y-4">
            <h3 className="text-lg font-medium text-gray-700">Schedule your campaign-- UTC Time</h3>
            <div className="flex space-x-4">
              <input
                type="date"
                value={scheduleDate}
                onChange={(e) => setScheduleDate(e.target.value)}
                className="px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-white"
              />
              <input
                type="time"
                value={scheduleTime}
                onChange={(e) => setScheduleTime(e.target.value)}
                className="px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-white"
              />
            </div>
            <div>
              <label htmlFor="timeBetweenEmails" className="block text-sm font-medium text-gray-700">
                Time between emails (in hours)
              </label>
              <input
                type="number"
                id="timeBetweenEmails"
                value={timeBetweenEmails}
                onChange={(e) => setTimeBetweenEmails(e.target.value)}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-white"
                min="1"
              />
            </div>
          </div>
        );
      case 5:
        return (
          <div className="space-y-4">
            <div className="bg-gray-100 p-8 rounded-md flex items-center justify-center">
              <span className="text-2xl font-bold text-gray-800">Coming Soon!</span>
            </div>
          </div>
        );
      case 6:
        return (
          <div className="space-y-4">
            <h3 className="text-lg font-medium text-gray-700">Select Lead List</h3>
            <select
              value={selectedLeadSet}
              onChange={(e) => setSelectedLeadSet(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-white"
            >
              <option value="">Select a lead list</option>
              {leadSets.map((leadSet, index) => (
                <option key={index} value={leadSet}>
                  {leadSet}
                </option>
              ))}
            </select>
          </div>
        );
      case 7:
        return (
          <div className="space-y-4">
            <h3 className="text-lg font-medium text-gray-700">Review and Deploy</h3>
            <div className="bg-gray-100 p-4 rounded-md space-y-2">
              <div className="flex items-center">
                <CheckCircle className="w-5 h-5 text-green-500 mr-2" />
                <span className="font-medium">Campaign Name:</span>
                <span className="ml-2">{campaignName}</span>
              </div>
              <div className="flex items-center">
                <CheckCircle className="w-5 h-5 text-green-500 mr-2" />
                <span className="font-medium">Campaign Type:</span>
                <span className="ml-2">{campaignType}</span>
              </div>
              <div className="flex items-center">
                <CheckCircle className="w-5 h-5 text-green-500 mr-2" />
                <span className="font-medium">Schedule:</span>
                <span className="ml-2">{`${scheduleDate} at ${scheduleTime}`}</span>
              </div>
              <div className="flex items-center">
                <CheckCircle className="w-5 h-5 text-green-500 mr-2" />
                <span className="font-medium">Time Between Emails:</span>
                <span className="ml-2">{`${timeBetweenEmails} hours`}</span>
              </div>
              <div className="flex items-center">
                <CheckCircle className="w-5 h-5 text-green-500 mr-2" />
                <span className="font-medium">Selected Catchup Type:</span>
                <span className="ml-2">{selectedCatchupType}</span>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };


  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
    <div className="bg-white p-6 rounded-lg w-11/12 md:w-2/3 lg:w-1/2 max-w-2xl relative">
      {isLoading && (
        <div className="absolute inset-0 bg-white bg-opacity-90 flex items-center justify-center rounded-lg">
          <div className="flex flex-col items-center">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600 mb-4"></div>
            <p className="text-gray-700 font-medium">Deploying your campaign...</p>
          </div>
        </div>
      )}
      
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold text-gray-800">Create Your Awesome Campaign!</h2>
        <button 
          onClick={onClose} 
          className="text-gray-500 hover:text-gray-700"
          disabled={isLoading}
        >
          <X className="w-5 h-5" />
        </button>
      </div>

        <div className="flex justify-between mb-6">
          {['Campaign Name', 'Type', 'Product Info', 'Schedule', 'Catchups', 'Email Copies', 'Deploy'].map((label, index) => {
            const StepIcon = stepIcons[index];
            return (
              <div
                key={label}
                className={`flex flex-col items-center ${
                  step >= index + 1 ? 'text-blue-500' : 'text-gray-400'
                }`}
              >
                <StepIcon className="w-8 h-8 mb-2" />
                <span className="text-sm">{label}</span>
              </div>
            );
          })}
        </div>
        <form onSubmit={handleNext} className="space-y-6">
          {renderStep()}
          <div className="flex justify-between items-center">
            {step > 1 && (
              <button
                type="button"
                onClick={handleBack}
                className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                disabled={isLoading}
              >
                Back
              </button>
            )}
            <div className="flex items-center">
              {renderMoodIcon()}
              <button
                type="submit"
                className={`ml-2 px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${
                  isLoading ? 'bg-blue-400 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700'
                } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
                disabled={isLoading}
              >
                {step < 7 ? 'Next' : 'Deploy Campaign!'}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

const StatusBadge = ({ status }) => {
  const statusStyles = {
    in_progress: 'bg-blue-100 text-blue-800',
    scheduled: 'bg-gray-100 text-gray-800',
    failed: 'bg-red-100 text-red-800',
    success: 'bg-green-100 text-green-800',
    at_risk: 'bg-yellow-100 text-yellow-800',
    delayed: 'bg-orange-100 text-orange-800'
  };

  return (
    <span className={`px-2 py-1 text-xs font-medium rounded-full ${statusStyles[status] || 'bg-gray-100 text-gray-800'}`}>
      {status?.replace(/_/g, ' ').toUpperCase()}
    </span>
  );
};

const CampaignMetrics = ({ metrics }) => {
  if (!metrics) return null;
  
  return (
    <div className="grid grid-cols-3 gap-2 mt-2">
      <div className="text-xs text-gray-500">
        Success Rate: 
        <span className="ml-1 font-medium text-gray-900">
          {metrics.delivery.successRate.toFixed(1)}%
        </span>
      </div>
      <div className="text-xs text-gray-500">
        Sent: 
        <span className="ml-1 font-medium text-gray-900">
          {metrics.delivery.sentCount}/{metrics.delivery.totalPlanned}
        </span>
      </div>
      <div className="text-xs text-gray-500">
        Failed: 
        <span className="ml-1 font-medium text-gray-900">
          {metrics.delivery.failedCount}
        </span>
      </div>
    </div>
  );
};

const SidebarButton = ({ icon: Icon, text, to, isActive }) => {
  const navigate = useNavigate();
  return (
    <div 
      className={`flex items-center space-x-3 px-4 py-3 rounded-lg cursor-pointer transition-colors duration-200 ${
        isActive ? 'bg-blue-100 text-blue-600' : 'text-gray-600 hover:bg-gray-100'
      }`}
      onClick={() => navigate(to)}
    >
      <Icon className="w-5 h-5" />
      <span className="text-sm font-medium">{text}</span>
    </div>
  );
};




const OrganizationDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState('My Organization');
  const organizations = ['My Organization', 'Team A', 'Team B', 'Team C'];

  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="px-4 py-2 bg-white text-gray-700 rounded-md border border-gray-300 hover:bg-gray-50 transition duration-300 ease-in-out flex items-center"
      >
        {selectedOrg}
        <ChevronDown className="w-4 h-4 ml-2" />
      </button>
      {isOpen && (
        <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-10 border border-gray-200">
          {organizations.map((org, index) => (
            <button
              key={index}
              className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              onClick={() => {
                setSelectedOrg(org);
                setIsOpen(false);
              }}
            >
              {org}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};


const CampaignTable = ({ campaigns, onDeleteCampaign, userId }) => {
  const navigate = useNavigate();

  // Filter out campaigns where sent count and total planned are both 0
  const filteredCampaigns = campaigns?.filter(campaign => {
    if (!campaign?.metrics?.delivery) return false;
    const { sentCount, totalPlanned } = campaign.metrics.delivery;
    return !(sentCount === 0 && totalPlanned === 0);
  });

  if (!filteredCampaigns || !filteredCampaigns.length) {
    return (
      <div className="p-4 text-center text-gray-500">
        No campaigns found
      </div>
    );
  }

  const handleViewCampaign = (campaignName) => {
    const urlFriendlyName = encodeURIComponent(campaignName.toLowerCase().replace(/ /g, '-'));
    navigate(`/echidna/${urlFriendlyName}`);
  };
  
  return (
    <div className="overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Campaign Name
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Status
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Metrics
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Risk Level
            </th>
            <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {filteredCampaigns.map((campaign) => (
            <tr key={campaign._id} className="hover:bg-gray-50">
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm font-medium text-gray-900">{campaign.campaignName}</div>
                <div className="text-xs text-gray-500">{campaign.message}</div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <StatusBadge status={campaign.status} />
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <CampaignMetrics metrics={campaign.metrics} />
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                {campaign.risk && (
                  <div className="flex flex-col">
                    <span className={`text-sm font-medium ${
                      campaign.risk.riskLevel === 'high' ? 'text-red-600' : 'text-yellow-600'
                    }`}>
                      {campaign.risk.riskLevel.toUpperCase()}
                    </span>
                    {campaign.risk.riskFactors.length > 0 && (
                      <span className="text-xs text-gray-500 mt-1">
                        {campaign.risk.riskFactors[0]}
                      </span>
                    )}
                  </div>
                )}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-right">
                <div className="flex justify-end space-x-2">
                  <button
                    onClick={() => handleViewCampaign(campaign.campaignName)}
                    className="inline-flex items-center px-3 py-1 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 active:bg-blue-700 transition duration-150 ease-in-out"
                  >
                    <Eye className="w-4 h-4 mr-2" />
                    View
                  </button>
                  <button
                    onClick={() => onDeleteCampaign(campaign.id, campaign.campaignName)}
                    className="inline-flex items-center px-3 py-1 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-red-500 active:bg-red-700 transition duration-150 ease-in-out"
                  >
                    <Trash2 className="w-4 h-4 mr-2" />
                    Delete
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};




// Domain Verification Warning Component
const DomainVerificationWarning = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4">
        <div className="flex items-center mb-4">
          <AlertTriangle className="w-6 h-6 text-yellow-500 mr-2" />
          <h3 className="text-lg font-semibold text-gray-900">Domain Verification Required</h3>
        </div>
        <p className="text-gray-600 mb-6">
          Please verify your domain before creating a campaign. This helps ensure better email deliverability and campaign success.
        </p>
        <div className="flex justify-end space-x-3">
          <button
            onClick={onClose}
            className="px-4 py-2 text-gray-600 hover:text-gray-800 font-medium rounded-md hover:bg-gray-100 transition-colors"
          >
            Close
          </button>
          <button
            onClick={() => window.location.href = '/settings/domain'}
            className="px-4 py-2 bg-blue-500 text-white font-medium rounded-md hover:bg-blue-600 transition-colors"
          >
            Set Up Domain
          </button>
        </div>
      </div>
    </div>
  );
};



const DeleteConfirmationModal = ({ isOpen, onClose, onConfirm, campaignName }) => {
  if (!isOpen) return null;
  
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4">
        <div className="flex items-center mb-4">
          <AlertTriangle className="w-6 h-6 text-red-500 mr-2" />
          <h3 className="text-lg font-semibold text-gray-900">Delete Campaign</h3>
        </div>
        <p className="text-gray-600 mb-6">
          Are you sure you want to delete the campaign "{campaignName}"? This action cannot be undone.
        </p>
        <div className="flex justify-end space-x-3">
          <button
            onClick={onClose}
            className="px-4 py-2 text-gray-600 hover:text-gray-800 font-medium rounded-md hover:bg-gray-100 transition-colors"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className="px-4 py-2 bg-red-500 text-white font-medium rounded-md hover:bg-red-600 transition-colors flex items-center"
          >
            <Trash2 className="w-4 h-4 mr-2" />
            Delete Campaign
          </button>
        </div>
      </div>
    </div>
  );
};


const CampaignDashboard = () => {
  const [showNewCampaignPopup, setShowNewCampaignPopup] = useState(false);
  const [campaigns, setCampaigns] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    campaignId: null,
    campaignName: ''
  });
  const { userInfo } = useSelector((state) => state.auth);

  const checkDomainVerification = async () => {
    try {
      const response = await axios.post('https://web-production-5ee8.up.railway.app/echidna/verifycheck', {
        userId: userInfo._id
      });
      return response.data.verified; // Updated to match the response format
    } catch (error) {
      console.error('Error checking domain verification:', error);
      return false;
    }
  };

  const handleCreateCampaignClick = async () => {
    const isDomainVerified = await checkDomainVerification();
    if (!isDomainVerified) {
      toast.error('Domain not verified. Please verify your domain before creating a campaign.');
    } else {
      setShowNewCampaignPopup(true);
    }
  };

  const fetchCampaigns = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post('https://web-production-5ee8.up.railway.app/echidna/allcamps', {
        userId: userInfo._id
      });
      
      const campaignsWithStatus = await Promise.all(
        response.data.campaigns.map(async (campaign) => {
          try {
            const monitoringResponse = await axios.post('https://web-production-5ee8.up.railway.app/echidna/campstatus', {
              userId: userInfo._id,
              campaignId: campaign.id
            });
            
            const defaultRisk = {
              riskLevel: 'low',
              riskFactors: []
            };

            const risk = monitoringResponse.data.risk || defaultRisk;
            risk.riskLevel = typeof risk.riskLevel === 'string' ? risk.riskLevel : 'low';
            
            return {
              ...campaign,
              status: monitoringResponse.data.status || 'scheduled',
              metrics: monitoringResponse.data.metrics || {
                delivery: {
                  successRate: 0,
                  sentCount: 0,
                  totalPlanned: 0,
                  failedCount: 0
                }
              },
              risk
            };
          } catch (error) {
            console.error(`Error fetching monitoring data for campaign ${campaign._id}:`, error);
            return {
              ...campaign,
              status: 'scheduled',
              metrics: {
                delivery: {
                  successRate: 0,
                  sentCount: 0,
                  totalPlanned: 0,
                  failedCount: 0
                }
              },
              risk: {
                riskLevel: 'low',
                riskFactors: []
              }
            };
          }
        })
      );
      
      setCampaigns(campaignsWithStatus);
    } catch (error) {
      console.error('Error fetching campaigns:', error);
      toast.error('Failed to fetch campaigns. Please try again.');
      setCampaigns([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (userInfo?._id) {
      fetchCampaigns();
    }
  }, [userInfo?._id]);

  const handleDeleteCampaign = async (campaignId, campaignName) => {
    setDeleteModal({
      isOpen: true,
      campaignId,
      campaignName
    });
  };

  const confirmDelete = async () => {
    const campaignId = deleteModal.campaignId;
    
    if (!campaignId || !userInfo?._id) {
      toast.error('Invalid campaign data. Unable to delete.');
      setDeleteModal({ isOpen: false, campaignId: null, campaignName: '' });
      return;
    }

    try {
      setIsLoading(true);
      
      const response = await axios.post('https://web-production-5ee8.up.railway.app/echidna/deletecampaign', {
        userId: userInfo._id,
        campaignId: campaignId.toString()
      });

      if (response.data.success) {
        setCampaigns(prevCampaigns => prevCampaigns.filter(c => c.id !== campaignId));
        toast.success('Campaign deleted successfully');
      } else {
        throw new Error(response.data.message || 'Failed to delete campaign');
      }
    } catch (error) {
      console.error('Error deleting campaign:', error);
      const errorMessage = error.response?.data?.message || 'Failed to delete campaign. Please try again.';
      toast.error(errorMessage);
    } finally {
      setIsLoading(false);
      setDeleteModal({ isOpen: false, campaignId: null, campaignName: '' });
    }
  };

  const handleClosePopup = () => {
    setShowNewCampaignPopup(false);
    fetchCampaigns();
  };

  return (
    <div className="flex h-screen bg-gray-100">
      <aside className="w-64 bg-white shadow-md">
        <div className="p-6">
          <div className="flex items-center space-x-3 mb-8">
            <div className="w-10 h-10 bg-blue-500 rounded-full flex items-center justify-center">
              <Mail className="w-6 h-6 text-white" />
            </div>
            <span className="text-xl font-bold text-gray-800">Echidna mail</span>
          </div>
          <nav className="space-y-1">
            <SidebarButton icon={Send} text="Campaigns" to="/echidnamail/campaigns"  isActive={true}/>
            <SidebarButton icon={Users} text="Contacts" to="/echidnamail/prospects" />
            <SidebarButton icon={BarChart2} text="Analytics" to="/echidnamail/analytics" />
            <SidebarButton icon={Zap} text="Automations" to="/echidnamail/automations" />
            <SidebarButton icon={MessageSquare} text="Templates" to="/echidnamail/templates" />
          </nav>
        </div>
      </aside>

      <main className="flex-1 overflow-auto">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center mb-6">
            <div className="relative flex items-center">
              <Search className="w-5 h-5 text-gray-400 absolute left-3" />
            </div>
            <button 
              onClick={handleCreateCampaignClick}
              className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-300 ease-in-out flex items-center"
            >
              <Plus className="w-5 h-5 mr-2" />
              Create Campaign
            </button>
          </div>
          
          <div className="bg-white rounded-lg shadow overflow-hidden">
            <div className="px-6 py-4 border-b border-gray-200">
              <h2 className="text-lg font-semibold text-gray-800">Current Campaigns</h2>
            </div>
            {isLoading ? (
              <div className="flex items-center justify-center p-8">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
              </div>
            ) : (
              <CampaignTable 
                campaigns={campaigns} 
                onDeleteCampaign={handleDeleteCampaign}
              />
            )}
          </div>
        </div>
      </main>

      {/* Delete Confirmation Modal */}
      {deleteModal.isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4">
            <div className="flex items-center mb-4">
              <AlertTriangle className="w-6 h-6 text-red-500 mr-2" />
              <h3 className="text-lg font-semibold text-gray-900">Delete Campaign</h3>
            </div>
            <p className="text-gray-600 mb-6">
              Are you sure you want to delete the campaign "{deleteModal.campaignName}"? This action cannot be undone.
            </p>
            <div className="flex justify-end space-x-3">
              <button
                onClick={() => setDeleteModal({ isOpen: false, campaignId: null, campaignName: '' })}
                className="px-4 py-2 text-gray-600 hover:text-gray-800 font-medium rounded-md hover:bg-gray-100 transition-colors"
              >
                Cancel
              </button>
              <button
                onClick={confirmDelete}
                className="px-4 py-2 bg-red-500 text-white font-medium rounded-md hover:bg-red-600 transition-colors flex items-center"
              >
                <Trash2 className="w-4 h-4 mr-2" />
                Delete Campaign
              </button>
            </div>
          </div>
        </div>
      )}

      {showNewCampaignPopup && (
        <InteractiveCampaignCreator
          onClose={handleClosePopup}
          userId={userInfo._id}
        />
      )}
    </div>
  );
};

export default CampaignDashboard;







