import React, { useEffect, useState, useRef } from 'react';
import Axios from 'axios'; // Import Axios if you're using it
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { ARButton } from 'three/examples/jsm/webxr/ARButton';
import { useParams } from 'react-router-dom';


const ARScene = () => {
    const containerRef = useRef();
    const renderer = useRef();
    const camera = useRef();
    const scene = useRef();
    const controller = useRef();
    const reticle = useRef();
    const hitTestSource = useRef(null);
    let hitTestSourceRequested = useRef(false);
    const gltfLoader = new GLTFLoader();
    const { objectname } = useParams();
    const [objectExists, setObjectExists] = useState(false);
    const [isPublished, setIsPublished] = useState(false);
    const [cdnUrl, setCdnUrl] = useState("");
    const [xrotation, setxRotation] = useState(0);
    const [yrotation, setyRotation] = useState(0);
    const [zrotation, setzRotation] = useState(0);
    let produrl
    let ARscale

    useEffect(() => {
        objectver();
        init();
        animate();

        return () => {
            if (containerRef.current && renderer.current) {
                containerRef.current.removeChild(renderer.current.domElement);
            }
            window.removeEventListener('resize', onWindowResize);
            // Other cleanup operations
        };
    }, [objectname]);

    function objectver(){
        const fetchObjectData = async () => {
            try {
                const existsResponse = await Axios.get(`http://localhost:5000/products/check-object-exists/${objectname}`);
                setObjectExists(existsResponse.data.exists);

                if (existsResponse.data.exists) {
                    const publishedResponse = await Axios.get(`http://localhost:5000/products/check-publish/${objectname}`);
                    setIsPublished(publishedResponse.data.published);

                    if (publishedResponse.data.published) {
                        const cdnResponse = await Axios.get(`http://localhost:5000/products/fetch-cdn/${objectname}`);
                        produrl = cdnResponse.data.cdnUrl;
                        ARscale = cdnResponse.data.ARscale;
                        //console.log(cdnResponse.data.ARscale);
                        setxRotation(cdnResponse.data.xrotation);
                       // console.log(cdnResponse.data.xrotation);
                        setyRotation(cdnResponse.data.yrotation);
                      //  console.log(cdnResponse.data.yrotation);
                        setzRotation(cdnResponse.data.zrotation);
                        //console.log(cdnResponse.data.zrotation);
                    } else { console.error('Object error'); }
                }
            } catch (error) {
                console.error('Error fetching object data:', error);
            }
            
        };
        fetchObjectData();
    }

    function init() {
        containerRef.current = document.createElement('div');
        document.body.appendChild(containerRef.current);

        scene.current = new THREE.Scene();
        camera.current = new THREE.PerspectiveCamera(70, window.innerWidth / window.innerHeight, 0.01, 20);

        const light = new THREE.HemisphereLight(0xffffff, 0xbbbbff, 3);
        light.position.set(0.5, 1, 0.25);
        scene.current.add(light);

        const ambientLight = new THREE.AmbientLight(0xffffff, 0.5); // Soft white light
        scene.current.add(ambientLight);

        renderer.current = new THREE.WebGLRenderer({ antialias: true, alpha: true });
        renderer.current.setPixelRatio(window.devicePixelRatio);
        renderer.current.setSize(window.innerWidth, window.innerHeight);
        renderer.current.xr.enabled = true;
        containerRef.current.appendChild(renderer.current.domElement);

        document.body.appendChild(ARButton.createButton(renderer.current, { requiredFeatures: ['hit-test'] }));

        controller.current = renderer.current.xr.getController(0);
        controller.current.addEventListener('select', onSelect);
        scene.current.add(controller.current);

        reticle.current = new THREE.Mesh(
            new THREE.RingGeometry(0.15, 0.2, 32).rotateX(-Math.PI / 2),
            new THREE.MeshBasicMaterial()
        );
        reticle.current.matrixAutoUpdate = false;
        reticle.current.visible = false;
        scene.current.add(reticle.current);

        window.addEventListener('resize', onWindowResize);
    }

    function onWindowResize() {
        camera.current.aspect = window.innerWidth / window.innerHeight;
        camera.current.updateProjectionMatrix();
        renderer.current.setSize(window.innerWidth, window.innerHeight);
    }

    function animate() {
        renderer.current.setAnimationLoop(render);
    }

    function onSelect() {
        if (reticle.current.visible) {
            gltfLoader.load(
                produrl,
                function (gltf) {
                    const model = gltf.scene;
                    model.position.copy(reticle.current.position);
                    if (ARscale) {
                        model.scale.set(ARscale.x, ARscale.y, ARscale.z);
                      //  console.log(ARscale);
                    }
                    if (yrotation) {
                        model.rotation.y = THREE.MathUtils.degToRad(yrotation);
                        model.rotation.x = THREE.MathUtils.degToRad(xrotation);
                        model.rotation.z = THREE.MathUtils.degToRad(zrotation); 
                    }
    
                    scene.current.add(model);
                },
                undefined,
                function (error) {
                    console.error('Error loading GLTF model', error);
                }
            );
        }
    }
    

    function render(timestamp, frame) {
        if (frame) {
            const referenceSpace = renderer.current.xr.getReferenceSpace();
            const session = renderer.current.xr.getSession();

            if (!hitTestSourceRequested.current) {
                session.requestReferenceSpace('viewer').then((refSpace) => {
                    session.requestHitTestSource({ space: refSpace }).then((source) => {
                        hitTestSource.current = source;
                    });
                });

                session.addEventListener('end',
() => {
hitTestSourceRequested.current = false;
hitTestSource.current = null;
});

            hitTestSourceRequested.current = true;
        }

        if (hitTestSource.current) {
            const hitTestResults = frame.getHitTestResults(hitTestSource.current);

            if (hitTestResults.length) {
                const hit = hitTestResults[0];

                reticle.current.visible = true;
                reticle.current.matrix.fromArray(hit.getPose(referenceSpace).transform.matrix);
                reticle.current.matrix.decompose(reticle.current.position, reticle.current.quaternion, reticle.current.scale);
            } else {
                reticle.current.visible = false;
            }
        }
    }

    renderer.current.render(scene.current, camera.current);
}

return null;
};

export default ARScene;