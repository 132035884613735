import React, { useState } from 'react';

const FuturisticCreditPage = () => {
  const [credits, setCredits] = useState(0);
  const [isRotating, setIsRotating] = useState(false);

  const handleMouseClick = () => {
    setIsRotating(!isRotating);
  };

  return (
    <div
      style={{
        backgroundColor: 'black',
        height: '100vh',
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        overflow: 'hidden', // Prevent scrolling
      }}
      onClick={handleMouseClick}
    >
      <h1 style={{ color: 'goldenrod', fontSize: '3rem', marginBottom: '2rem' }}>ADD CREDITS</h1>
      <div style={{ fontSize: '10rem', color: 'white', fontFamily: 'monospace', display: 'flex', alignItems: 'center' }}>
        <span onClick={() => setCredits(credits - 1)} style={{ cursor: 'pointer' }}>
          -
        </span>
        <span style={{ marginLeft: '1rem', marginRight: '1rem' }}>{credits}</span>
        <span onClick={() => setCredits(credits + 1)} style={{ cursor: 'pointer' }}>
          +
        </span>
      </div>
      <button class="bg-yellow-950 text-yellow-400 border border-yellow-400 border-b-4 font-medium overflow-hidden relative px-4 py-2 rounded-md hover:brightness-150 hover:border-t-4 hover:border-b active:opacity-75 outline-none duration-300 group">
  <span class="bg-yellow-400 shadow-yellow-400 absolute -top-[150%] left-0 inline-flex w-80 h-[5px] rounded-md opacity-50 group-hover:top-[150%] duration-500 shadow-[0_0_10px_10px_rgba(0,0,0,0.3)]"></span>
  Pay
</button>
    </div>
  );
};

export default FuturisticCreditPage;
