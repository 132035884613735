import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';

import Navbar from '../../components/navbar/Navbar';
import microsoft from '../../assets/ContentImages/microsoft.png';
import amazon from '../../assets/ContentImages/amazon.png';
import Footer from '../../components/footer/Footer';

function Cyberhome() {
    const canvasRef = useRef(null);

    useEffect(() => {
        // Create a new Three.js scene
        const scene = new THREE.Scene();

        // Create a camera
        const camera = new THREE.PerspectiveCamera(75, 1, 0.1, 1000);
        camera.position.set(0, 0, 20); // Adjust as needed

        // Create a renderer and append it to the canvas
        const renderer = new THREE.WebGLRenderer({ canvas: canvasRef.current });

        const updateRendererSize = () => {
          const { width, height } = canvasRef.current.getBoundingClientRect();
          renderer.setSize(width, height);
          camera.aspect = width / height;
          camera.updateProjectionMatrix();
        };

        // Initial setup
        updateRendererSize();

        // Update renderer size when window is resized
        window.addEventListener('resize', updateRendererSize);

        // Create a GLTFLoader
        const loader = new GLTFLoader();

        // Load your custom model
        loader.load(
            'https://cybverlake-test.s3.ap-south-1.amazonaws.com/server.glb', // Replace with the path to your GLTF file
            (gltf) => {
                const model = gltf.scene;
                scene.add(model);

                // Adjust model scale if needed
                model.scale.set(0.2, 0.2, 0.2);

                // Center the model
                const box = new THREE.Box3().setFromObject(model);
                const center = box.getCenter(new THREE.Vector3());
                model.position.sub(center);

                // Function to update the scene on each frame
                const animate = () => {
                    requestAnimationFrame(animate);
                    model.rotation.y += 0.005; // Rotate the model if desired
                    renderer.render(scene, camera);
                };
                animate();
            },
            (xhr) => {
                console.log((xhr.loaded / xhr.total) * 100 + '% loaded');
            },
            (error) => {
                console.error('An error occurred while loading the model:', error);
            }
        );

        // Add OrbitControls to enable mouse controls
        const controls = new OrbitControls(camera, renderer.domElement);

        // Add lights
        const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
        scene.add(ambientLight);

        const directionalLight = new THREE.DirectionalLight(0xffffff, 0.5);
        directionalLight.position.set(0, 1, 0);
        scene.add(directionalLight);

        // Cleanup function to remove the renderer
        return () => {
            if (renderer) {
                renderer.dispose();
                renderer.domElement.remove();
            }
        };
    }, []);

    return (
      <div className="w-screen h-screen bg-black">
        <Navbar />
        <div className="pt-5 md:pt-10 w-full bg-black">
          <div className="max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8">
            <div className="grid grid-cols-1 lg:grid-cols-7 gap-y-8 lg:gap-x-8 xl:gap-x-12 lg:items-center">
              <div className="lg:col-span-3 lg:pr-20">
                <h1 className="block text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-bold text-white">
                  Secure your 3D assets
                </h1>
                <p className="mt-3 text-base sm:text-lg text-neutral-400">
                  Introducing the new way to store your 3D assets securely
                </p>
                <div className="mt-5 lg:mt-8 flex flex-col items-start gap-2 sm:flex-row sm:gap-3">
                  <div className="w-full sm:w-auto">
                    <label htmlFor="hero-input" className="sr-only">
                      Search
                    </label>
                    <input
                      type="text"
                      id="hero-input"
                      name="hero-input"
                      className="py-3 px-4 block w-full sm:w-64 border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none bg-neutral-900 border-neutral-700 text-neutral-400 placeholder-neutral-500 focus:ring-neutral-600"
                      placeholder="Enter work email"
                    />
                  </div>
                  
                  <a className="w-full sm:w-auto py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
                     href="/contact" >
                    Request demo
                  </a>
                </div>
                
                <div className="mt-6 lg:mt-12">
                  <span className="text-xs font-medium text-neutral-200 uppercase">
                    Sponsored by:
                  </span>
                  <div className="mt-4 flex gap-x-6 sm:gap-x-8">
                    <img
                      className="w-16 sm:w-20 h-auto"
                      src={microsoft}
                      alt="Microsoft"
                      width={106}
                      height={36}
                    />
                    <img
                      className="w-16 sm:w-20 h-auto"
                      src={amazon}
                      alt="Amazon"
                      width={106}
                      height={10}
                    />
                  </div>
                </div>
              </div>
              
              <div className="lg:col-span-4 w-full h-[300px] sm:h-[200px] lg:h-[500px]">
                <canvas ref={canvasRef} className="w-full h-full"></canvas>
              </div>
            </div>
          </div>
   
    
            <div className='pt-40 bg-black'>
            <>
  {/* Features */}
  <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto bg-black ">
    <div className="aspect-w-16 aspect-h-7">
      <div>
      <div style={{ position: 'relative', paddingBottom: 'calc(51.927083333333336% + 41px)', height: 0, width: '100%' }}>
    <iframe src="https://demo.arcade.software/ylahG1wzo729hD5CcdMA?embed&show_copy_link=true" title="Metabees" frameborder="0" loading="lazy" webkitallowfullscreen mozallowfullscreen allowfullscreen allow="clipboard-write" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', colorScheme: 'light' }}></iframe>
</div>

      </div>
    </div>
    {/* Grid */}
    <div className="mt-5 lg:mt-16 grid lg:grid-cols-3 gap-8 lg:gap-12">
      <div className="lg:col-span-1">
        <h2 className="font-bold text-2xl md:text-3xl text-gray-800 dark:text-neutral-200">
          We tackle the challenges faced by XR and game devs
        </h2>
        <p className="mt-2 md:mt-4 text-gray-500 dark:text-neutral-500">
          Our team of qualified XR devs have built the best solution for storing your 3D files 
        </p>
      </div>
      {/* End Col */}
      <div className="lg:col-span-2">
        <div className="grid sm:grid-cols-2 gap-8 md:gap-12">
          {/* Icon Block */}
          <div className="flex gap-x-5">
            <svg
              className="flex-shrink-0 mt-1 size-6 text-blue-600 dark:text-blue-500"
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <rect width={18} height={10} x={3} y={11} rx={2} />
              <circle cx={12} cy={5} r={2} />
              <path d="M12 7v4" />
              <line x1={8} x2={8} y1={16} y2={16} />
              <line x1={16} x2={16} y1={16} y2={16} />
            </svg>
            <div className="grow">
              <h3 className="text-lg font-semibold text-gray-800 dark:text-white">
                Creative minds
              </h3>
              <p className="mt-1 text-gray-600 dark:text-neutral-400">
                We choose our teams carefully. Our people are the secret to
                great work.
              </p>
            </div>
          </div>
          {/* End Icon Block */}

          
          {/* Icon Block */}
          <div className="flex gap-x-5">
            <svg
              className="flex-shrink-0 mt-1 size-6 text-blue-600 dark:text-blue-500"
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M7 10v12" />
              <path d="M15 5.88 14 10h5.83a2 2 0 0 1 1.92 2.56l-2.33 8A2 2 0 0 1 17.5 22H4a2 2 0 0 1-2-2v-8a2 2 0 0 1 2-2h2.76a2 2 0 0 0 1.79-1.11L12 2h0a3.13 3.13 0 0 1 3 3.88Z" />
            </svg>
            <div className="grow">
              <h3 className="text-lg font-semibold text-gray-800 dark:text-white">
                Simple and affordable
              </h3>
              <p className="mt-1 text-gray-600 dark:text-neutral-400">
                From boarding passes to movie tickets, there's pretty much
                nothing you can't store with Preline.
              </p>
            </div>
          </div>
          {/* End Icon Block */}
          {/* Icon Block */}
          <div className="flex gap-x-5">
            <svg
              className="flex-shrink-0 mt-1 size-6 text-blue-600 dark:text-blue-500"
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z" />
              <path d="M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z" />
            </svg>
            <div className="grow">
              <h3 className="text-lg font-semibold text-gray-800 dark:text-white">
                Industry-leading documentation
              </h3>
              <p className="mt-1 text-gray-600 dark:text-neutral-400">
                Our documentation and extensive Client libraries contain
                everything a business needs to build a custom integration.
              </p>
            </div>
          </div>
          {/* End Icon Block */}
          {/* Icon Block */}
          <div className="flex gap-x-5">
            <svg
              className="flex-shrink-0 mt-1 size-6 text-blue-600 dark:text-blue-500"
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx={9} cy={7} r={4} />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
            <div className="grow">
              <h3 className="text-lg font-semibold text-gray-800 dark:text-white">
                Designing for people
              </h3>
              <p className="mt-1 text-gray-600 dark:text-neutral-400">
                We actively pursue the right balance between functionality and
                aesthetics, creating delightful experiences.
              </p>
            </div>
          </div>
          {/* End Icon Block */}
        </div>
      </div>
      {/* End Col */}
    </div>
    {/* End Grid */}
  </div>
  {/* End Features */}
</>

            </div>
            <div className='w-full'>
            <>
  {/* Pricing */}
  <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
    {/* Title */}
    <div className="max-w-2xl mx-auto text-center mb-10 lg:mb-14">
      <h2 className="text-2xl font-bold md:text-4xl md:leading-tight dark:text-white">
        Pricing
      </h2>
      <p className="mt-1 text-gray-600 dark:text-neutral-400">
        Whatever your status, our offers evolve according to your needs.
      </p>
    </div>
    {/* End Title */}
    {/* Switch */}

    {/* End Switch */}
    {/* Grid */}
    <div className="mt-12 grid sm:grid-cols-2 lg:grid-cols-4 gap-6 lg:items-center">
      {/* Card */}
      <div className="flex flex-col border border-gray-200 text-center rounded-xl p-8 dark:border-neutral-800">
        <h4 className="font-medium text-lg text-gray-800 dark:text-neutral-200">
          Free
        </h4>
        <span className="mt-7 font-bold text-5xl text-gray-800 dark:text-neutral-200">
          Free
        </span>
        <p className="mt-2 text-sm text-gray-500 dark:text-neutral-500">
          Forever free
        </p>
        <ul className="mt-7 space-y-2.5 text-sm">
          <li className="flex space-x-2">
            <svg
              className="flex-shrink-0 mt-0.5 size-4 text-blue-600 dark:text-blue-500"
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <polyline points="20 6 9 17 4 12" />
            </svg>
            <span className="text-gray-800 dark:text-neutral-400">1 user</span>
          </li>
          <li className="flex space-x-2">
            <svg
              className="flex-shrink-0 mt-0.5 size-4 text-blue-600 dark:text-blue-500"
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <polyline points="20 6 9 17 4 12" />
            </svg>
            <span className="text-gray-800 dark:text-neutral-400">
              Plan features
            </span>
          </li>
          <li className="flex space-x-2">
            <svg
              className="flex-shrink-0 mt-0.5 size-4 text-blue-600 dark:text-blue-500"
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <polyline points="20 6 9 17 4 12" />
            </svg>
            <span className="text-gray-800 dark:text-neutral-400">
              Product support
            </span>
          </li>
        </ul>
        <a
          className="mt-5 py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-gray-200 text-gray-500 hover:border-blue-600 hover:text-blue-600 disabled:opacity-50 disabled:pointer-events-none dark:border-neutral-700 dark:text-neutral-400 dark:hover:text-blue-500 dark:hover:border-blue-600"
          href="#"
        >
          Sign up
        </a>
      </div>
      {/* End Card */}
      {/* Card */}
      <div className="flex flex-col border-2 border-blue-600 text-center shadow-xl rounded-xl p-8 dark:border-blue-700">
        <p className="mb-3">
          <span className="inline-flex items-center gap-1.5 py-1.5 px-3 rounded-lg text-xs uppercase font-semibold bg-blue-100 text-blue-800 dark:bg-blue-600 dark:text-white">
            Most popular
          </span>
        </p>
        <h4 className="font-medium text-lg text-gray-800 dark:text-neutral-200">
          Startup
        </h4>
        <span className="mt-5 font-bold text-5xl text-gray-800 dark:text-neutral-200">
          <span className="font-bold text-2xl -me-2"></span>
          $39
        </span>
        <p className="mt-2 text-sm text-gray-500 dark:text-neutral-500">
          All the basics for sdeploying new app
        </p>
        <ul className="mt-7 space-y-2.5 text-sm">
          <li className="flex space-x-2">
            <svg
              className="flex-shrink-0 mt-0.5 size-4 text-blue-600 dark:text-blue-500"
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <polyline points="20 6 9 17 4 12" />
            </svg>
            <span className="text-gray-800 dark:text-neutral-400">2 users</span>
          </li>
          <li className="flex space-x-2">
            <svg
              className="flex-shrink-0 mt-0.5 size-4 text-blue-600 dark:text-blue-500"
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <polyline points="20 6 9 17 4 12" />
            </svg>
            <span className="text-gray-800 dark:text-neutral-400">
              Plan features
            </span>
          </li>
          <li className="flex space-x-2">
            <svg
              className="flex-shrink-0 mt-0.5 size-4 text-blue-600 dark:text-blue-500"
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <polyline points="20 6 9 17 4 12" />
            </svg>
            <span className="text-gray-800 dark:text-neutral-400">
              Product support
            </span>
          </li>
        </ul>
        <a
          className="mt-5 py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
          href="#"
        >
          Sign up
        </a>
      </div>
      {/* End Card */}
      {/* Card */}
      <div className="flex flex-col border border-gray-200 text-center rounded-xl p-8 dark:border-neutral-800">
        <h4 className="font-medium text-lg text-gray-800 dark:text-neutral-200">
          Team
        </h4>
        <span className="mt-5 font-bold text-5xl text-gray-800 dark:text-neutral-200">
          <span className="font-bold text-2xl -me-2"></span>
          $89
        </span>
        <p className="mt-2 text-sm text-gray-500 dark:text-neutral-500">
          Everything needed for a XR app
        </p>
        <ul className="mt-7 space-y-2.5 text-sm">
          <li className="flex space-x-2">
            <svg
              className="flex-shrink-0 mt-0.5 size-4 text-blue-600 dark:text-blue-500"
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <polyline points="20 6 9 17 4 12" />
            </svg>
            <span className="text-gray-800 dark:text-neutral-400">5 users</span>
          </li>
          <li className="flex space-x-2">
            <svg
              className="flex-shrink-0 mt-0.5 size-4 text-blue-600 dark:text-blue-500"
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <polyline points="20 6 9 17 4 12" />
            </svg>
            <span className="text-gray-800 dark:text-neutral-400">
              Plan features
            </span>
          </li>
          <li className="flex space-x-2">
            <svg
              className="flex-shrink-0 mt-0.5 size-4 text-blue-600 dark:text-blue-500"
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <polyline points="20 6 9 17 4 12" />
            </svg>
            <span className="text-gray-800 dark:text-neutral-400">
              Product support
            </span>
          </li>
        </ul>
        <a
          className="mt-5 py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-gray-200 text-gray-500 hover:border-blue-600 hover:text-blue-600 disabled:opacity-50 disabled:pointer-events-none dark:border-neutral-700 dark:text-neutral-400 dark:hover:text-blue-500 dark:hover:border-blue-600"
          href="#"
        >
          Sign up
        </a>
      </div>
      {/* End Card */}
      {/* Card */}
      <div className="flex flex-col border border-gray-200 text-center rounded-xl p-8 dark:border-neutral-800">
        <h4 className="font-medium text-lg text-gray-800 dark:text-neutral-200">
          Enterprise
        </h4>
        <span className="mt-5 font-bold text-5xl text-gray-800 dark:text-neutral-200">
          <span className="font-bold text-2xl -me-2"></span>
          $149
        </span>
        <p className="mt-2 text-sm text-gray-500 dark:text-neutral-500">
          Advanced features for scaling your XR app
        </p>
        <ul className="mt-7 space-y-2.5 text-sm">
          <li className="flex space-x-2">
            <svg
              className="flex-shrink-0 mt-0.5 size-4 text-blue-600 dark:text-blue-500"
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <polyline points="20 6 9 17 4 12" />
            </svg>
            <span className="text-gray-800 dark:text-neutral-400">
              10 users
            </span>
          </li>
          <li className="flex space-x-2">
            <svg
              className="flex-shrink-0 mt-0.5 size-4 text-blue-600 dark:text-blue-500"
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <polyline points="20 6 9 17 4 12" />
            </svg>
            <span className="text-gray-800 dark:text-neutral-400">
              Plan features
            </span>
          </li>
          <li className="flex space-x-2">
            <svg
              className="flex-shrink-0 mt-0.5 size-4 text-blue-600 dark:text-blue-500"
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <polyline points="20 6 9 17 4 12" />
            </svg>
            <span className="text-gray-800 dark:text-neutral-400">
              Product support
            </span>
          </li>
        </ul>
        <a
          className="mt-5 py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-gray-200 text-gray-500 hover:border-blue-600 hover:text-blue-600 disabled:opacity-50 disabled:pointer-events-none dark:border-neutral-700 dark:text-neutral-400 dark:hover:text-blue-500 dark:hover:border-blue-600"
          href="#"
        >
          Sign up
        </a>
      </div>
      {/* End Card */}
    </div>
    {/* End Grid */}
   
    {/* End Comparison table */}
  </div>
  {/* End Pricing */}
</>
</div>
        </div>
        <Footer />
      </div>
    );
}

export default Cyberhome;
