import React, { useState, useEffect } from 'react';
import { Mail, Send, Users, BarChart2, Zap, Cloud, CheckCircle,Rocket, Sparkles,BarChart, MessageSquare, Smile, Frown, User, X, Edit, ChevronDown, Search, Plus, Calendar } from 'lucide-react';
import { Route, Routes, NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useSelector } from 'react-redux';



const GuidePopup = ({ onClose }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const steps = [
    { icon: Mail, text: "Add an email account to send emails from" },
    { icon: Users, text: "Connect or add prospects to your campaign" },
    { icon: Rocket, text: "Create a campaign to start sending emails" }
  ];

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-8 rounded-lg max-w-md w-full transform transition-all duration-300 ease-in-out hover:scale-105">
        <h2 className="text-3xl font-bold mb-6 text-center text-blue-600">
          Welcome to Your Email Dashboard!
          <Sparkles className="inline-block ml-2 text-yellow-400" />
        </h2>
        <p className="mb-6 text-center text-lg text-gray-700">Follow these magical steps to get started:</p>
        <div className="space-y-4 mb-8">
          {steps.map((step, index) => (
            <div
              key={index}
              className={`flex items-center p-4 rounded-lg transition-all duration-300 ${
                index === currentStep ? 'bg-blue-100 scale-105' : 'bg-gray-50'
              }`}
              onMouseEnter={() => setCurrentStep(index)}
            >
              <step.icon className={`mr-4 ${index === currentStep ? 'text-blue-500' : 'text-gray-400'}`} />
              <span className={`${index === currentStep ? 'text-blue-700 font-semibold' : 'text-gray-600'}`}>
                {step.text}
              </span>
            </div>
          ))}
        </div>
        <button
          onClick={onClose}
          className="w-full bg-blue-500 text-white px-6 py-3 rounded-full font-bold hover:bg-blue-600 transform transition-all duration-300 hover:scale-105 hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50"
        >
          Got it, let's go!
        </button>
      </div>
    </div>
  );
};

const EmailAccounts = () => {
  const [showConfigureDomainPopup, setShowConfigureDomainPopup] = useState(false);
  const [view, setView] = useState('main');
  const [selectedService, setSelectedService] = useState({ name: 'Gmail', host: 'smtp.gmail.com' });
  const [customHost, setCustomHost] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [domainInfo, setDomainInfo] = useState([]);
  const [verificationStatus, setVerificationStatus] = useState(false);
  const { userInfo } = useSelector((state) => state.auth);

  const popularServices = [
    { name: 'Gmail', host: 'smtp.gmail.com' },
    { name: 'Outlook', host: 'smtp.office365.com' },
    { name: 'Yahoo', host: 'smtp.mail.yahoo.com' },
    { name: 'Custom', host: '' },
  ];

  useEffect(() => {
    fetchDomainInfo();
    checkDomainVerification();
  }, []);

  const checkDomainVerification = async () => {
    try {
      const response = await axios.post('https://web-production-5ee8.up.railway.app/echidna/verifycheck', {
        userId: userInfo._id,
      });
      setVerificationStatus(response.data.verified);
      
      // Immediately update domain info statuses when verification status changes
      setDomainInfo(prevInfo => prevInfo.map(domain => ({
        ...domain,
        status: response.data.verified ? 'Active' : 'Pending'
      })));
    } catch (error) {
      console.error('Error checking domain verification:', error);
      setError('Failed to check domain verification status.');
    }
  };

  const fetchDomainInfo = async () => {
    try {
      const response = await axios.post('https://web-production-5ee8.up.railway.app/auth/domaininfo', {
        userId: userInfo._id,
      });
      const domains = Array.isArray(response.data) ? response.data : [response.data];
      
      // Set domains with status based on current verification status
      const updatedDomains = domains.map(domain => ({
        ...domain,
        status: verificationStatus ? 'Active' : 'Pending'
      }));
      
      setDomainInfo(updatedDomains);
    } catch (error) {
      console.error('Error fetching domain info:', error);
      setError('Failed to fetch domain information.');
    }
  };
  const handleAddDomain = async () => {
    try {
      await axios.post('https://web-production-5ee8.up.railway.app/auth/configuredomain', {
        email,
        password,
        host: selectedService.name === 'Custom' ? customHost : selectedService.host,
        userId: userInfo._id,
      });
      setView('main');
      setError('');
      setShowConfigureDomainPopup(false);
      fetchDomainInfo();
    } catch (err) {
      setError('Failed to add domain. Please check your inputs and try again.');
    }
  };

  const handleVerifyDomain = async (domain) => {
    try {
      await axios.post('https://web-production-5ee8.up.railway.app/echidna/verifydomain', {
        userId: userInfo._id,
      });
      await checkDomainVerification();
      fetchDomainInfo();
    } catch (err) {
      setError('Failed to verify domain. Please try again.');
    }
  };

  return (
 
      <div className="bg-white rounded-lg shadow-lg p-8">
        <h2 className="text-2xl font-semibold mb-4 text-center">Manage Your Email Domains</h2>
        <p className="text-gray-600 mb-6 text-center">
          Configure your sending domains to start campaigns and track performance.
        </p>
        
        {/* Verification Status Banner */}
        <div className={`mb-4 p-4 rounded-lg ${verificationStatus ? 'bg-green-100' : 'bg-yellow-100'}`}>
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              {verificationStatus ? (
                <CheckCircle className="w-5 h-5 text-green-500 mr-2" />
              ) : (
                <Cloud className="w-5 h-5 text-yellow-500 mr-2" />
              )}
              <span className={verificationStatus ? 'text-green-700' : 'text-yellow-700'}>
                {verificationStatus ? 'Domain Verified' : 'Domain Verification Pending'}
              </span>
            </div>
            {!verificationStatus && (
              <button
                onClick={checkDomainVerification}
                className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-300 ease-in-out text-sm"
              >
                Check Status
              </button>
            )}
          </div>
        </div>
    
        {/* Add Domain Button */}
        <div className="flex justify-center mb-8">
          <button 
            onClick={() => setShowConfigureDomainPopup(true)}
            className="px-6 py-3 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-300 ease-in-out flex items-center"
          >
            <Plus className="w-5 h-5 mr-2" />
            Configure Domain Settings
          </button>
        </div>
    
        {/* Domain Info Table */}
        <div className="overflow-x-auto">
          <table className="w-full bg-white border border-gray-300">
            <thead>
              <tr className="bg-gray-100">
                <th className="py-2 px-4 border-b font-medium text-left">Email</th>
                <th className="py-2 px-4 border-b font-medium text-left">Domain</th>
                <th className="py-2 px-4 border-b font-medium text-left">Status</th>
                <th className="py-2 px-4 border-b font-medium"></th> {/* Empty header for actions column */}
              </tr>
            </thead>
            <tbody>
              {domainInfo.map((domain, index) => (
                <tr key={index} className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
                  <td className="py-2 px-4 border-b">{domain.email}</td>
                  <td className="py-2 px-4 border-b">{domain.domain}</td>
                  <td className="py-2 px-4 border-b">
                    <span className={`px-2 py-1 rounded ${verificationStatus ? 'bg-green-200 text-green-800' : 'bg-yellow-200 text-yellow-800'}`}>
                      {verificationStatus ? 'Active' : 'Pending'}
                    </span>
                  </td>
                  <td className="py-2 px-4 border-b text-right">
                    <button
                      onClick={() => handleVerifyDomain(domain)}
                      className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-300 ease-in-out"
                    >
                      {verificationStatus ? 'Reverify Domain' : 'Verify Domain'}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
    
        {/* Error Message */}
        {error && (
          <p className="text-red-500 text-sm mt-4 text-center">{error}</p>
        )}
  
      {/* Configure Domain Popup */}
      {showConfigureDomainPopup && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" onClick={() => setShowConfigureDomainPopup(false)}>
          <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white" onClick={e => e.stopPropagation()}>
            <div className="mt-3">
              <h3 className="text-lg leading-6 font-medium text-gray-900 mb-2">Configure Sending Domain Settings</h3>
              
              {view === 'main' && (
                <>
                  <p className="text-sm text-gray-500 mb-4">
                    Please choose an option to configure your sending domain settings.
                  </p>
                  <div className="space-y-2">
                    <button
                      onClick={() => setView('addDomain')}
                      className="w-full px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-300"
                    >
                      Add New Domain
                    </button>
                  </div>
                </>
              )}
    
              {view === 'addDomain' && (
                <>
                  <p className="text-sm text-gray-500 mb-4">
                    Add a new sending domain by selecting a service and providing your credentials.
                  </p>
                  <div className="space-y-4">
                    <select
                      value={JSON.stringify(selectedService)}
                      onChange={(e) => setSelectedService(JSON.parse(e.target.value))}
                      className="w-full p-2 border rounded-md bg-white" 
                    >
                      {popularServices.map((service) => (
                        <option key={service.name} value={JSON.stringify(service)}>
                          {service.name}
                        </option>
                      ))}
                    </select>
                    {selectedService.name === 'Custom' && (
                      <input
                        type="text"
                        placeholder="Custom SMTP Host"
                        value={customHost}
                        onChange={(e) => setCustomHost(e.target.value)}
                        className="w-full p-2 border rounded-md bg-white"
                      />
                    )}
                    <input
                      type="email"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="w-full p-2 border rounded-md bg-white"
                    />
                    <input
                      type="password"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="w-full p-2 border rounded-md bg-white"
                    />
                    <button
                      onClick={handleAddDomain}
                      className="w-full px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-300"
                    >
                      Add Domain
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};


const ManageProspects = () => (
  <div className="text-center text-gray-800">
    <h2 className="text-2xl font-bold">Manage Prospects</h2>
    <p>Here you can manage your prospects.</p>
  </div>
);

const SidebarButton = ({ icon: Icon, text, to, isActive }) => {
  const navigate = useNavigate();
  return (
    <div 
      className={`flex items-center space-x-3 px-4 py-3 rounded-lg cursor-pointer transition-colors duration-200 ${
        isActive ? 'bg-blue-100 text-blue-600' : 'text-gray-600 hover:bg-gray-100'
      }`}
      onClick={() => navigate(to)}
    >
      <Icon className="w-5 h-5" />
      <span className="text-sm font-medium">{text}</span>
    </div>
  );
};

const EmailDashboard = () => {
  const [showGuide, setShowGuide] = useState(true);
  const { userInfo } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (!userInfo) {
      navigate('/login');
    }
  }, [userInfo, navigate]);

  return (
    <div className="flex h-screen bg-gray-100">
      {showGuide && <GuidePopup onClose={() => setShowGuide(false)} />}
      
      {/* Sidebar */}
      <aside className="w-64 bg-white shadow-md">
        <div className="p-6">
          <div className="flex items-center space-x-3 mb-8">
            <div className="w-10 h-10 bg-blue-500 rounded-full flex items-center justify-center">
              <Mail className="w-6 h-6 text-white" />
            </div>
            <span className="text-xl font-bold text-gray-800">Echidna mail</span>
          </div>
          <nav className="space-y-1">
            <SidebarButton icon={Send} text="Campaigns" to="/echidnamail/campaigns"  />
            <SidebarButton icon={Users} text="Contacts" to="/echidnamail/prospects" />
            <SidebarButton icon={BarChart2} text="Analytics" to="/echidnamail/analytics" />
            <SidebarButton icon={Zap} text="Automations" to="/echidnamail/automations" />
          
            <SidebarButton icon={MessageSquare} text="Templates" to="/echidnamail/templates" />
      
          </nav>
        </div>
      </aside>

      {/* Main content */}
      <main className="flex-1 p-8 overflow-auto">
        <header className="flex justify-between items-center mb-8">
          <h1 className="text-2xl font-bold text-gray-800">Email Dashboard</h1>
          <button className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 transition duration-300 ease-in-out">
            My Organization ▼
          </button>
        </header>

        {/* Routes */}
        <Routes>
          <Route path="/echidnamail/campaigns" element={
            <div className="text-center text-gray-800">
              <h2 className="text-2xl font-bold">Send Emails</h2>
              <p>Here you can send emails.</p>
            </div>
          } />
          <Route path="/echidnamail/prospects" element={<ManageProspects />} />
          <Route path="/echidnamail/analytics" element={
            <div className="text-center text-gray-800">
              <h2 className="text-2xl font-bold">Analytics</h2>
              <p>View your email analytics here.</p>
            </div>
          } />
          <Route path="/echidnamail/automations" element={
            <div className="text-center text-gray-800">
              <h2 className="text-2xl font-bold">Automations</h2>
              <p>Manage your automations here.</p>
            </div>
          } />
          <Route path="/integrations" element={
            <div className="text-center text-gray-800">
              <h2 className="text-2xl font-bold">Integrations</h2>
              <p>Manage your integrations here.</p>
            </div>
          } />
          <Route path="/echidnamail/templates" element={
            <div className="text-center text-gray-800">
              <h2 className="text-2xl font-bold">Templates</h2>
              <p>Manage your templates here.</p>
            </div>
          } />
          <Route path="/account" element={
            <div className="text-center text-gray-800">
              <h2 className="text-2xl font-bold">Account Settings</h2>
              <p>Manage your account settings here.</p>
            </div>
          } />
          <Route path="/" element={<EmailAccounts />} />
        </Routes>
      </main>
    </div>
  );
};

export default EmailDashboard;