import React, { useState } from 'react';
import { Mail, Send, Users, BarChart, Zap, Cloud, MessageSquare, User, Plus, Settings, Trash2 } from 'lucide-react';

const SidebarButton = ({ icon: Icon, text, to, active }) => (
  <button className={`p-3 rounded-xl ${active ? 'bg-indigo-100 text-indigo-600' : 'text-gray-400 hover:bg-gray-100'}`}>
    <Icon className="w-6 h-6" />
  </button>
);

const AIAgentCard = ({ name, role, performance }) => (
  <div className="bg-white p-6 rounded-lg shadow">
    <div className="flex items-center justify-between mb-4">
      <h3 className="text-lg font-semibold text-gray-900">{name}</h3>
      <span className={`px-2 py-1 text-xs font-semibold rounded-full ${
        performance === 'High' ? 'bg-green-100 text-green-800' :
        performance === 'Medium' ? 'bg-yellow-100 text-yellow-800' :
        'bg-red-100 text-red-800'
      }`}>
        {performance} Performance
      </span>
    </div>
    <p className="text-sm text-gray-600 mb-4">{role}</p>
    <div className="flex justify-end space-x-2">
      <button className="p-2 text-blue-600 hover:bg-blue-100 rounded">
        <Settings className="w-5 h-5" />
      </button>
      <button className="p-2 text-red-600 hover:bg-red-100 rounded">
        <Trash2 className="w-5 h-5" />
      </button>
    </div>
  </div>
);

const NewAgentForm = ({ onClose }) => (
  <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="my-modal">
    <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
      <div className="mt-3 text-center">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Create New AI Agent</h3>
        <form className="mt-2 text-left">
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
              Name
            </label>
            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="name" type="text" placeholder="Agent Name" />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="role">
              Role
            </label>
            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="role" type="text" placeholder="Agent Role" />
          </div>
          <div className="flex items-center justify-between">
            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">
              Create Agent
            </button>
            <button className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button" onClick={onClose}>
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
);

const Automations = () => {
  const [showNewAgentForm, setShowNewAgentForm] = useState(false);
  const [aiAgents, setAiAgents] = useState([
    { id: 1, name: 'Alex', role: 'Lead Generation Specialist', performance: 'High' },
    { id: 2, name: 'Sam', role: 'Account Executive', performance: 'Medium' },
    { id: 3, name: 'Jordan', role: 'Customer Success Manager', performance: 'High' },
  ]);

  return (
    <div className="flex h-screen bg-gray-100 relative">
      {/* Coming Soon Overlay */}
      <div className="absolute inset-0 backdrop-blur-sm bg-white/20 z-50 flex items-center justify-center">
        <div className="text-center bg-white/80 p-8 rounded-xl shadow-lg">
          <h2 className="text-4xl font-bold text-gray-900 mb-4">Coming Soon</h2>
          <p className="text-xl text-gray-600">We're working hard to bring you amazing automation features!</p>
        </div>
      </div>

      {/* Sidebar */}
      <aside className="w-20 bg-white shadow-md">
        <div className="flex flex-col items-center py-6 space-y-8">
          <div className="p-3 bg-indigo-600 rounded-xl">
            <Mail className="w-8 h-8 text-white" />
          </div>
          <SidebarButton icon={Send} text="Send Emails" to="/send-emails" />
          <SidebarButton icon={Users} text="Manage Prospects" to="/prospects" />
          <SidebarButton icon={BarChart} text="Analytics" to="/analytics" />
          <SidebarButton icon={Zap} text="Automations" to="/automations" active />
          <SidebarButton icon={Cloud} text="Integrations" to="/integrations" />
          <SidebarButton icon={MessageSquare} text="Templates" to="/templates" />
          <SidebarButton icon={User} text="Account" to="/account" />
        </div>
      </aside>
      
      {/* Main content */}
      <main className="flex-1 p-8 overflow-auto">
        <div className="max-w-7xl mx-auto">
          <header className="flex justify-between items-center mb-8">
            <h1 className="text-3xl font-bold text-gray-900">AI Sales Team Automations</h1>
            <button 
              onClick={() => setShowNewAgentForm(true)}
              className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 transition duration-300 ease-in-out flex items-center"
            >
              <Plus className="w-5 h-5 mr-2" />
              New AI Agent
            </button>
          </header>
          
          {/* AI Agents Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
            {aiAgents.map(agent => (
              <AIAgentCard key={agent.id} {...agent} />
            ))}
          </div>
          
          {/* Automation Workflows */}
          <div className="bg-white p-6 rounded-lg shadow mb-8">
            <h2 className="text-xl font-semibold text-gray-900 mb-4">Automation Workflows</h2>
            <p className="text-gray-600">Configure your AI sales team's automated workflows here.</p>
          </div>
        </div>
      </main>

      {showNewAgentForm && <NewAgentForm onClose={() => setShowNewAgentForm(false)} />}
    </div>
  );
};

export default Automations;