import { useState, useEffect } from 'react';
import './cyberupload.css';
import { useSelector } from 'react-redux';
import Navbar from '../../components/navbar/Navbar';
import axios from 'axios';

const Cyberlakeupload = () => {
  const [file, setFile] = useState(null);
  const [isPublic, setIsPublic] = useState(false);
  const { userInfo } = useSelector((state) => state.auth);
  const [objectName, setObjectName] = useState('');
  const [passcode, setPasscode] = useState(null);
  const [fileType, setFileType] = useState('GLB');
  const [serverLocation, setServerLocation] = useState('Europe');
  const [isChecked, setIsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [muff, setMuff] = useState(0);
  const [hasCyberlakeAccess, setHasCyberlakeAccess] = useState(false);

  useEffect(() => {
    checkCyberlakeAccess();
  }, []);

  const checkCyberlakeAccess = async () => {
    try {
      const response = await axios.post('https://web-production-5ee8.up.railway.app/auth/cyberlake');
      setHasCyberlakeAccess(response.status === 200);
    } catch (error) {
      console.error('Error checking Cyberlake access:', error);
      setHasCyberlakeAccess(false);
    }
  };

  const handleChange = () => {
    setIsChecked(!isChecked);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFile(file);
    const fileSizeInGB = (file.size / (1024 * 1024 * 1024));
    // You can use fileSizeInGB here if needed
  };

  const handleUpload = async () => {
    if (!hasCyberlakeAccess) {
      alert('You don\'t have access to Cyberlake.');
      return;
    }

    if (!file) {
      alert('Please select a file to upload.');
      return;
    }
    if (!passcode) {
      alert('Please enter a valid passcode.');
      return;
    }
    setIsLoading(true);
    const userId = userInfo._id;
    const formData = new FormData();
    formData.append('file', file);
    const fileSizeInBytes = file.size;
    const fileSizeInGB = fileSizeInBytes / (1024 * 1024 * 1024);
    const muff = (fileSizeInGB * 321 / 3.5).toFixed(4);
    setMuff(muff);
    formData.append('secretcode', muff);
    formData.append('objectName', objectName);
    formData.append('auth', isChecked);
    formData.append('passcode', passcode);
    formData.append('fileType', fileType);
    formData.append('serverLocation', serverLocation);
    formData.append('userId', userId);
    formData.append('isPublic', isPublic);
    formData.append('fileSizeInGB', fileSizeInGB);
  
    try {
      const tldResponse = await fetch('https://web-production-5ee8.up.railway.app/cyberlake/tld', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ secretcode: muff, userId }),
      });
  
      if (tldResponse.ok) {
        const response = await fetch('https://web-production-5ee8.up.railway.app/cyberlake/upload', {
          method: 'POST',
          body: formData,
        });
  
        if (response.ok) {
          alert('File uploaded successfully');
        } else {
          alert('Failed to upload file');
        }
      } else {
        alert('Failed to validate TLD');
      }
  
      setIsLoading(false);
    } catch (error) {
      console.error('Error uploading file:', error);
      setIsLoading(false);
    }
  };

  const handleToggle = () => {
    setIsPublic(!isPublic);
  };

  return (
    <div className="w-full min-h-screen bg-black" >
      <Navbar />
      <div className="bg-black-900 h-full w-full flex justify-center items-center flex-col gap-10 relative pt-20">
        <div className="absolute top-4 right-4">
          <label className="relative inline-flex items-center cursor-pointer">
            <input
              className="sr-only peer"
              value={isChecked ? 'true' : 'false'}
              type="checkbox"
              onChange={handleChange}
            />
            <div className="group peer ring-0 bg-gray-50 border-2 border-gray-900 rounded-full outline-none duration-700 after:duration-200 w-24 h-12 shadow-md peer-checked:bg-gradient-to-r peer-focus:outline-none after:content-[''] after:rounded-full after:absolute after:bg-gray-900 after:outline-none after:h-10 after:w-10 after:top-1 after:left-1 peer-checked:after:translate-x-12 peer-hover:after:scale-95">
              <svg
                y="0"
                xmlns="http://www.w3.org/2000/svg"
                x="0"
                width="100"
                viewBox="0 0 100 100"
                preserveAspectRatio="xMidYMid meet"
                height="100"
                className="absolute top-1 left-12 fill-green-600 w-10 h-10"
              >
                <path
                  d="M50,18A19.9,19.9,0,0,0,30,38v8a8,8,0,0,0-8,8V74a8,8,0,0,0,8,8H70a8,8,0,0,0,8-8V54a8,8,0,0,0-8-8H38V38a12,12,0,0,1,23.6-3,4,4,0,1,0,7.8-2A20.1,20.1,0,0,0,50,18Z"
                  className="svg-fill-primary"
                ></path>
              </svg>

              <svg
                y="0"
                xmlns="http://www.w3.org/2000/svg"
                x="0"
                width="100"
                viewBox="0 0 100 100"
                preserveAspectRatio="xMidYMid meet"
                height="100"
                className="absolute top-1 left-1 fill-red-600 w-10 h-10"
              >
                <path
                  fillRule="evenodd"
                  d="M30,46V38a20,20,0,0,1,40,0v8a8,8,0,0,1,8,8V74a8,8,0,0,1-8,8H30a8,8,0,0,1-8-8V54A8,8,0,0,1,30,46Zm32-8v8H38V38a12,12,0,0,1,24,0Z"
                ></path>
              </svg>
            </div>
          </label>
        </div>

        <label className="input-div mb-10 flex flex-col items-center cursor-pointer">
  <input
    className="input bg-purple-700 text-purple-200 border-purple-500 focus:ring-purple-500 focus:border-purple-500 hidden"
    name="file"
    type="file"
    onChange={handleFileChange}
  />
  <div className="relative group rounded-lg w-72 bg-purple-800 overflow-hidden before:absolute before:w-12 before:h-12 before:content-[''] before:right-0 before:bg-purple-500 before:rounded-full before:blur-lg before:[box-shadow:-60px_20px_10px_10px_#C084FC]">
    <svg
      y="0"
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      width="100"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid meet"
      height="100"
      className="w-8 h-8 absolute right-0 -rotate-45 stroke-purple-300 top-1.5 group-hover:rotate-0 duration-300"
    >
      <path
        strokeWidth="4"
        strokeLinejoin="round"
        strokeLinecap="round"
        fill="none"
        d="M60.7,53.6,50,64.3m0,0L39.3,53.6M50,64.3V35.7m0,46.4A32.1,32.1,0,1,1,82.1,50,32.1,32.1,0,0,1,50,82.1Z"
        className="svg-stroke-primary"
      ></path>
    </svg>
  </div>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    strokeLinejoin="round"
    strokeLinecap="round"
    viewBox="0 0 24 24"
    strokeWidth="2"
    fill="none"
    stroke="currentColor"
    className="icon stroke-purple-400 hover:stroke-purple-300"
  >
    <polyline points="16 16 12 12 8 16"></polyline>
    <line y2="21" x2="12" y1="12" x1="12"></line>
    <path d="M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3"></path>
    <polyline points="16 16 12 12 8 16"></polyline>
  </svg>
</label>

        <input
          placeholder="Enter a secret passcode"
          className="bg-[#292929] border-2 border-[#3e3e3e] rounded-lg text-white px-6 py-3 w-72 hover:border-[#fff] cursor-pointer transition"
          type="text"
          value={passcode}
          onChange={(e) => setPasscode(e.target.value)}
        />

        <div className="relative group rounded-lg w-72 bg-purple-800 overflow-hidden before:absolute before:w-12 before:h-12 before:content[''] before:right-0 before:bg-purple-500 before:rounded-full before:blur-lg before:[box-shadow:-60px_20px_10px_10px_#C084FC]">
          <svg
            y="0"
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            width="100"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid meet"
            height="100"
            className="w-8 h-8 absolute right-0 -rotate-45 stroke-purple-300 top-1.5 group-hover:rotate-0 duration-300"
          >
            <path
              strokeWidth="4"
              strokeLinejoin="round"
              strokeLinecap="round"
              fill="none"
              d="M60.7,53.6,50,64.3m0,0L39.3,53.6M50,64.3V35.7m0,46.4A32.1,32.1,0,1,1,82.1,50,32.1,32.1,0,0,1,50,82.1Z"
              className="svg-stroke-primary"
            ></path>
          </svg>
          <select
            className="appearance-none hover:placeholder-shown:bg-purple-600 relative text-purple-300 bg-transparent ring-0 outline-none border border-purple-600 text-purple-100 placeholder-purple-400 text-sm font-bold rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5"
            value={fileType}
            onChange={(e) => setFileType(e.target.value)}
          >
            <option className="bg-purple-800">glb</option>
            <option className="bg-purple-800">gltf</option>
            <option className="bg-purple-800">obj</option>
            <option className="bg-purple-800">fbx</option>
            <option className="bg-purple-800">csv</option>
          </select>
        </div>

        <div className="relative group rounded-lg w-72 bg-purple-800 overflow-hidden before:absolute before:w -12 before:h-12 before:content[''] before:right-0 before:bg-purple-500 before:rounded-full before:blur-lg before:[box-shadow:-60px_20px_10px_10px_#C084FC]">
          <svg
            y="0"
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            width="100"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid meet"
            height="100"
            className="w-8 h-8 absolute right-0 -rotate-45 stroke-purple-300 top-1.5 group-hover:rotate-0 duration-300"
          >
            <path
              strokeWidth="4"
              strokeLinejoin="round"
              strokeLinecap="round"
              fill="none"
              d="M60.7,53.6,50,64.3m0,0L39.3,53.6M50,64.3V35.7m0,46.4A32.1,32.1,0,1,1,82.1,50,32.1,32.1,0,0,1,50,82.1Z"
              className="svg-stroke-primary"
            ></path>
          </svg>
        </div>

        {file && <p className="text-purple-400 mt-2">{file.name}</p>}
        <div>
          <button
            onClick={handleUpload}
            className={`svg-wrapper-1 ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
            disabled={isLoading}
          >
            <div className="svg-wrapper">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="30"
                height="30"
                className="icon"
              >
                <path
                  d="M22,15.04C22,17.23 20.24,19 18.07,19H5.93C3.76,19 2,17.23 2,15.04C2,13.07 3.43,11.44 5.31,11.14C5.28,11 5.27,10.86 5.27,10.71C5.27,9.33 6.38,8.2 7.76,8.2C8.37,8.2 8.94,8.43 9.37,8.8C10.14,7.05 11.13,5.44 13.91,5.44C17.28,5.44 18.87,8.06 18.87,10.83C18.87,10.94 18.87,11.06 18.86,11.17C20.65,11.54 22,13.13 22,15.04Z"
                ></path>
              </svg>
            </div>
            <span>Save</span>
          </button>
        </div>
        {isLoading && (
          <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center">
            <div className="bg-black p-8 rounded-lg">
              <div className="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <p className="text-grey-800 mt-4">Uploading file...</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Cyberlakeupload;