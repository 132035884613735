import React, { useState } from 'react';
import axios from 'axios';
import { Upload, Book, FileText, GraduationCap, Sparkles, Trophy } from 'lucide-react';
import * as pdfjsLib from 'pdfjs-dist/build/pdf'; // Correct import for PDF.js
import 'pdfjs-dist/build/pdf.worker.entry';
import logo from '../../assets/logo.png';
const StudyApp = () => {
  const [files, setFiles] = useState([]);
  const [text, setText] = useState('');
  const [questions, setQuestions] = useState([]);
  const [importantNotes, setImportantNotes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [level, setLevel] = useState(1);
  const [xp, setXp] = useState(0);

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files || []);
    const validFiles = selectedFiles.filter(file => {
      const isValidSize = file.size <= 10 * 1024 * 1024; // 10MB limit
      const isValidType = file.type === 'text/plain' || file.type === 'application/pdf' || file.name.endsWith('.txt') || file.name.endsWith('.pdf');
      return isValidSize && isValidType;
    });

    if (files.length + validFiles.length > 3) {
      setError('You can only upload a maximum of 3 files.');
      return;
    }

    if (validFiles.length < selectedFiles.length) {
      setError('Some files were skipped. Only text files (*.txt) and PDF files (*.pdf) up to 10MB are allowed.');
    }

    setFiles(prevFiles => [...prevFiles, ...validFiles]);
    setError('');
    addXp(10);
  };

  const handleTextChange = (event) => {
    setText(event.target.value);
  };

  const addXp = (amount) => {
    setXp(prevXp => {
      const newXp = prevXp + amount;
      if (newXp >= 100) {
        setLevel(prevLevel => prevLevel + 1);
        return newXp - 100;
      }
      return newXp;
    });
  };

  const readFileContent = async (file) => {
    if (file.type === 'application/pdf') {
      return await extractTextFromPdf(file);
    } else {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          resolve(event.target.result);
        };
        reader.onerror = (error) => reject(error);
        reader.readAsText(file);
      });
    }
  };

  const extractTextFromPdf = async (file) => {
    const arrayBuffer = await file.arrayBuffer();
    const pdf = await pdfjsLib.getDocument({ data: arrayBuffer }).promise;
    let text = '';
    for (let i = 1; i <= pdf.numPages; i++) {
      const page = await pdf.getPage(i);
      const content = await page.getTextContent();
      text += content.items.map(item => item.str).join(' ') + '\n';
    }
    return text;
  };
  
  const processContent = async () => {
    if (files.length === 0 && !text.trim()) {
      setError('Please upload study materials or enter text first.');
      return;
    }
  
    setLoading(true);
    setError('');
  
    try {
      let allContent = text.trim();
  
      // Read content from all uploaded files
      for (const file of files) {
        try {
          const fileContent = await readFileContent(file);
          allContent += '\n\n' + fileContent.trim();
        } catch (err) {
          console.error(`Error reading file ${file.name}:`, err);
          setError(`Error reading file ${file.name}. Please ensure it's a valid text or PDF file.`);
          setLoading(false);
          return;
        }
      }
  
      console.log('Sending request with content:', allContent.slice(0, 200) + '...'); // Log first 200 chars
      const response = await axios.post('https://web-production-5ee8.up.railway.app/echidna/edu', { text: allContent });
      console.log('Received response:', response.data);
  
      // Check if response.data is a string (the questions)
      if (typeof response.data === 'string') {
        // Split the string into an array of questions
        const questionArray = response.data.split('\n').filter(q => q.trim() !== '');
        setQuestions(questionArray);
        addXp(50);
      } else {
        throw new Error('Invalid response format');
      }
    } catch (err) {
      console.error('Error in processContent:', err);
      setError('Error processing your study materials. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const generateNotes = () => {
    if (questions.length === 0) {
      setError('Please generate questions first.');
      return;
    }

    const notes = [
      "Review the main concepts regularly",
      "Create flashcards for key terms",
      "Practice explaining the material to someone else",
      "Connect new information to things you already know"
    ];
    setImportantNotes(notes);
    addXp(30);
  };

  const removeFile = (index) => {
    setFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-100 to-purple-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto bg-white rounded-xl shadow-md overflow-hidden">
        <div className="p-8">
          <div className="flex items-center justify-between mb-6">
            <img src={logo} alt="logo" className="h-8 w-8" />
            <div className="flex items-center space-x-2">
              <GraduationCap className="text-blue-500" />
              <span className="text-lg font-semibold text-gray-700">Level {level}</span>
              <div className="w-32 bg-gray-200 rounded-full h-2.5">
                <div className="bg-blue-600 h-2.5 rounded-full" style={{ width: `${xp}%` }}></div>
              </div>
            </div>
          </div>

          <div className="space-y-6">
            <div className="bg-yellow-100 border-l-4 border-yellow-500 p-4 rounded-lg">
              <p className="text-yellow-700">Upload your study materials (books, notes, articles) or paste your text below. Let's turn them into engaging questions!</p>
            </div>

            <div className="flex items-center justify-center w-full">
              <label htmlFor="dropzone-file" className="flex flex-col items-center justify-center w-full h-64 border-2 border-blue-300 border-dashed rounded-lg cursor-pointer bg-blue-50 hover:bg-blue-100">
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                  <Upload className="w-10 h-10 mb-3 text-blue-500" />
                  <p className="mb-2 text-sm text-gray-500"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                  <p className="text-xs text-gray-500">PDF or TXT (MAX. 10MB)</p>
                </div>
                <input id="dropzone-file" type="file" className="hidden" onChange={handleFileChange} multiple accept=".pdf,.txt" />
              </label>
            </div>

            {files.length > 0 && (
              <div className="flex flex-wrap gap-2">
                {files.map((file, index) => (
                  <div key={index} className="flex items-center bg-blue-100 text-blue-800 text-sm font-medium px-2.5 py-0.5 rounded">
                    <Book className="w-4 h-4 mr-1" />
                    {file.name}
                    <button onClick={() => removeFile(index)} className="ml-2 text-blue-600 hover:text-blue-800">
                      &times;
                    </button>
                  </div>
                ))}
              </div>
            )}

            <textarea
              placeholder="Or paste your study text here..."
              value={text}
              onChange={handleTextChange}
              className="w-full h-32 p-2 border bg-white border-gray-300 rounded-md resize-none focus:outline-none focus:ring-2 focus:ring-blue-500"
            />

            <button
              onClick={processContent}
              disabled={loading}
              className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-4 rounded-lg disabled:opacity-50 disabled:cursor-not-allowed transition-colors duration-300 flex items-center justify-center"
            >
              {loading ? (
                <>
                  <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Processing...
                </>
              ) : (
                <>
                  <Sparkles className="mr-2" />
                  Generate Study Questions
                </>
              )}
            </button>

            {error && (
              <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 rounded" role="alert">
                <p>{error}</p>
              </div>
            )}

            {questions.length > 0 && (
              <div className="bg-white border border-gray-200 rounded-lg shadow-sm p-6">
                <h3 className="text-xl font-semibold mb-4 flex items-center">
                  <Trophy className="mr-2 text-yellow-500" />
                  Your Study Questions
                </h3>
                <ul className="space-y-2">
  {questions.map((question, index) => (
    <li key={index} className="flex items-start">
      <p>{question}</p>
    </li>
  ))}
</ul>

              </div>
            )}

            {questions.length > 0 && (
              <button
                onClick={generateNotes}
                className="w-full bg-green-600 hover:bg-green-700 text-white font-bold py-3 px-4 rounded-lg transition-colors duration-300 flex items-center justify-center"
              >
                <FileText className="mr-2" />
                Generate Study Notes
              </button>
            )}

            {importantNotes.length > 0 && (
              <div className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 rounded" role="alert">
                <p><strong>Study Tips:</strong></p>
                <ul>
                  {importantNotes.map((note, index) => (
                    <li key={index}>{note}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudyApp;