import React from 'react';
import './PricingCard.css';
import Navbar from '../../components/navbar/Navbar';
import { Link } from 'react-router-dom';

const PricingCard = () => {
  return (
    <div className="flex flex-col h-screen">
    <Navbar />
    <div className="bck flex items-center justify-center bg-black overflow-hidden flex-1">
      <div className="flex justify-center items-center">
          <div className="card mx-12">
            <div className="card__border"></div>
            <div className="card_title__container">
              <span className="card_title">Personal</span>
              <p className="card_paragraph">
               
              </p>
            </div>
            <hr className="line" />
            <ul className="card__list">
              <li className="card__list_item">
                <span className="check">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                    fill="currentColor"
                    className="check_svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12.416 3.376a.75.75 0 0 1 .208 1.04l-5 7.5a.75.75 0 0 1-1.154.114l-3-3a.75.75 0 0 1 1.06-1.06l2.353 2.353 4.493-6.74a.75.75 0 0 1 1.04-.207Z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </span>
                <span className="list_text">100GB per month</span>
              </li>
              <li className="card__list_item">
                <span className="check">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                    fill="currentColor"
                    className="check_svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12.416 3.376a.75.75 0 0 1 .208 1.04l-5 7.5a.75.75 0 0 1-1.154.114l-3-3a.75.75 0 0 1 1.06-1.06l2.353 2.353 4.493-6.74a.75.75 0 0 1 1.04-.207Z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </span>
                <span className="list_text">CDN access</span>
              </li>
              <li className="card__list_item">
                <span className="check">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                    fill="currentColor"
                    className="check_svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12.416 3.376a.75.75 0 0 1 .208 1.04l-5 7.5a.75.75 0 0 1-1.154.114l-3-3a.75.75 0 0 1 1.06-1.06l2.353 2.353 4.493-6.74a.75.75 0 0 1 1.04-.207Z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </span>
                <span className="list_text">0 egress</span>
              </li>
              <li className="card__list_item">
                <span className="check">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                    fill="currentColor"
                    className="check_svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12.416 3.376a.75.75 0 0 1 .208 1.04l-5 7.5a.75.75 0 0 1-1.154.114l-3-3a.75.75 0 0 1 1.06-1.06l2.353 2.353 4.493-6.74a.75.75 0 0 1 1.04-.207Z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </span>
                <span className="list_text">Encryption</span>
              </li>
              
            </ul>
            <Link to="/cyberlake/pay/monthly-personal">
      <button className="button flex items-center justify-center">
        <div>
          <p>$5 p/mo</p>
        </div>
      </button>
    </Link>
    <Link to="/cyberlake/pay/yearly-personal">
      <button className="button flex items-center justify-center">
        <div>
          <p>$50 yearly</p>
        </div>
      </button>
    </Link>
          </div>

          {/* Second card */}
          <div className="card mx-12">
            <div className="card__border"></div>
            <div className="card_title__container">
              <span className="card_title">Proffesional</span>
              <p className="card_paragraph">
                best for startups and small businesses
              </p>
            </div>
            <hr className="line" />
            <ul className="card__list">
              <li className="card__list_item">
                <span className="check">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                    fill="currentColor"
                    className="check_svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12.416 3.376a.75.75 0 0 1 .208 1.04l-5 7.5a.75.75 0 0 1-1.154.114l-3-3a.75.75 0 0 1 1.06-1.06l2.353 2.353 4.493-6.74a.75.75 0 0 1 1.04-.207Z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </span>
                <span className="list_text">1000GB per month</span>
              </li>
              <li className="card__list_item">
                <span className="check">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                    fill="currentColor"
                    className="check_svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12.416 3.376a.75.75 0 0 1 .208 1.04l-5 7.5a.75.75 0 0 1-1.154.114l-3-3a.75.75 0 0 1 1.06-1.06l2.353 2.353 4.493-6.74a.75.75 0 0 1 1.04-.207Z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </span>
                <span className="list_text">CSV pre-preocessing</span>
              </li>
              <li className="card__list_item">
                <span className="check">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                    fill="currentColor"
                    className="check_svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12.416 3.376a.75.75 0 0 1 .208 1.04l-5 7.5a.75.75 0 0 1-1.154.114l-3-3a.75.75 0 0 1 1.06-1.06l2.353 2.353 4.493-6.74a.75.75 0 0 1 1.04-.207Z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </span>
                <span className="list_text">gLtf optimization</span>
              </li>
              <li className="card__list_item">
                <span className="check">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                    fill="currentColor"
                    className="check_svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12.416 3.376a.75.75 0 0 1 .208 1.04l-5 7.5a.75.75 0 0 1-1.154.114l-3-3a.75.75 0 0 1 1.06-1.06l2.353 2.353 4.493-6.74a.75.75 0 0 1 1.04-.207Z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </span>
                <span className="list_text">Encryption</span>
              </li>
             
            </ul>
            <Link to="/cyberlake/pay/monthly-pro">
      <button className="button flex items-center justify-center">
        <div>
          <p>$50 p/mo</p>
        </div>
      </button>
    </Link>
    <Link to="/cyberlake/pay/yearly-pro">
      <button className="button flex items-center justify-center">
        <div>
          <p>$500 yearly</p>
        </div>
      </button>
    </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingCard;