import React, { useState } from 'react';
import { Mail, Send, Users, BarChart, Zap, Cloud, MessageSquare, User, Plus, Edit, Trash2, Copy, ThumbsUp, ThumbsDown } from 'lucide-react';

const SidebarButton = ({ icon: Icon, text, to, active }) => (
  <button className={`p-3 rounded-xl ${active ? 'bg-indigo-100 text-indigo-600' : 'text-gray-400 hover:bg-gray-100'}`}>
    <Icon className="w-6 h-6" />
  </button>
);

const TemplateCard = ({ id, name, subject, content, successRate, onEdit, onDelete, onTest }) => (
  <div className="bg-white p-6 rounded-lg shadow">
    <div className="flex justify-between items-center mb-4">
      <h3 className="text-lg font-semibold text-gray-900">{name}</h3>
      <div className="flex space-x-2">
        <button onClick={() => onEdit(id)} className="p-2 text-blue-600 hover:bg-blue-100 rounded">
          <Edit className="w-5 h-5" />
        </button>
        <button onClick={() => onDelete(id)} className="p-2 text-red-600 hover:bg-red-100 rounded">
          <Trash2 className="w-5 h-5" />
        </button>
        <button onClick={() => onTest(id)} className="p-2 text-green-600 hover:bg-green-100 rounded">
          <Send className="w-5 h-5" />
        </button>
      </div>
    </div>
    <p className="text-sm font-medium text-gray-600 mb-2">Subject: {subject}</p>
    <p className="text-sm text-gray-500 mb-4">{content.substring(0, 100)}...</p>
    <div className="flex justify-between items-center">
      <span className="text-sm font-medium text-gray-600">Success Rate: {successRate}%</span>
      <div className="flex items-center space-x-2">
        <ThumbsUp className="w-4 h-4 text-green-500" />
        <span className="text-sm text-gray-600">{Math.round(successRate)}%</span>
        <ThumbsDown className="w-4 h-4 text-red-500" />
        <span className="text-sm text-gray-600">{Math.round(100 - successRate)}%</span>
      </div>
    </div>
  </div>
);

const TemplateForm = ({ template, onSave, onClose }) => {
  const [name, setName] = useState(template?.name || '');
  const [subject, setSubject] = useState(template?.subject || '');
  const [content, setContent] = useState(template?.content || '');

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave({ id: template?.id, name, subject, content });
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="my-modal">
      <div className="relative top-20 mx-auto p-5 border w-3/4 shadow-lg rounded-md bg-white">
        <div className="mt-3">
          <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">
            {template ? 'Edit Email Template' : 'Create New Email Template'}
          </h3>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                Template Name
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="name"
                type="text"
                placeholder="Template Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="subject">
                Subject
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="subject"
                type="text"
                placeholder="Email Subject"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="content">
                Email Content
              </label>
              <textarea
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="content"
                rows="6"
                placeholder="Email Content"
                value={content}
                onChange={(e) => setContent(e.target.value)}
                required
              ></textarea>
            </div>
            <div className="flex items-center justify-between">
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="submit"
              >
                Save Template
              </button>
              <button
                className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="button"
                onClick={onClose}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

const SuccessRateTester = ({ onClose, onTestComplete }) => {
  const [testResults, setTestResults] = useState(null);

  const runTest = () => {
    // Simulating an AI test process
    setTimeout(() => {
      const simulatedResults = {
        openRate: Math.random() * 100,
        clickRate: Math.random() * 100,
        responseRate: Math.random() * 100,
        overallScore: Math.random() * 100,
      };
      setTestResults(simulatedResults);
      onTestComplete(simulatedResults.overallScore);
    }, 2000);
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="test-modal">
      <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
        <h3 className="text-lg font-medium text-gray-900 mb-4">Test Email Template</h3>
        {!testResults ? (
          <div>
            <p className="text-gray-600 mb-4">Click the button below to simulate an AI-powered test of your email template.</p>
            <button
              onClick={runTest}
              className="w-full bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Run Test
            </button>
          </div>
        ) : (
          <div>
            <h4 className="font-medium text-gray-800 mb-2">Test Results:</h4>
            <p className="text-sm text-gray-600">Open Rate: {testResults.openRate.toFixed(2)}%</p>
            <p className="text-sm text-gray-600">Click Rate: {testResults.clickRate.toFixed(2)}%</p>
            <p className="text-sm text-gray-600">Response Rate: {testResults.responseRate.toFixed(2)}%</p>
            <p className="font-medium text-gray-800 mt-2">Overall Score: {testResults.overallScore.toFixed(2)}%</p>
            <button
              onClick={onClose}
              className="mt-4 w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Close
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
const EmailTemplates = () => {
  const [templates, setTemplates] = useState([
    { id: 1, name: 'Welcome Email', subject: 'Welcome to Our Service', content: 'Dear [Name],\n\nWelcome to our service! We are excited to have you on board...', successRate: 85 },
    { id: 2, name: 'Follow-up', subject: 'Following up on our conversation', content: 'Hello [Name],\n\nI hope this email finds you well. I wanted to follow up on our recent conversation...', successRate: 72 },
  ]);
  const [showTemplateForm, setShowTemplateForm] = useState(false);
  const [editingTemplate, setEditingTemplate] = useState(null);
  const [showSuccessRateTester, setShowSuccessRateTester] = useState(false);
  const [testingTemplateId, setTestingTemplateId] = useState(null);

  const handleSaveTemplate = (template) => {
    if (template.id) {
      setTemplates(templates.map(t => t.id === template.id ? { ...template, successRate: t.successRate } : t));
    } else {
      setTemplates([...templates, { ...template, id: Date.now(), successRate: 0 }]);
    }
    setShowTemplateForm(false);
    setEditingTemplate(null);
  };

  const handleDeleteTemplate = (id) => {
    setTemplates(templates.filter(t => t.id !== id));
  };

  const handleEditTemplate = (id) => {
    const template = templates.find(t => t.id === id);
    setEditingTemplate(template);
    setShowTemplateForm(true);
  };

  const handleTestTemplate = (id) => {
    setTestingTemplateId(id);
    setShowSuccessRateTester(true);
  };

  const handleTestComplete = (score) => {
    setTemplates(templates.map(t => t.id === testingTemplateId ? { ...t, successRate: score } : t));
    setShowSuccessRateTester(false);
    setTestingTemplateId(null);
  };

  return (
    <div className="flex h-screen bg-gray-100 relative">
      {/* Coming Soon Overlay */}
      <div className="absolute inset-0 backdrop-blur-sm bg-white/20 z-50 flex items-center justify-center">
        <div className="text-center bg-white/80 p-8 rounded-xl shadow-lg">
          <h2 className="text-4xl font-bold text-gray-900 mb-4">Coming Soon</h2>
          <p className="text-xl text-gray-600">Our AI-powered email template system is being perfected!</p>
        </div>
      </div>

      {/* Sidebar */}
      <aside className="w-20 bg-white shadow-md">
        <div className="flex flex-col items-center py-6 space-y-8">
          <div className="p-3 bg-indigo-600 rounded-xl">
            <Mail className="w-8 h-8 text-white" />
          </div>
          <SidebarButton icon={Send} text="Send Emails" to="/send-emails" />
          <SidebarButton icon={Users} text="Manage Prospects" to="/prospects" />
          <SidebarButton icon={BarChart} text="Analytics" to="/analytics" />
          <SidebarButton icon={Zap} text="Automations" to="/automations" />
          <SidebarButton icon={Cloud} text="Integrations" to="/integrations" />
          <SidebarButton icon={MessageSquare} text="Templates" to="/templates" active />
          <SidebarButton icon={User} text="Account" to="/account" />
        </div>
      </aside>
      
      {/* Main content */}
      <main className="flex-1 p-8 overflow-auto">
        <div className="max-w-7xl mx-auto">
          <header className="flex justify-between items-center mb-8">
            <h1 className="text-3xl font-bold text-gray-900">Email Templates</h1>
            <button 
              onClick={() => setShowTemplateForm(true)}
              className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 transition duration-300 ease-in-out flex items-center"
            >
              <Plus className="w-5 h-5 mr-2" />
              New Template
            </button>
          </header>
          
          {/* Templates Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
            {templates.map(template => (
              <TemplateCard 
                key={template.id}
                {...template}
                onEdit={handleEditTemplate}
                onDelete={handleDeleteTemplate}
                onTest={handleTestTemplate}
              />
            ))}
          </div>
        </div>
      </main>

      {showTemplateForm && (
        <TemplateForm
          template={editingTemplate}
          onSave={handleSaveTemplate}
          onClose={() => {
            setShowTemplateForm(false);
            setEditingTemplate(null);
          }}
        />
      )}

      {showSuccessRateTester && (
        <SuccessRateTester
          onClose={() => setShowSuccessRateTester(false)}
          onTestComplete={handleTestComplete}
        />
      )}
    </div>
  );
};

export default EmailTemplates;