import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLogoutMutation } from '../../slices/usersApiSlice';
import { logout } from '../../slices/authSlice';
import { useSelector, useDispatch } from 'react-redux';
import logo from '../../assets/logo.png';

const menuItems = [
  {
    name: 'View',
    href: '/view',
  },
  
  {
    name: 'Docs',
    href: 'https://docs.metabees.org/',
  },
  {
    name: 'Contact',
    href: '/contact',
  },
];

function Navbar() {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { userInfo } = useSelector((state) => state.auth);

  const [logoutApiCall] = useLogoutMutation();
  const dispatch = useDispatch();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLogoClick = () => {
    navigate('/');
  };

  const logoutHandler = async () => {
    try {
      await logoutApiCall().unwrap();
      dispatch(logout());
      navigate('/login');
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="w-full top-0 left-0 right-0 overflow-hidden bg-transparent">
      <div className="flex items-center justify-between px-4 py-3 md:px-6">
        <div className="flex items-center">
          <button
            type="button"
            className="text-white lg:hidden focus:outline-none"
            onClick={toggleMenu}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16m-8 6h8"
              />
            </svg>
          </button>
          <div
            className="flex items-center cursor-pointer"
            onClick={handleLogoClick}
          >
            <img src={logo} alt="logo" className="h-6 w-6" />
            <span className="text-xl ml-2">Metabees</span>
          </div>
        </div>
        <div className="hidden lg:flex lg:items-center lg:flex-grow lg:justify-center">
          <ul className="flex space-x-10">
            {menuItems.map((item) => (
              <li key={item.name}>
                <a href={item.href} className="text-white hover:text-gray-300">
                  {item.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div className="hidden lg:flex lg:items-center">
          {userInfo ? (
            <div className="flex gap-3">
              <button
                type="button"
                className="btn bg-purple-600 text-white"
                onClick={logoutHandler}
              >
                Log out
              </button>
              <button
                onClick={() => navigate('/dashboard')}
                className="bg-yellow-950 text-yellow-400 border border-yellow-400 border-b-4 font-medium overflow-hidden relative px-4 py-2 rounded-md hover:brightness-150 hover:border-t-4 hover:border-b active:opacity-75 outline-none duration-300 group"
              >
                <span className="bg-yellow-400 shadow-yellow-400 absolute -top-[150%] left-0 inline-flex w-80 h-[5px] rounded-md opacity-50 group-hover:top-[150%] duration-500 shadow-[0_0_10px_10px_rgba(0,0,0,0.3)]"></span>
                Dashboard
              </button>
            </div>
          ) : (
            <div className="flex gap-3">
              <button
                type="button"
                className="btn bg-purple-600 text-white"
                onClick={() => navigate('/login')}
              >
                Sign In
              </button>
            </div>
          )}
        </div>
      </div>
      {isMenuOpen && (
        <div className="lg:hidden">
          <ul className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            {menuItems.map((item) => (
              <li key={item.name}>
                <a
                  href={item.href}
                  className="block px-3 py-2 rounded-md text-base font-medium text-white hover:bg-gray-700 hover:text-white"
                >
                  {item.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default Navbar;
