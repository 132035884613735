import { useState } from 'react';
import axios from 'axios';
import 'tailwindcss/tailwind.css'; // Ensure Tailwind CSS is imported
import Navbar from '../../components/navbar/Navbar.jsx';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [step, setStep] = useState(1);
  const [msg, setMsg] = useState('');
  const [error, setError] = useState('');

  const handleSendOtp = async (e) => {
    e.preventDefault();
    try {
      const url = `https://web-production-5ee8.up.railway.app/auth/generateAndSendOTP`; // Replace with your API endpoint
      const { data } = await axios.post(url, { email });
      setMsg(data.message);
      setError('');
      setStep(2);
    } catch (error) {
      if (error.response && error.response.status >= 400 && error.response.status <= 500) {
        setError(error.response.data.message);
        setMsg('');
      }
    }
  };

  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    try {
      const url = `https://web-production-5ee8.up.railway.app/auth/verifyOtp`; // Replace with your API endpoint
      const { data } = await axios.post(url, { email, otp });
      setMsg(data.message);
      setError('');
      setStep(3);
    } catch (error) {
      if (error.response && error.response.status >= 400 && error.response.status <= 500) {
        setError(error.response.data.message);
        setMsg('');
      }
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }
    try {
      const url = `https://web-production-5ee8.up.railway.app/auth/newpassword`; // Replace with your API endpoint
      const { data } = await axios.post(url, { email, newPassword });
      setMsg(data.message);
      setError('');
    } catch (error) {
      if (error.response && error.response.status >= 400 && error.response.status <= 500) {
        setError(error.response.data.message);
        setMsg('');
      }
    }
  };

  return (
    <div className="bg-black w-full min-h-screen flex flex-col">
      <Navbar /> 
      <div className="flex-grow flex justify-center items-center">
        <div className="w-full max-w-xs p-8 border rounded shadow-lg z-20 bg-gray-900">
          <form 
            onSubmit={step === 1 ? handleSendOtp : step === 2 ? handleVerifyOtp : handleResetPassword}
          >
            <h1 className="text-2xl font-bold mb-4 text-purple-500">Forgot Password</h1>
  
            {step === 1 && (
              <>
                <div className="mb-4">
                  <label className="block mb-2">Email</label>
                  <input
                    type="email"
                    placeholder="Enter email"
                    name="email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    required
                    className="w-full border rounded py-2 px-3 bg-gray-800 text-white"
                  />
                </div>
                <button 
                  type="submit" 
                  className="bg-purple-700 hover:bg-purple-800 text-white font-bold py-2 px-4 rounded"
                >
                  Send OTP
                </button>
              </>
            )}
  
            {step === 2 && (
              <>
                <div className="mb-4">
                  <label className="block mb-2">Enter OTP</label>
                  <input
                    type="text"
                    placeholder="Enter OTP"
                    name="otp"
                    onChange={(e) => setOtp(e.target.value)}
                    value={otp}
                    required
                    className="w-full border rounded py-2 px-3 bg-gray-800 text-white"
                  />
                </div>
                <button 
                  type="submit" 
                  className="bg-purple-700 hover:bg-purple-800 text-white font-bold py-2 px-4 rounded"
                >
                  Verify OTP
                </button>
              </>
            )}
  
            {step === 3 && (
              <>
                <div className="mb-4">
                  <label className="block mb-2">New Password</label>
                  <input
                    type="password"
                    placeholder="New Password"
                    name="newPassword"
                    onChange={(e) => setNewPassword(e.target.value)}
                    value={newPassword}
                    required
                    className="w-full border rounded py-2 px-3 bg-gray-800 text-white"
                  />
                </div>
                <div className="mb-4">
                  <label className="block mb-2">Confirm Password</label>
                  <input
                    type="password"
                    placeholder="Confirm Password"
                    name="confirmPassword"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    value={confirmPassword}
                    required
                    className="w-full border rounded py-2 px-3 bg-gray-800 text-white"
                  />
                </div>
                <button 
                  type="submit" 
                  className="bg-purple-700 hover:bg-purple-800 text-white font-bold py-2 px-4 rounded"
                >
                  Submit
                </button>
              </>
            )}
  
            {error && <div className="mt-4 text-red-500">{error}</div>}
            {msg && <div className="mt-4 text-green-500">{msg}</div>}
          </form>
        </div>
      </div>
    </div>
  );
  
  
};

export default ForgotPassword;
