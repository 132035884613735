import React from 'react';
import { Pen, Book, Users, Award, CheckCircle, Play } from 'lucide-react';

const TlandingPage = () => {
  return (
    <div className="min-h-screen bg-white font-sans">
    <header className="container mx-auto px-4 py-6 flex justify-between items-center">
      <div className="flex items-center space-x-2">
        <Pen className="w-6 h-6" />
        <span className="text-xl font-bold">etextgen</span>
      </div>
      <nav>
        <ul className="flex space-x-6">
          <li><a href="#" className="text-gray-600 hover:text-gray-900">Pricing</a></li>
          <li><a href="#" className="text-gray-600 hover:text-gray-900">About</a></li>
          <li><a href="#" className="text-gray-600 hover:text-gray-900">Blog</a></li>
          <li><a href="#" className="text-gray-600 hover:text-gray-900">Support</a></li>
        </ul>
      </nav>
      <div className="flex items-center space-x-4">
        <button className="text-gray-600 hover:text-gray-900">Log In</button>
        <button className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700">Start writing</button>
      </div>
    </header>

    <main className="container mx-auto px-4 py-16 text-center">
      <h1 className="text-6xl font-serif mb-6">Reading, Writing, and<br />Referencing. Together.</h1>
      <p className="text-xl text-gray-600 mb-8">
        Stop tab switching and consolidate your research and<br />
        writing workflow with etextgen's AI-powered workspace.
      </p>
      <button className="bg-blue-600 text-white text-lg px-8 py-3 rounded-md hover:bg-blue-700">
        Start writing - it's free
      </button>
      <div className="mt-8 flex justify-center items-center space-x-2">
        <div className="flex -space-x-2">
          <img src="/api/placeholder/32/32" alt="User" className="w-8 h-8 rounded-full border-2 border-white" />
          <img src="/api/placeholder/32/32" alt="User" className="w-8 h-8 rounded-full border-2 border-white" />
          <img src="/api/placeholder/32/32" alt="User" className="w-8 h-8 rounded-full border-2 border-white" />
        </div>
        <span className="text-sm text-gray-600">Trusted by over 3 million academics worldwide</span>
      </div>
    </main>

    <section className="bg-gray-100 py-16">
      <div className="container mx-auto px-4 text-center">
        <h2 className="text-4xl font-serif mb-4">You're in control</h2>
        <p className="text-xl text-gray-600 mb-12">Types of content etextgen can help you with</p>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {[
            { title: 'Essays', icon: Pen, description: "Save hours writing your essay with our AI essay writing tool." },
            { title: 'Literature reviews', icon: Book, description: "Discover, write, and cite relevant research effortlessly." },
            { title: 'Research Papers', icon: Users, description: "Polish your writing to increase submission success rates." },
            { title: 'Dissertations', icon: Award, description: "Structure and write comprehensive dissertations with ease." },
            { title: 'Conference Papers', icon: Users, description: "Prepare impactful conference papers and presentations." },
            { title: 'Grant Proposals', icon: CheckCircle, description: "Craft compelling grant proposals to secure funding." },
          ].map((item) => (
            <div key={item.title} className="bg-white p-6 rounded-lg shadow">
              <item.icon className="w-12 h-12 mx-auto mb-4 text-blue-600" />
              <h3 className="text-xl font-semibold mb-2">{item.title}</h3>
              <p className="text-gray-600">{item.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>

    <section className="py-16">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row items-center">
          <div className="md:w-1/2 mb-8 md:mb-0">
            <div className="aspect-w-16 aspect-h-9 rounded-lg overflow-hidden shadow-lg">
              <div className="bg-gray-300 flex items-center justify-center">
                <Play className="w-16 h-16 text-white opacity-75" />
              </div>
            </div>
          </div>
          <div className="md:w-1/2 md:pl-12">
            <h2 className="text-4xl font-serif mb-6">See etextgen in action</h2>
            <p className="text-xl text-gray-600 mb-6">
              Watch how etextgen streamlines your research and writing process,
              saving you time and improving the quality of your work.
            </p>
            <button className="bg-blue-600 text-white px-6 py-3 rounded-md hover:bg-blue-700">
              Watch full demo
            </button>
          </div>
        </div>
      </div>
    </section>

    <section className="bg-gray-100 py-16">
      <div className="container mx-auto px-4 text-center">
        <h2 className="text-4xl font-serif mb-8">Trusted by academics worldwide</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
          <div>
            <div className="text-5xl font-bold text-blue-600 mb-2">3M+</div>
            <p className="text-xl text-gray-600">Active users</p>
          </div>
          <div>
            <div className="text-5xl font-bold text-blue-600 mb-2">1M+</div>
            <p className="text-xl text-gray-600">Papers generated</p>
          </div>
          <div>
            <div className="text-5xl font-bold text-blue-600 mb-2">500+</div>
            <p className="text-xl text-gray-600">Universities partnered</p>
          </div>
        </div>
      </div>
    </section>

    <section className="py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-serif mb-8 text-center">What our users say</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {[
            { name: "Dr. Sarah Johnson", role: "Professor of Biology", quote: "etextgen has revolutionized how I approach my research writing. It's an indispensable tool for any serious academic." },
            { name: "Mark Chen", role: "PhD Candidate in Computer Science", quote: "The time I save using etextgen allows me to focus more on my experiments and data analysis. It's a game-changer." },
            { name: "Dr. Emily Patel", role: "Research Director", quote: "Our entire department has seen a significant increase in publication rates since we started using etextgen. Highly recommended!" },
          ].map((testimonial, index) => (
            <div key={index} className="bg-white p-6 rounded-lg shadow">
              <p className="text-gray-600 mb-4">"{testimonial.quote}"</p>
              <div className="font-semibold">{testimonial.name}</div>
              <div className="text-sm text-gray-500">{testimonial.role}</div>
            </div>
          ))}
        </div>
      </div>
    </section>

    <section className="bg-indigo-900 text-white py-16">
      <div className="container mx-auto px-4 text-center">
        <h2 className="text-4xl font-serif mb-4">Team & institutional plans</h2>
        <p className="text-xl mb-8">Collaborate with your research team and speed up your workflow.</p>
        <div className="flex justify-center items-center space-x-4">
          <span className="bg-pink-500 px-3 py-1 rounded-full text-sm">Professor</span>
          <button className="bg-blue-600 px-6 py-2 rounded-md hover:bg-blue-700">Learn more</button>
          <span className="bg-cyan-500 px-3 py-1 rounded-full text-sm">Researcher</span>
        </div>
      </div>
    </section>

    {/* New section: AI-Powered Features */}
    <section className="py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-serif mb-8 text-center">AI-Powered Features</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
          {[
            { title: "Smart Citations", icon: Book, description: "Automatically find and insert relevant citations as you write." },
            { title: "Style Formatting", icon: Pen, description: "Instantly format your document in any academic style (APA, MLA, Chicago, etc.)." },
            { title: "Plagiarism Check", icon: CheckCircle, description: "Ensure originality with our advanced AI-powered plagiarism detector." },
            { title: "Research Assistant",icon: CheckCircle, description: "Get instant answers and summaries from millions of academic papers." },
          ].map((feature, index) => (
            <div key={index} className="flex items-start">
              <feature.icon className="w-12 h-12 text-blue-600 mr-4 flex-shrink-0" />
              <div>
                <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
                <p className="text-gray-600">{feature.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>

    {/* New section: Pricing Plans */}
    <section className="bg-gray-100 py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-serif mb-8 text-center">Choose Your Plan</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {[
            { name: "Basic", price: "Free", features: ["500 words/month", "Basic citation tool", "Standard support"] },
            { name: "Pro", price: "$9.99/month", features: ["Unlimited words", "Advanced AI features", "Priority support", "Plagiarism checker"] },
            { name: "Team", price: "Custom", features: ["Everything in Pro", "Collaboration tools", "Admin dashboard", "API access"] },
          ].map((plan, index) => (
            <div key={index} className="bg-white p-8 rounded-lg shadow-lg text-center">
              <h3 className="text-2xl font-semibold mb-4">{plan.name}</h3>
              <div className="text-4xl font-bold mb-6">{plan.price}</div>
              <ul className="text-left mb-8">
                {plan.features.map((feature, i) => (
                  <li key={i} className="flex items-center mb-2">
                    <CheckCircle className="w-5 h-5 text-green-500 mr-2" />
                    <span>{feature}</span>
                  </li>
                ))}
              </ul>
              <button className="bg-blue-600 text-white px-6 py-2 rounded-md hover:bg-blue-700 w-full">
                {plan.name === "Team" ? "Contact Sales" : "Get Started"}
              </button>
            </div>
          ))}
        </div>
      </div>
    </section>

    {/* New section: Integration Partners */}
    <section className="py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-serif mb-8 text-center">Seamless Integrations</h2>
        <p className="text-xl text-center text-gray-600 mb-12">etextgen works with your favorite tools</p>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
          {[
            { name: "Mendeley", icon: Book },
            { name: "Zotero", icon: Book },
            { name: "Microsoft Word", icon: Pen },
            { name: "Google Docs", icon: Pen },
            { name: "Overleaf", icon: Pen },
            { name: "EndNote", icon: Book },
            { name: "Grammarly", icon: CheckCircle },
            { name: "Dropbox", icon: Pen },
          ].map((partner, index) => (
            <div key={index} className="flex flex-col items-center">
              <partner.icon className="w-16 h-16 text-gray-400 mb-4" />
              <span className="text-gray-600">{partner.name}</span>
            </div>
          ))}
        </div>
      </div>
    </section>

    {/* New section: Call to Action */}
    <section className="bg-blue-600 text-white py-16">
      <div className="container mx-auto px-4 text-center">
        <h2 className="text-4xl font-serif mb-6">Ready to supercharge your academic writing?</h2>
        <p className="text-xl mb-8">Join millions of researchers and students who trust etextgen for their academic success.</p>
        <button className="bg-white text-blue-600 text-lg px-8 py-3 rounded-md hover:bg-gray-100">
          Start your free trial
        </button>
        <p className="mt-4 text-sm">No credit card required. Cancel anytime.</p>
      </div>
    </section>

      <section className="bg-indigo-900 text-white py-16">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-4xl font-serif mb-4">Team & institutional plans</h2>
          <p className="text-xl mb-8">Collaborate with your research team and speed up your workflow.</p>
          <div className="flex justify-center items-center space-x-4">
            <span className="bg-pink-500 px-3 py-1 rounded-full text-sm">Professor</span>
            <button className="bg-blue-600 px-6 py-2 rounded-md hover:bg-blue-700">Learn more</button>
            <span className="bg-cyan-500 px-3 py-1 rounded-full text-sm">Researcher</span>
          </div>
        </div>
      </section>

      <footer className="bg-gray-100 py-12">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
            <div>
              <h3 className="font-semibold mb-4">Product</h3>
              <ul className="space-y-2">
                <li><a href="#" className="text-gray-600 hover:text-gray-900">Features</a></li>
                <li><a href="#" className="text-gray-600 hover:text-gray-900">Pricing</a></li>
                <li><a href="#" className="text-gray-600 hover:text-gray-900">Use cases</a></li>
              </ul>
            </div>
            <div>
              <h3 className="font-semibold mb-4">Company</h3>
              <ul className="space-y-2">
                <li><a href="#" className="text-gray-600 hover:text-gray-900">About us</a></li>
                <li><a href="#" className="text-gray-600 hover:text-gray-900">Careers</a></li>
                <li><a href="#" className="text-gray-600 hover:text-gray-900">Contact</a></li>
              </ul>
            </div>
            <div>
              <h3 className="font-semibold mb-4">Resources</h3>
              <ul className="space-y-2">
                <li><a href="#" className="text-gray-600 hover:text-gray-900">Blog</a></li>
                <li><a href="#" className="text-gray-600 hover:text-gray-900">Help center</a></li>
                <li><a href="#" className="text-gray-600 hover:text-gray-900">Webinars</a></li>
              </ul>
            </div>
            <div>
              <h3 className="font-semibold mb-4">Legal</h3>
              <ul className="space-y-2">
                <li><a href="#" className="text-gray-600 hover:text-gray-900">Privacy policy</a></li>
                <li><a href="#" className="text-gray-600 hover:text-gray-900">Terms of service</a></li>
                <li><a href="#" className="text-gray-600 hover:text-gray-900">Cookie policy</a></li>
              </ul>
            </div>
          </div>
          <div className="mt-8 pt-8 border-t border-gray-200 text-center text-gray-500">
            © 2024 etextgen. All rights reserved.
          </div>
        </div>
      </footer>
    </div>
  );
};

export default TlandingPage;