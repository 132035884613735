import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLoginMutation } from '../../slices/usersApiSlice';
import { setCredentials } from '../../slices/authSlice';
import { useNavigate } from 'react-router-dom';
import './LogIn.css';
import Navbar from '../../components/navbar/Navbar.jsx';

function LogIn() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [login] = useLoginMutation();

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [showError, setShowError] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await login(formData).unwrap();
      dispatch(setCredentials({ ...res }));
      navigate('/dashboard');
    } catch (error) {
      console.error(error);
      if (error.status === 401) {
        setShowError(true);
        setTimeout(() => setShowError(false), 3000);
      }
    }
  };

  return (
    <div className="min-h-screen bg-cover bg-center flex flex-col" style={{backgroundImage: "url('https://cybverlake-test.s3.ap-south-1.amazonaws.com/dashboard-bg.jpg')"}}>
      <Navbar />
      <div className="flex-grow flex items-center justify-center px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8 bg-black bg-opacity-50 p-10 rounded-2xl backdrop-filter backdrop-blur-lg shadow-xl">
          <div>
            <h2 className="mt-6 text-center text-4xl font-extrabold text-white">
              Welcome Back
            </h2>
            <p className="mt-2 text-center text-sm text-gray-300">
              Sign in to your account
            </p>
          </div>
          <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
            <div className="space-y-4">
              {['email', 'password'].map((field) => (
                <div key={field}>
                  <label htmlFor={field} className="sr-only">{field === 'email' ? 'Email address' : 'Password'}</label>
                  <input
                    id={field}
                    name={field}
                    type={field}
                    autoComplete={field === 'email' ? 'email' : 'current-password'}
                    required
                    className="appearance-none relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-400 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm bg-gray-800 bg-opacity-50"
                    placeholder={field === 'email' ? 'Email address' : 'Password'}
                    onChange={handleChange}
                    value={formData[field]}
                  />
                </div>
              ))}
            </div>

            <div>
              <button
                type="submit"
                className="group relative w-full flex justify-center py-3 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition duration-150 ease-in-out"
              >
                Sign In
              </button>
            </div>
          </form>
          <div className="text-center space-y-2">
            <p className="text-sm text-gray-300">
              No account?{' '}
              <a href="/signup" className="font-medium text-blue-400 hover:text-blue-300 transition duration-150 ease-in-out">
                Sign up!
              </a>
            </p>
            <p className="text-sm text-gray-300">
              Forgot password?{' '}
              <a href="/forgotpassword" className="font-medium text-blue-400 hover:text-blue-300 transition duration-150 ease-in-out">
                Change it!
              </a>
            </p>
          </div>
        </div>
      </div>
      {showError && (
        <div className="fixed top-0 left-0 right-0 bg-red-500 text-white text-center py-2 z-50">
          Wrong email or password. Please try again.
        </div>
      )}
    </div>
  );
}

export default LogIn;