/* eslint-disable jsx-a11y/anchor-is-valid */
import linkedin from '../../assets/FooterIcons/linkedin.svg'
import instagram from '../../assets/FooterIcons/instagram.svg'
import twitter from '../../assets/FooterIcons/twitter.svg'
import logo from '../../assets/logo.png'
import metabees from '../../assets/logo.png';

function Footer() {
  return (
    <footer className="footer p-10 bg-black text-base-content">
    <aside>
    <img src={metabees} alt="Metabees Image" width="50" height="50" />
      <p>metabees.in<br/>building the hardest tech since 2021</p>
    </aside> 
    <nav>
      <p className="footer-title">Tech</p> 
      <a href="https://docs.metabees.org/neptaverse-engine"className="link link-hover">Neptaverse engine</a>
      <a href="https://docs.metabees.org/neptaverse-engine/upload-guide"className="link link-hover">Upload guide</a>
      <a href="https://docs.metabees.org/neptaverse-engine/ar-guide"className="link link-hover">AR guide</a>
      <a href="https://docs.metabees.org/neptaverse-engine/vr-guide" className="link link-hover">VR guide</a>
      <a href="https://docs.metabees.org/echidna" className="link link-hover">Echidna</a>
      <a href="https://docs.metabees.org/neptaverse-engine/vr-guide/apis" className="link link-hover">API's</a>
    </nav> 
    <nav>
      <p className="footer-title">Company</p> 
      <a href="https://docs.metabees.org/" className="link link-hover">About us</a>
      <a className="link link-hover">Contact</a>
    </nav> 
    <nav>
      <header className="footer-title">Legal</header> 
      <a href="https://docs.metabees.org/legal/terms-of-use" className="link link-hover">Terms of use</a>
      <a href="https://docs.metabees.org/legal/security-and-privacy" className="link link-hover">Privacy policy</a>
      <a href="https://docs.metabees.org/legal/ai-training-policy" className="link link-hover">AI training policy</a>
    </nav>
  </footer>
  )
}

export default Footer
