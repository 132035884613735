import { useState, useEffect } from 'react';
import axios from 'axios';
import Navbar from '../../components/navbar/Navbar';
import base64url from 'base64url';
import { useSelector } from 'react-redux';
import './cyberlakedash.css';
import vr from '../../assets/ContentImages/virtual-reality.png';
import csv from '../../assets/ContentImages/csv.png';
import file from '../../assets/ContentImages/file.png';
import { Link } from 'react-router-dom';
let Flurl = '';



const Sidebar = ({ dataLeft, acc }) => {
  return (
    <div className="bg-black text-gray-300 w-64 h-[calc(100vh-64px)] overflow-y-auto flex flex-col">
      <div className="p-6 flex-grow">
        <h2 className="text-xl font-semibold mb-8 text-white">Dashboard</h2>
        <nav>
          <ul className="space-y-4">
          <li>
  <Link 
    to="/cyberlake/dashboard" 
    className="flex items-center py-2 px-4 rounded transition-colors hover:bg-gray-800"
    onClick={(e) => {
      e.preventDefault();
      alert("You don't have Neptaverse access");
    }}
  >
    <span className="text-xl mr-3" role="img" aria-label="analytics">📊</span>
    Neptaverse Analytics
  </Link>
</li>
            <li>
              <Link to="/cyberlake/upload" className="flex items-center py-2 px-4 rounded transition-colors hover:bg-gray-800">
                <svg className="w-5 h-5 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
                </svg>
                Upload to cyberlake
              </Link>
            </li>
            <li>
            <li>
  <Link to="/cyberlake/chat" className="flex items-center py-2 px-4 rounded transition-colors hover:bg-gray-800">
    <span className="text-xl mr-3" role="img" aria-label="snake">🐍</span>
    <span className="flex items-center">
      Echidna chat
      <span className="ml-2 text-xs bg-yellow-500 text-black px-1 rounded">beta</span>
    </span>
  </Link>
</li>
<li>
  <Link to="/echidnamail" className="flex items-center py-2 px-4 rounded transition-colors hover:bg-gray-800">
    <svg className="w-6 h-6 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
      <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
    </svg>
    <span className="flex items-center">
      Echidna mail
      <span className="ml-2 text-xs bg-yellow-500 text-black px-1 rounded">beta</span>
    </span>
  </Link>
</li>
            </li>
          </ul>
        </nav>
      </div>
      {!acc && (
        <div className="p-6 border-t border-gray-700">
          <p className="text-sm text-gray-400 mb-3">{dataLeft !== null ? `${dataLeft} GB left` : 'Loading...'}</p>
          <button
  className="w-full bg-blue-600 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded transition-colors"
  onClick={() => alert("Error: Please contact sales for upgrading to Pro.")}
>
  Upgrade to Pro
</button>

        </div>
      )}
    </div>
  );
};


function TableRow({ product, handleDelete, handleDownload, handleOptimize }) {
  const downloadLink = `https://web-production-5ee8.up.railway.app/cyberlake/download/${product.fileName}`;
  const viewLink = `/cyberlake/privateview/${product.fileName}`;

  const handleActionClick = async (action) => {
    const secretCode = window.prompt('Enter secret code:');

    if (secretCode) {
      try {
        const response = await axios.post('https://web-production-5ee8.up.railway.app/cyberlake/verify-secret-code', {
          secretc:secretCode,
          fileName : product.fileName
        });

        if (response.status === 200) {
          // Perform the desired action
          switch (action) {
            case 'view':
              window.location.href = viewLink;
              break;
            case 'download':
              handleDownload(product.fileName);
              break;
            case 'optimize':
              handleOptimize(product.fileName, product.filetype);
              break;
            case 'delete':
              handleDelete(product.fileName, product.fileUrl);
              break;
            default:
              break;
          }
        } else {
          alert('Invalid secret code');
        }
      } catch (error) {
        alert('An error occurred while verifying the secret code');
      }
    }
  };

  return (
    <tr>
      <td>
        <label>
          <input type="checkbox" className="checkbox" />
        </label>
      </td>
      <td>
        <div className="flex items-center gap-3">
          <div className="avatar">
            <div className="mask mask-squircle w-11 h-11">
              {product.fileType == 'csv' ? (
                <img src={csv} alt={product.fileName} />
              ) : (
                <img src={file} alt={product.fileName} />
              )}
            </div>
          </div>

          <div>
          <div className="text-gray-300"><a>{product.fileName}</a></div>

          </div>
        </div>
      </td>
      <td>
        {product.fileUrl}
        <br />
      </td>
      <td>running</td>
      <td><div className="text-gray-300"><a>{product.filetype}</a></div></td>
      <td>{product.hosting}</td>
      <td style={{ width: '70px' }}>
        <button
          className="btn btn-sm bg-gray-800 text-white hover:bg-gray-700 w-full"
          onClick={() => handleActionClick('view')}
        >
          View
        </button>
      </td>
      <td style={{ width: '70px' }}>
        <button
          className="btn btn-sm bg-gray-800 text-white hover:bg-gray-700 w-full"
          onClick={() => handleActionClick('download')}
        >
          Download
        </button>
      </td>
      <td style={{ width: '70px' }}>
        {product.filetype === 'CSV' ? (
          <button
            className="btn btn-sm bg-green-600 text-white hover:bg-green-700 w-full"
            onClick={() => handleActionClick('optimize')}
          >
            Optimize
          </button>
        ) : null}
      </td>
      <td style={{ width: '70px' }}>
        <button
          className="btn btn-sm bg-red-600 text-white hover:bg-red-700 w-full"
          onClick={() => handleActionClick('delete')}
        >
          Delete
        </button>
      </td>
    </tr>
  );
}


const Cyberdash = () => {
  const { userInfo } = useSelector((state) => state.auth);
  if (userInfo && userInfo._id) {
    sessionStorage.setItem('userId', userInfo._id);
  }
  const [products, setProducts] = useState([]);
  const [dataLeft, setDataLeft] = useState(null); // Define the dataLeft state
  const [acc, setacc] = useState(''); 
  

  const handleDelete = async (fileName, fileUrl) => {
    try {
      // Show confirmation dialog
      const confirmed = window.confirm('Are you sure you want to delete this file?');

      if (confirmed) {
        // Send a request to the server to delete the file from AWS and MongoDB
        const response = await axios.post(
          'https://web-production-5ee8.up.railway.app/cyberlake/delete',
          { fileName:fileName, fileUrl:fileUrl, userId: userInfo._id },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + userInfo.token,
            },
          }
        );

        if (response.status === 200) {
          // File deleted successfully, update the products state
          setProducts(products.filter((product) => product.fileName !== fileName));
        } else {
          console.error('Error deleting file:', response.data);
        }
      }
    } catch (error) {
      console.error('Error deleting file:', error);
    }
  };

  function rearrangeAlphabet(char) {
    const lowercase = 'abcdefghijklmnopqrstuvwxyz';
    const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const isLowercase = lowercase.includes(char);
    const isUppercase = uppercase.includes(char);

    if (isLowercase) {
      const index = lowercase.indexOf(char);
      return lowercase[25 - index];
    } else if (isUppercase) {
      const index = uppercase.indexOf(char);
      return uppercase[25 - index];
    } else {
      return char;
    }
  }

  function factorial(n) {
    let result = 1;
    for (let i = 2; i <= n; i++) {
      result *= i;
    }
    return result;
  }

  function decodeURL(encoded) {
    let decoded = '';
    let i = 0;
    while (i < encoded.length) {
      const char = encoded[i];
      if (/[a-zA-Z]/.test(char)) {
        decoded += rearrangeAlphabet(char);
      } else if (/[0-9]/.test(char)) {
        const num = parseInt(char);
        let factor = 1;
        while (factorial(factor) < num) {
          factor++;
        }
        if (factorial(factor) === num) {
          decoded += factor.toString();
        } else {
          // Factorial not found, leave as is
          decoded += char;
        }
      } else if (char === ')') {
        decoded += '.';
      } else if (char === '$') {
        decoded += '/';
      } else if (encoded.substr(i, 2) === '+=') {
        decoded += '-';
        i += 1; // Skip the next character ('=')
      } else {
        decoded += char;
      }
      i++;
    }
    return decoded;
  }

  const handleDownload = async (fileName) => {
    try {
      const response = await axios.post(
        'https://web-production-5ee8.up.railway.app/cyberlake/download',
        { fileName:fileName, userId: userInfo._id },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userInfo.token,
          },
        }
      );

      if (response.status === 200) {
        const downloadLink = response.data.fileurl;
    
        // Decode the URL if needed
    
        if (downloadLink) {
            const completeUrl = 'https://' + downloadLink; // Assuming it's an HTTPS link
            window.location.href = completeUrl; 
        } else {
            console.error('Invalid decoded URL:');
        }
    } else {
        console.error('Error downloading file:', response.data);
    }
    } catch (error) {
    console.error('Error downloading file:', error);
    }
    };
    

  const fetchDataLeft = async () => {
    try {
      const response = await axios.post('https://web-production-5ee8.up.railway.app/cyberlake/dataleft', {
        userId: userInfo._id,
      }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + userInfo.token,
        },
      });
  
      if (response.status === 200) {
        setDataLeft(response.data.dataleft);
        setacc(response.data.acc)
      }
    } catch (error) {
      console.error('Error fetching data left:', error);
    }
  };

  const handleOptimize = async (fileName, fileType) => {
    try {
      let response;
      if (fileType === 'CSV') {
        response = await axios.post(
          'https://web-production-5ee8.up.railway.app/cyberlake/csvoptimize',
          { fileName:fileName, userId: userInfo._id },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + userInfo.token,
            },
          }
        );
      } else if (fileType === 'model') {
        response = await axios.post(
          'https://web-production-5ee8.up.railway.app/cyberlake/optimizemodel',
          { fileName:fileName, userId: userInfo._id },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + userInfo.token,
            },
          }
        );
      }

      if (response.status === 200) {
        if (fileType === 'csv') {
        } else if (fileType === 'model') {
          alert('File optimization successful!');
        }
      } else {
        console.error('Error optimizing file:', response.data);
      }
    } catch (error) {
      console.error('Error optimizing file:', error);
    }
  };

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.post('https://web-production-5ee8.up.railway.app/cyberlake/view/all', { userId: userInfo._id }, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + userInfo.token,
          },
        });

        if (response.status === 200) {
          setProducts(response.data);
        }
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
    fetchDataLeft(); 
  }, [userInfo._id, userInfo.token]);

  return (
    <div className="h-screen w-full bg-black text-white flex flex-col">
      <Navbar />
      <div className="flex flex-1">
        <Sidebar dataLeft={dataLeft} acc={acc} />
        <div className="flex-1 overflow-hidden">
          <div className="px-8 py-6 h-full overflow-auto">
            <div className="overflow-x-auto">
              <table className="table w-full">
                <thead>
                  <tr>
                    <th></th>
                    <th className="text-white font-bold">Product Name</th>
                    <th className="text-white font-bold">URL</th>
                    <th className="text-white font-bold">Status</th>
                    <th className="text-white font-bold">Type</th>
                    <th className="text-white font-bold">Server</th>
                    <th></th>
                    <th></th>
                    <th></th>
                    {products.some((product) => product.filetype !== 'CSV') && <th></th>}
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(products) &&
                    products.map((product, index) => (
                      <TableRow
                        key={index}
                        product={product}
                        handleDelete={handleDelete}
                        handleDownload={handleDownload}
                        handleOptimize={handleOptimize}
                      />
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


export default Cyberdash;