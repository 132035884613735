import React, { useEffect, useRef, useState } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Navbar from '../../components/navbar/Navbar';

const PublicView = () => {
  const containerRef = useRef(null);
  const [fileType, setFileType] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  const { objectname } = useParams();
  const userInfo = useSelector((state) => state.auth);
  const userId = sessionStorage.getItem('userId');

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.post(
          `https://web-production-5ee8.up.railway.app/cyberlake/viewfile/${objectname}`,
          { userId :userId},
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + userInfo.token,
            },
          }
        );

        if (response.status === 200 && response.data.length > 0) {
          const [product] = response.data;
          setFileType(product.fileType);
          setFileUrl(product.fileUrl);
          setLoading(false);
        } else {
          alert('Unauthorized');
        }
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, [objectname, userInfo, userInfo.token, userId]);

  useEffect(() => {
    if (!fileUrl || !fileType) return;

    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    camera.position.z = 5;

    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize(window.innerWidth, window.innerHeight);
    containerRef.current.appendChild(renderer.domElement);

    const ambientLight = new THREE.AmbientLight(0xffffff);
    scene.add(ambientLight);

    let loader;
    switch (fileType) {
      case 'obj':
        loader = new OBJLoader();
        break;
      case 'gltf':
      case 'glb':
        loader = new GLTFLoader();
        break;
      case 'stl':
        loader = new STLLoader();
        break;
      case 'fbx':
        loader = new FBXLoader();
        break;
      default:
        console.error('Unsupported file type');
        return;
    }

    loader.load(fileUrl, (data) => {
      let object;
      if (fileType === 'gltf' || fileType === 'glb') {
        const gltfScene = data.scene;
        object = gltfScene;
      } else {
        object = data;
        if (!(object instanceof THREE.Object3D)) {
          console.error('Unsupported object type:', data);
          return;
        }
      }

      // Position the object in front of the camera
      object.position.z = -5;
      scene.add(object);
    });

    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true;
    controls.dampingFactor = 0.25;
    controls.enableZoom = true;

    const animate = () => {
      requestAnimationFrame(animate);
      controls.update();
      renderer.render(scene, camera);
    };

    animate();

    return () => {
      //containerRef.current.removeChild(renderer.domElement);
    };
  }, [fileUrl, fileType]);

  return (
    <div className="w-screen h-screen">
      <Navbar />
      {loading ? <p>Loading...</p> : <div ref={containerRef} className="w-full h-full" />}
    </div>
  );
};

export default PublicView;