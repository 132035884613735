import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { format, isValid, parseISO } from 'date-fns';
import { Edit2, Save, X } from 'lucide-react';

const CampaignSpecific = () => {
  const [campaignData, setCampaignData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editingEmail, setEditingEmail] = useState(null);
  const [saveLoading, setSaveLoading] = useState(false);
  const [validationError, setValidationError] = useState(null);
  const { campaign } = useParams();
  const { userInfo } = useSelector((state) => state.auth);

  useEffect(() => {
    fetchCampaignData();
  }, [campaign, userInfo._id]);

  const validateDateTime = (dateTimeStr) => {
    const date = parseISO(dateTimeStr);
    if (!isValid(date)) {
      throw new Error('Invalid date/time format');
    }
    
    const now = new Date();
    if (date < now) {
      throw new Error('Cannot schedule emails in the past');
    }
    
    return true;
  };

  const fetchCampaignData = async () => {
    try {
      const response = await axios.post('https://web-production-5ee8.up.railway.app/echidna/campinfo', {
        userId: userInfo._id,
        campaignName: campaign
      });
      
      const emailCopiesWithIds = response.data.emailCopies.map((email, index) => ({
        ...email,
        _id: email._id || `temp-id-${index}`
      }));
      
      setCampaignData({
        ...response.data,
        emailCopies: emailCopiesWithIds
      });
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch campaign data: ' + (err.response?.data?.error || err.message));
      setLoading(false);
    }
  };

  const handleEditEmail = (index) => {
    const emailToEdit = campaignData.emailCopies[index];
    setValidationError(null);
    setEditingEmail({
      ...emailToEdit,
      index,
      originalId: emailToEdit._id
    });
  };

  const handleSaveEmail = async () => {
    try {
      setSaveLoading(true);
      setError(null);
      setValidationError(null);

      // Validate required fields
      if (!editingEmail.mailSubject?.trim()) {
        throw new Error('Email subject is required');
      }
      if (!editingEmail.mailBody?.trim()) {
        throw new Error('Email body is required');
      }
      if (!editingEmail.mailTo?.trim()) {
        throw new Error('Recipient email is required');
      }
      if (!editingEmail.time) {
        throw new Error('Schedule time is required');
      }

      // Validate email format
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(editingEmail.mailTo)) {
        throw new Error('Invalid email address format');
      }

      // Validate date/time
      validateDateTime(editingEmail.time);

      const updatePayload = {
        userId: userInfo._id,
        campaignName: campaign,
        emailIndex: editingEmail.index,
        updatedEmail: {
          _id: editingEmail.originalId,
          mailSubject: editingEmail.mailSubject.trim(),
          mailBody: editingEmail.mailBody.trim(),
          mailTo: editingEmail.mailTo.trim(),
          time: new Date(editingEmail.time).toISOString(),
          sent: editingEmail.sent === true,
          userId: userInfo._id,
          campaign: campaign
        }
      };

      const response = await axios.post(
        'https://web-production-5ee8.up.railway.app/echidna/updateemailcopy',
        updatePayload
      );

      if (response.data.updatedEmail) {
        const updatedEmailCopies = [...campaignData.emailCopies];
        updatedEmailCopies[editingEmail.index] = {
          ...response.data.updatedEmail,
          _id: editingEmail.originalId
        };
        setCampaignData({
          ...campaignData,
          emailCopies: updatedEmailCopies
        });
        setEditingEmail(null);
      }
    } catch (err) {
      console.error('Error updating email:', err);
      setValidationError(err.message);
    } finally {
      setSaveLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValidationError(null);
    
    try {
      if (name === 'time') {
        validateDateTime(value);
      }
      
      setEditingEmail(prev => {
        if (name === 'sent') {
          return { ...prev, [name]: value === 'true' };
        }
        return { ...prev, [name]: value };
      });
    } catch (err) {
      setValidationError(err.message);
    }
  };

  if (loading) return <div className="flex items-center justify-center h-screen">Loading...</div>;
  if (!campaignData) return <div className="flex items-center justify-center h-screen">No campaign data found</div>;

  return (
    <div className="flex h-screen bg-white">
      {/* Error Display */}
      {(error || validationError) && (
        <div className="fixed top-4 right-4 z-50 max-w-sm bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded shadow-lg flex items-start justify-between">
          <div className="flex items-center">
            <svg className="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20">
              <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
            </svg>
            <span>{error || validationError}</span>
          </div>
          <button 
            onClick={() => {
              setError(null);
              setValidationError(null);
            }}
            className="ml-4 text-red-700 hover:text-red-900"
          >
            <X size={16} />
          </button>
        </div>
      )}

      {/* Sidebar */}
      <div className="w-64 bg-gray-800 text-white">
        <div className="p-4">
          <h1 className="text-2xl font-bold">Echidna campaign Dashboard</h1>
        </div>
       
      </div>

      {/* Main Content */}
      <div className="flex-1 overflow-y-auto">
        <header className="bg-white shadow">
          <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold text-gray-900">{campaignData.campaignName}</h1>
          </div>
        </header>

        <main className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          {/* Campaign Overview */}
          <div className="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200 mb-6">
            <div className="px-4 py-5 sm:px-6">
              <h2 className="text-lg font-medium text-gray-900">Campaign Overview</h2>
            </div>
            <div className="px-4 py-5 sm:p-6">
              <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Campaign Type</dt>
                  <dd className="mt-1 text-sm text-gray-900">{campaignData.campaignType}</dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Status</dt>
                  <dd className="mt-1 text-sm text-gray-900">{campaignData.status}</dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Lead Count</dt>
                  <dd className="mt-1 text-sm text-gray-900">{campaignData.leadCount}</dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Schedule Date</dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {format(new Date(campaignData.scheduleDate), 'PPP')}
                  </dd>
                </div>
              </dl>
            </div>
          </div>

          {/* Email Copies */}
          <div className="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200">
            <div className="px-4 py-5 sm:px-6">
              <h2 className="text-lg font-medium text-gray-900">Email Copies</h2>
            </div>
            <div className="px-4 py-5 sm:p-6">
              {campaignData.emailCopies.map((email, index) => (
                <div key={email._id || index} className="mb-8 pb-8 border-b border-gray-200 last:mb-0 last:pb-0 last:border-b-0">
                  <div className="flex justify-between items-center mb-4">
                    <h3 className="text-lg font-medium text-gray-900">Email {index + 1}</h3>
                    {editingEmail?.index === index ? (
                      <div className="flex items-center">
                        <button 
                          onClick={handleSaveEmail} 
                          disabled={saveLoading}
                          className={`mr-2 ${saveLoading ? 'text-gray-400' : 'text-green-600 hover:text-green-900'}`}
                        >
                          <Save size={20} />
                        </button>
                        <button 
                          onClick={() => setEditingEmail(null)} 
                          disabled={saveLoading}
                          className={`${saveLoading ? 'text-gray-400' : 'text-red-600 hover:text-red-900'}`}
                        >
                          <X size={20} />
                        </button>
                      </div>
                    ) : (
                      <button 
                        onClick={() => handleEditEmail(index)} 
                        className="text-blue-600 hover:text-blue-900"
                      >
                        <Edit2 size={20} />
                      </button>
                    )}
                  </div>

                  <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Subject</label>
                      {editingEmail?.index === index ? (
                        <input
                          type="text"
                          name="mailSubject"
                          value={editingEmail.mailSubject}
                          onChange={handleInputChange}
                          className="mt-1 focus:ring-blue-500 focus:border-blue-500 bg-white block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                      ) : (
                        <p className="mt-1 text-sm text-gray-900">{email.mailSubject}</p>
                      )}
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700">Recipient</label>
                      {editingEmail?.index === index ? (
                        <input
                          type="email"
                          name="mailTo"
                          value={editingEmail.mailTo}
                          onChange={handleInputChange}
                          className="mt-1 focus:ring-blue-500 focus:border-blue-500 bg-white block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                      ) : (
                        <p className="mt-1 text-sm text-gray-900">{email.mailTo}</p>
                      )}
                    </div>

                    <div>
        <label className="block text-sm font-medium text-gray-700">Scheduled Time</label>
        {editingEmail?.index === index ? (
          <div className="relative">
            <input
              type="datetime-local"
              name="time"
              value={editingEmail.time ? format(new Date(editingEmail.time), "yyyy-MM-dd'T'HH:mm") : ''}
              onChange={handleInputChange}
              min={format(new Date(), "yyyy-MM-dd'T'HH:mm")}
              className={`mt-1 block w-full rounded-md shadow-sm sm:text-sm
                ${validationError && validationError.includes('date') 
                  ? 'border-red-500 focus:ring-red-500 focus:border-red-500' 
                  : 'border-gray-300 focus:ring-blue-500 focus:border-blue-500'
                }`}
            />
            {validationError && validationError.includes('date') && (
              <p className="mt-1 text-sm text-red-600">{validationError}</p>
            )}
          </div>
        ) : (
          <p className="mt-1 text-sm text-gray-900">
            {format(new Date(email.time), 'PPP p')}
          </p>
        )}
      </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700">Status</label>
                      {editingEmail?.index === index ? (
                        <select
                          name="sent"
                          value={editingEmail.sent}
                          onChange={handleInputChange}
                          className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        >
                          <option value={false}>Pending</option>
                          <option value={true}>Sent</option>
                        </select>
                      ) : (
                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                          email.sent ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800'
                        }`}>
                          {email.sent ? 'Sent' : 'Pending'}
                        </span>
                      )}
                    </div>

                    <div className="sm:col-span-2">
                      <label className="block text-sm font-medium text-gray-700">Email Body</label>
                      {editingEmail?.index === index ? (
                        <textarea
                          name="mailBody"
                          rows="4"
                          value={editingEmail.mailBody}
                          onChange={handleInputChange}
                          className="mt-1 focus:ring-blue-500 bg-white focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                      ) : (
                        <p className="mt-1 text-sm text-gray-900 whitespace-pre-wrap">
                          {email.mailBody}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default CampaignSpecific;