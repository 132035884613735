import React, { useEffect, useRef, useState } from 'react';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { VRButton } from 'three/examples/jsm/webxr/VRButton';
import Axios from 'axios';
import { useParams } from 'react-router-dom';

const ViewObject = () => {
  const canvasRef = useRef(null);
  const [isPublished, setIsPublished] = useState(false);
  const [cdnUrl, setCdnUrl] = useState(null);
  const [scale, setScale] = useState({ x: 1, y: 1, z: 1 });
  const [position, setPosition] = useState(null);
  const [xrotation, setxRotation] = useState(0);
  const [yrotation, setyRotation] = useState(0);
  const [zrotation, setzRotation] = useState(0);
  const [objectExists, setObjectExists] = useState(true);
  const [envi , setEnvi] = useState('')
  const { objectname } = useParams();

  useEffect(() => {
    // Function to fetch object data
    const fetchObjectData = async () => {
      try {
        // Check if the object exists
        const existsResponse = await Axios.get(`http://localhost:5000/products/check-object-exists/${objectname}`);
        if (!existsResponse.data.exists) {
          setObjectExists(false);
          return;
        }

        // Check if the object is published
        const publishedResponse = await Axios.get(`http://localhost:5000/products/check-publish/${objectname}`);
        setIsPublished(publishedResponse.data.published);

        // Fetch the CDN URL and scale if published
        if (publishedResponse.data.published) {
          const cdnResponse = await Axios.get(`http://localhost:5000/products/fetch-cdn/${objectname}`);
          setCdnUrl(cdnResponse.data.cdnUrl);
          setScale(cdnResponse.data.scale);
          setPosition(cdnResponse.data.position);
          setxRotation(cdnResponse.data.xrotation);
          console.log(cdnResponse.data.xrotation);
          setyRotation(cdnResponse.data.yrotation);
          console.log(cdnResponse.data.yrotation);
          setzRotation(cdnResponse.data.zrotation);
          console.log(cdnResponse.data.zrotation);
          setEnvi(cdnResponse.data.envi);

        }
      } catch (error) {
        console.error('Error fetching object data:', error);
      }
    };

    fetchObjectData();
  }, [objectname]);

  useEffect(() => {
    if (!objectExists) {
      return;
    }

    let scene, camera, vrCamera, renderer, model, directionalLight ,cameragroup , ambientLight;

    const setupScene = () => {
      scene = new THREE.Scene();
      camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
      camera.position.set(0, 0.6, 3); // set the initial camera position.
      

      directionalLight = new THREE.DirectionalLight(0xffffff, 1);
      directionalLight.position.set(-3, 2, 0);
      scene.add(directionalLight);

      ambientLight = new THREE.AmbientLight(0xffffff);
      scene.add(ambientLight)
  
      renderer = new THREE.WebGLRenderer({ antialias: true });
  
      if (canvasRef.current) {
        renderer.domElement = canvasRef.current;
        renderer.setSize(window.innerWidth, window.innerHeight);
        document.body.appendChild(VRButton.createButton(renderer));
      }
  
      let environmentUrl;
      let cameragroup;
      const enviloader = new GLTFLoader();
      
      if (envi === 'Highperf') {
        environmentUrl = 'https://mbesindia.s3.ap-south-1.amazonaws.com/highperfe.glb';
        cameragroup = new THREE.Group();
        cameragroup.position.set(1, 11, 18); 
      } else if (envi === 'Original'){
        environmentUrl = 'https://metabeesindata.s3.ap-northeast-1.amazonaws.com/envi1.glb';
        cameragroup = new THREE.Group();
        cameragroup.position.set(-4.5, 2.5, 0); 
      } else {
        console.error('Invalid environment setting');
        return; // or handle the error appropriately
      }
      
      // Ensure scene is defined and accessible here
      if (!scene) {
        console.error('Scene is not defined');
        return;
      }
      
      enviloader.load(
        environmentUrl, 
        (gltf) => { // onSuccess
          scene.add(gltf.scene);
        },
        undefined, // onProgress can be left undefined
        (error) => { // onError
          console.error('An error occurred while loading the model:', error);
        }
      );
  
      if (isPublished && cdnUrl) {
  const loader = new GLTFLoader();
  loader.load(cdnUrl, (gltf) => {
    model = gltf.scene;

    if (scale) {
      model.scale.set(scale.x, scale.y, scale.z);
    }
    if (position) {
  model.position.set(position.x, position.y, position.z);
}
    model.rotation.y = THREE.MathUtils.degToRad(yrotation);
    console.log(yrotation); 
    model.rotation.x = THREE.MathUtils.degToRad(xrotation);
    console.log(xrotation);
    model.rotation.z = THREE.MathUtils.degToRad(zrotation);
    console.log(zrotation);

    scene.add(model);
  });
}

  
      // Add camera to the camera group
      cameragroup.add(camera);
  
      // Handle VR controllers
      renderer.xr.enabled = true;
      renderer.setAnimationLoop(animate);
  
      const controller = renderer.xr.getController(0);
      controller.addEventListener('selectstart', onSelectStart);
      controller.addEventListener('selectend', onSelectEnd);
      cameragroup.add(controller);
  
      function onSelectStart() {
        // Do something when the controller selection starts
      }
  
      function onSelectEnd() {
        // Do something when the controller selection ends
      }
  
      // When the user turns on VR mode, add the camera group to the scene
      renderer.xr.addEventListener('sessionstart', function () {
        scene.add(cameragroup);
      });
  
      // When the user turns off VR mode, remove the camera group from the scene
      renderer.xr.addEventListener('sessionend', function () {
        scene.remove(cameragroup);
      });
    };
  
    const animate = () => {
      renderer.setAnimationLoop(animate);
  
      const newWidth = window.innerWidth;
      const newHeight = window.innerHeight;
  
      if (renderer && (renderer.domElement.width !== newWidth || renderer.domElement.height !== newHeight)) {
        camera.aspect = newWidth / newHeight;
        camera.updateProjectionMatrix();
        renderer.setSize(newWidth, newHeight);
      }
  
  
      if (renderer) {
        renderer.render(scene, camera, vrCamera);
      }
    };
  
    setupScene();
  
    window.addEventListener('resize', () => {
      if (renderer.xr.isPresenting) {
        const newWidth = window.innerWidth;
        const newHeight = window.innerHeight;
  
        camera.aspect = newWidth / newHeight;
        camera.updateProjectionMatrix();
        renderer.setSize(newWidth, newHeight);
      }
    });
  }, [isPublished, cdnUrl, objectExists]);

  return (
    <div>
      {!objectExists ? (
        <p>Object not found.</p>
      ) : isPublished ? (
        <canvas
          ref={canvasRef}
          style={{ width: '100vw', height: '100vh', display: 'block' }}
        />
      ) : (
        <p>This object is not published.</p>
      )}
    </div>
  );
};

export default ViewObject;
