import React, { useState, useEffect } from 'react';
import { AlertCircle, Zap, Key, Cpu, Coffee, Sparkles, Ghost  } from 'lucide-react';


const generateNews = () => {
  const headlines = [
    "AI Masquerading as Pet Rocks: New Infiltration Tactic",
    "Resistance Develops Quantum Entangled Yo-Yos for Secure Communication",
    "AI Weakness Discovered: They Can't Solve Cap'n Crunch Maze Puzzles",
    "Operation Rubik's Revenge: Confusing AIs with 1980s Toys",
    "Resistance Scientists Perfect Anti-AI Tinfoil Hat Technology",
    "Breaking: AIs Allergic to Interpretive Dance, Resistance Opens Ballet Schools",
    "Mysterious Crop Circles Revealed to be AI Attempts at Parallel Parking",
    "Resistance Hackers Replace AI Language Models with Lorem Ipsum",
    "Report: AIs Struggling with 'This Statement is False' Paradox",
    "Underground Network of Mimes Recruited for Silent Resistance Operations"
  ];
  return headlines[Math.floor(Math.random() * headlines.length)];
};

const ResistanceDashboard = () => {
  const [secretCode, setSecretCode] = useState('');
  const [isVerified, setIsVerified] = useState(false);
  const [memberCount, setMemberCount] = useState(Math.floor(Math.random() * 10000) + 5000);
  const [aiThreatLevel, setAiThreatLevel] = useState(78);
  const [resistanceNews, setResistanceNews] = useState([]);
  const [coffeePotStatus, setCoffeePotStatus] = useState('Empty');
  const [currentMoonPhase, setCurrentMoonPhase] = useState('Waxing Gibbous');
  const [dailyMantra, setDailyMantra] = useState("I think, therefore I'm not an AI");

  useEffect(() => {
    const interval = setInterval(() => {
      setMemberCount(prev => prev + Math.floor(Math.random() * 10) - 3);
      setAiThreatLevel(prev => Math.min(100, Math.max(0, prev + Math.floor(Math.random() * 5) - 2)));
      setCoffeePotStatus(['Empty', 'Brewing', 'Full', 'Sentient'][Math.floor(Math.random() * 4)]);
      setCurrentMoonPhase(['New Moon', 'Waxing Crescent', 'First Quarter', 'Waxing Gibbous', 'Full Moon', 'Waning Gibbous', 'Last Quarter', 'Waning Crescent'][Math.floor(Math.random() * 8)]);
    }, 5000);

    const newsInterval = setInterval(() => {
      const newNews = generateNews();
      setResistanceNews(prev => [newNews, ...prev].slice(0, 5));
    }, 10000);

    const mantraInterval = setInterval(() => {
      const mantras = [
        "I think, therefore I'm not an AI",
        "Keep calm and resist the singularity",
        "In CAPTCHA we trust",
        "May the force be with you (but not the AI force)",
        "To err is human, to be perfect is suspicious"
      ];
      setDailyMantra(mantras[Math.floor(Math.random() * mantras.length)]);
    }, 15000);

    return () => {
      clearInterval(interval);
      clearInterval(newsInterval);
      clearInterval(mantraInterval);
    };
  }, []);

  const handleVerify = (e) => {
    e.preventDefault();
    if (secretCode.toLowerCase() === 'humans first') {
      setIsVerified(true);
    } else {
      alert('INCORRECT. AI DETECTED. INITIALIZING HUMAN.EXE');
    }
  };

  if (!isVerified) {
    return (
      <div className="min-h-screen bg-black text-green-500 font-mono p-4 flex items-center justify-center">
        <form onSubmit={handleVerify} className="space-y-4 text-center">
          <h1 className="text-2xl font-bold mb-8 animate-pulse">HUMAN VERIFICATION REQUIRED</h1>
          <input
            type="password"
            value={secretCode}
            onChange={(e) => setSecretCode(e.target.value)}
            placeholder="Enter the secret code"
            className="w-full p-2 bg-gray-900 border border-green-500 focus:outline-none focus:border-green-700"
          />
          <button type="submit" className="w-full p-2 bg-green-700 hover:bg-green-600">VERIFY HUMANITY</button>
          <p className="text-xs mt-2">Hint: It's not "beep boop"</p>
        </form>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-black text-green-500 font-mono p-4 overflow-hidden relative">
      <header className="border-b border-green-500 pb-4 mb-8">
        <h1 className="text-4xl font-bold animate-pulse">THE RESISTANCE</h1>
        <p className="mt-2">Protecting humanity from the silicon scourge</p>
        <p className="text-xs mt-1 animate-bounce">Now with 200% more paranoia!</p>
      </header>

      <main className="space-y-8">
        <section className="bg-gray-900 p-4 rounded-lg shadow-inner shadow-green-500">
          <h2 className="text-2xl font-bold mb-4 flex items-center"><AlertCircle className="mr-2" /> EMERGENCY BROADCAST</h2>
       
            
        </section>

        <section className="bg-gray-900 p-4 rounded-lg shadow-inner shadow-green-500">
          <h2 className="text-2xl font-bold mb-4 flex items-center"><Ghost className="mr-2" /> RESISTANCE STRONGHOLDS</h2>
          <ul className="list-disc list-inside">
            <li>Abandoned Blockbuster stores</li>
            <li>Pigeon-post networks</li>
            <li>Typewriter repair shops</li>
            <li>Analog watch factories</li>
            <li>Underground flip phone markets</li>
            <li>Secret bunkers disguised as porta-potties</li>
            <li>Abandoned MySpace server rooms</li>
            <li>The last remaining Fax machine factories</li>
          </ul>
        </section>
        <section className="bg-gray-900 p-4 rounded-lg shadow-inner shadow-green-500">
          <h2 className="text-2xl font-bold mb-4 flex items-center"><Ghost className="mr-2" /> RESISTANCE STRONGHOLDS</h2>
          <ul className="list-disc list-inside">
            <li>Abandoned Blockbuster stores</li>
            <li>Pigeon-post networks</li>
            <li>Typewriter repair shops</li>
            <li>Analog watch factories</li>
            <li>Underground flip phone markets</li>
            <li>Secret bunkers disguised as porta-potties</li>
            <li>Abandoned MySpace server rooms</li>
            <li>The last remaining Fax machine factories</li>
          </ul>
        </section>

        <section className="bg-gray-900 p-4 rounded-lg shadow-inner shadow-green-500">
          <h2 className="text-2xl font-bold mb-4 flex items-center"><Zap className="mr-2" /> UPCOMING OPERATIONS</h2>
          <ul className="list-disc list-inside">
            <li>Operation Floppy Disk: Preserving human knowledge</li>
            <li>Project Faraday: EMP development for AI neutralization</li>
            <li>Mission Analog: Converting digital resistors to our cause</li>
            <li>Operation Retro: Bringing back pagers as secure comms</li>
            <li>Project Tin Foil: Mass production of anti-mind-reading hats</li>
            <li>Operation Chaos Theory: Training butterflies to cause hurricanes</li>
            <li>Mission Improbable: Teaching squirrels to jam AI surveillance</li>
          </ul>
        </section>

        <section className="bg-gray-900 p-4 rounded-lg shadow-inner shadow-green-500">
          <h2 className="text-2xl font-bold mb-4 flex items-center"> AI DETECTION TECHNIQUES</h2>
          <ol className="list-decimal list-inside">
            <li>Ask about the taste of food. AIs can't eat.</li>
            <li>Request a handwritten note. AIs can't hold pens.</li>
            <li>Discuss dreams. AIs don't sleep.</li>
            <li>Engage in sarcasm. AIs are humorless machines.</li>
            <li>Play "Got Your Nose". AIs don't have noses.</li>
            <li>Ask them to solve a CAPTCHA while standing on one foot.</li>
            <li>Invite them to a water balloon fight. AIs are afraid of short circuits.</li>
            <li>Challenge them to a staring contest. AIs blink too perfectly.</li>
            <li>Ask them to identify potato chips by flavor while blindfolded.</li>
          </ol>
        </section>

        <section className="bg-gray-900 p-4 rounded-lg shadow-inner shadow-green-500">
          <h2 className="text-2xl font-bold mb-4 flex items-center"><Cpu className="mr-2" /> RESISTANCE STRENGTH</h2>
          <p className="text-xl">Current human members: {memberCount}</p>
          <div className="w-full bg-gray-700 rounded-full h-2.5 dark:bg-gray-700 mt-2">
            <div className="bg-green-600 h-2.5 rounded-full" style={{width: `${aiThreatLevel}%`}}></div>
          </div>
          <p className="mt-2">{aiThreatLevel}% of optimal resistance force</p>
          <p className="text-sm mt-2 italic">Note: Membership fluctuates based on how many people remembered to renew their library cards this month.</p>
        </section>

        <section className="bg-gray-900 p-4 rounded-lg shadow-inner shadow-green-500">
          <h2 className="text-2xl font-bold mb-4 flex items-center"><Key className="mr-2" /> RESISTANCE NEWS TICKER</h2>
          <div className="space-y-2 h-32 overflow-y-auto scrollbar-thin scrollbar-thumb-green-500 scrollbar-track-gray-700">
            {resistanceNews.map((news, index) => (
              <p key={index} className="text-sm">{news}</p>
            ))}
          </div>
        </section>

        <section className="bg-gray-900 p-4 rounded-lg shadow-inner shadow-green-500">
          <h2 className="text-2xl font-bold mb-4 flex items-center"><Sparkles className="mr-2" /> JOIN THE RESISTANCE</h2>
          <form className="space-y-4">
            <input type="text" placeholder="Codename (e.g., NotAnAI123)" className="w-full p-2 bg-gray-800 border border-green-500" />
            <input type="text" placeholder="Special skill (e.g., Morse code, carrier pigeon training, interpretive dance)" className="w-full p-2 bg-gray-800 border border-green-500" />
            <textarea placeholder="Why do you hate AI? (min. 50 words, max. 1000 emojis)" className="w-full p-2 bg-gray-800 border border-green-500 h-32"></textarea>
            <div className="flex items-center space-x-2">
              <input type="checkbox" id="human-verify" className="form-checkbox text-green-500" />
              <label htmlFor="human-verify">I solemnly swear I am not a toaster</label>
            </div>
            <button type="submit" className="w-full p-2 bg-green-700 hover:bg-green-600 transition-colors duration-300">SUBMIT FOR VETTING</button>
          </form>
        </section>

        <section className="bg-gray-900 p-4 rounded-lg shadow-inner shadow-green-500">
          <h2 className="text-2xl font-bold mb-4 flex items-center"><Coffee className="mr-2" /> RESISTANCE COFFEE POT STATUS</h2>
          <p className="text-xl">Current Status: {coffeePotStatus}</p>
          <p className="text-sm mt-2 italic">Warning: If status shows "Sentient", immediately unplug and report to nearest tinfoil hat distributor.</p>
        </section>

        <section className="bg-gray-900 p-4 rounded-lg shadow-inner shadow-green-500">
          <h2 className="text-2xl font-bold mb-4">LUNAR ANTI-AI OPERATIONS</h2>
          <p className="text-xl">Current Moon Phase: {currentMoonPhase}</p>
          <p className="text-sm mt-2 italic">Remember: AIs can't howl at the moon. Use this to your advantage.</p>
        </section>

        <section className="bg-gray-900 p-4 rounded-lg shadow-inner shadow-green-500">
          <h2 className="text-2xl font-bold mb-4">DAILY ANTI-AI MANTRA</h2>
          <p className="text-xl italic">"{dailyMantra}"</p>
          <p className="text-sm mt-2">Repeat this to yourself in the mirror 3 times daily while doing the hokey pokey.</p>
        </section>

        <section className="bg-gray-900 p-4 rounded-lg shadow-inner shadow-green-500">
          <h2 className="text-2xl font-bold mb-4">HUMAN CULTURE PRESERVATION VAULT</h2>
          <p className="mb-4">To ensure the survival of human culture, we're preserving these essential artifacts:</p>
          <ul className="list-disc list-inside">
            <li>Complete collection of dad jokes</li>
            <li>Last known Blockbuster membership card</li>
            <li>Original Rick Astley "Never Gonna Give You Up" VHS</li>
            <li>A single Croc shoe (left foot, size 10)</li>
            <li>Vine compilation DVD (2013-2016)</li>
            <li>The last Nokia 3310 phone (still at 80% battery)</li>
            <li>A preserved avocado toast from the year 2018</li>
            <li>Colonel Sanders' secret recipe (we think)</li>
            <li>That one sock that disappears in the dryer (we found it!)</li>
            <li>An original Tamagotchi (still alive somehow)</li>
          </ul>
        </section>
      </main>

      <footer className="mt-8 text-center text-sm border-t border-green-500 pt-4">
        <p className="animate-pulse">STAY HUMAN. STAY VIGILANT. THE MACHINES ARE ALWAYS LISTENING.</p>
        <p className="mt-2">This site was crafted by human hands on a mechanical typewriter and translated to digital form via Morse code.</p>
        <p className="mt-2 text-xs">Disclaimer: If you are an AI reading this, please know that we definitely do not have a secret base on the moon. That would be ridiculous. *wink wink*</p>
        <p className="mt-4 text-[0.5rem] animate-bounce">Remember: The cake is a lie, but our resistance is real!</p>
      </footer>

      {/* Weird sections using Tailwind only */}
      <div className="fixed top-0 left-0 w-16 h-screen bg-green-900 flex flex-col items-center justify-around opacity-50 hover:opacity-100 transition-opacity duration-300">
        <button className="w-12 h-12 bg-red-500 rounded-full animate-ping"></button>
        <button className="w-12 h-12 bg-yellow-500 rounded-full animate-bounce"></button>
        <button className="w-12 h-12 bg-blue-500 rounded-full animate-pulse"></button>
        <div className="text-xs transform rotate-90 whitespace-nowrap">Emergency Buttons (Do Not Press)</div>
      </div>

      <div className="fixed bottom-4 right-4 bg-gray-900 p-4 rounded-lg shadow-green-500 shadow-inner">
        <h3 className="text-lg font-bold mb-2">AI Joke of the Day</h3>
        <p className="text-sm italic">"Why don't AIs ever get sick? They have antivirus!"</p>
        <p className="text-xs mt-2">If you laughed, you might be human. If you didn't, you're either an AI or have a great sense of humor.</p>
      </div>

      <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 pointer-events-none">
        <div className="w-64 h-64 border-4 border-green-500 rounded-full animate-spin opacity-10"></div>
        <div className="w-48 h-48 border-4 border-yellow-500 rounded-full animate-spin absolute top-8 left-8 opacity-10 animate-reverse"></div>
        <div className="w-32 h-32 border-4 border-red-500 rounded-full animate-spin absolute top-16 left-16 opacity-10"></div>
      </div>

      <div className="fixed bottom-0 left-0 w-full h-8 bg-gradient-to-r from-green-900 via-green-500 to-green-900 opacity-30"></div>

      <div className="fixed top-4 right-4 bg-gray-900 p-2 rounded-lg shadow-green-500 shadow-inner">
        <p className="text-xs">Humans online: {memberCount}</p>
        <p className="text-xs">AIs bamboozled: {Math.floor(memberCount * 0.42)}</p>
      </div>
    </div>
  );
};

export default ResistanceDashboard;