import React, { useState, useEffect } from 'react';
import { CreditCard, ChevronRight } from 'lucide-react';
import axios from 'axios';
import { useSelector } from 'react-redux';

const TextGenPay = () => {
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [contact, setContact] = useState('');
  const [errors, setErrors] = useState({});
  const { userInfo } = useSelector((state) => state.auth);

  useEffect(() => {
    const loadRazorpay = () => {
      const script = document.createElement('script');
      script.src = 'https://checkout.razorpay.com/v1/checkout.js';
      script.async = true;
      document.body.appendChild(script);
    };
    loadRazorpay();
  }, []);

  const validateForm = () => {
    const errors = {};
    if (!name.trim()) errors.name = 'Name is required';
    if (!email.trim()) errors.email = 'Email is required';
    else if (!/\S+@\S+\.\S+/.test(email)) errors.email = 'Email is invalid';
    if (!contact.trim()) errors.contact = 'Contact number is required';
    else if (!/^\d{10}$/.test(contact)) errors.contact = 'Contact number should be 10 digits';
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleRegionSelect = (region) => {
    setSelectedRegion(region);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    const userId = userInfo._id;

    try {
      const response = await axios.post('http://localhost:5001/echidna/textgenpay', {
        name,
       userId,
        email,
        contact,
        amount: 50000, // 500 INR in paise
        description: 'TextGen Credits Purchase'
      });

      if (response.data.success) {
        const options = {
          key: response.data.key_id,
          amount: response.data.amount,
          currency: "INR",
          name: "TextGen Credits",
          description: response.data.description,
          image: "https://your-logo-url.com/logo.png",
          order_id: response.data.order_id,
          handler: function (response) {
            alert("Payment Succeeded");
            // Handle successful payment (e.g., update credits, redirect)
          },
          prefill: {
            name: response.data.name,
            email: response.data.email,
            contact: response.data.contact
          },
          notes: {
            description: response.data.description
          },
          theme: {
            color: "#3399cc"
          }
        };
        const razorpayObject = new window.Razorpay(options);
        razorpayObject.on('payment.failed', function (response) {
          alert("Payment Failed");
        });
        razorpayObject.open();
      } else {
        alert(response.data.msg);
      }
    } catch (error) {
      console.error('Error creating order:', error);
      alert('An error occurred. Please try again.');
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      {/* Top bar */}
      <div className="bg-white border-b px-4 py-2 flex items-center">
        <CreditCard className="text-blue-600 mr-2" size={24} />
        <h1 className="text-xl font-semibold">TextGen Pay</h1>
      </div>

      {/* Main content */}
      <div className="flex-grow flex items-center justify-center">
        <div className="bg-white p-8 rounded-lg shadow-xl w-96">
          {!selectedRegion ? (
            <>
              <h2 className="text-2xl font-bold mb-6 text-center">Select Your Region</h2>
              <div className="space-y-4">
                <button
                  onClick={() => handleRegionSelect('india')}
                  className="w-full flex items-center justify-between bg-white border border-gray-300 p-4 rounded-lg hover:bg-gray-50 transition duration-150 ease-in-out"
                >
                  <div className="flex items-center">
                    <img src="https://images.emojiterra.com/google/noto-emoji/unicode-15.1/color/svg/1f1ee-1f1f3.svg" alt="India Flag" className="w-8 h-8 mr-3" />
                    <span className="text-lg">India</span>
                  </div>
                  <ChevronRight size={20} className="text-gray-400" />
                </button>

                <button
                  onClick={() => handleRegionSelect('global')}
                  className="w-full flex items-center justify-between bg-white border border-gray-300 p-4 rounded-lg hover:bg-gray-50 transition duration-150 ease-in-out"
                >
                  <div className="flex items-center">
                    <h1>🌎</h1>
                    <span className="text-lg">Global</span>
                  </div>
                  <ChevronRight size={20} className="text-gray-400" />
                </button>
              </div>
            </>
          ) : selectedRegion === 'india' ? (
            <form onSubmit={handleSubmit} className="space-y-4">
              <h2 className="text-2xl font-bold mb-6 text-center">Enter Your Details</h2>
              <div>
                <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name</label>
                <input
                  type="text"
                  id="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                />
                {errors.name && <p className="mt-1 text-sm text-red-600">{errors.name}</p>}
              </div>
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                />
                {errors.email && <p className="mt-1 text-sm text-red-600">{errors.email}</p>}
              </div>
              <div>
                <label htmlFor="contact" className="block text-sm font-medium text-gray-700">Contact Number</label>
                <input
                  type="tel"
                  id="contact"
                  value={contact}
                  onChange={(e) => setContact(e.target.value)}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                />
                {errors.contact && <p className="mt-1 text-sm text-red-600">{errors.contact}</p>}
              </div>
              <div>
                <button type="submit" className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  Pay ₹500
                </button>
              </div>
            </form>
          ) : (
            <div className="text-center">
              <h2 className="text-2xl font-bold mb-4">Global Payment</h2>
              <p className="text-gray-600">Please select your preferred payment method on the next screen.</p>
              {/* Implement global payment logic here */}
            </div>
          )}
        </div>
      </div>

      {/* Footer */}
      <div className="bg-white border-t px-4 py-3 text-center text-gray-600">
        &copy; 2024 TextGen. All rights reserved.
      </div>
    </div>
  );
};

export default TextGenPay;