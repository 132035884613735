import React, { useState } from 'react';
import {
    Bell,
    User,
    Info,
    Settings,
    HelpCircle,
    BarChart,
    CloudLightning,
    Shield,
    Zap,
    Server,
    Globe,
    Clock,
    Activity,
    Mic,
    Image,
    Camera,
    Code,
    Video,
  } from 'lucide-react';
import logo from '../../assets/logo.png';

const AIModelDeployment = () => {
  const [selectedModel, setSelectedModel] = useState(null);
  const [selectedGPU, setSelectedGPU] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedFramework, setSelectedFramework] = useState(null);
  const [deploymentName, setDeploymentName] = useState('');
  const [autoScaling, setAutoScaling] = useState(false);
  const [instanceCount, setInstanceCount] = useState(1);
  const [deploymentMode, setDeploymentMode] = useState('production');
  const [monitoringLevel, setMonitoringLevel] = useState('basic');
  const [vpcConfig, setVpcConfig] = useState('default');
  const [securityGroup, setSecurityGroup] = useState('default');
  const [customDomain, setCustomDomain] = useState('');
  const [sslCertificate, setSslCertificate] = useState('');

  const handleDeploy = () => {
    console.log('Deploying:', {
      selectedModel,
      selectedGPU,
      selectedLocation,
      selectedFramework,
      deploymentName,
      autoScaling,
      instanceCount,
      deploymentMode,
      monitoringLevel,
      vpcConfig,
      securityGroup,
      customDomain,
      sslCertificate
    });
    // Add deployment logic here
  };

  const aiModels = [
    { id: 'gpt3', name: 'GPT-3', description: 'General-purpose language model', pricing: '$0.06 per 1K tokens', icon: <Zap className="h-6 w-6 text-yellow-500" /> },
    { id: 'gpt4', name: 'GPT-4', description: 'Advanced language model', pricing: '$0.12 per 1K tokens', icon: <Zap className="h-6 w-6 text-blue-500" /> },
    { id: 'dall-e', name: 'DALL-E 2', description: 'Image generation model', pricing: '$0.02 per image', icon: <CloudLightning className="h-6 w-6 text-purple-500" /> },
    { id: 'stable-diffusion', name: 'Stable Diffusion v1.5', description: 'Open-source image generation', pricing: '$0.01 per image', icon: <CloudLightning className="h-6 w-6 text-green-500" /> },
    { id: 'echidna', name: 'Echidna AI 🐍', description: 'Multi-modal AI model', pricing: '$0.15 per request', icon: <Shield className="h-6 w-6 text-red-500" /> },
    { id: 'bert', name: 'BERT Base', description: 'NLP model for text encoding', pricing: '$0.04 per 1K tokens', icon: <BarChart className="h-6 w-6 text-indigo-500" /> },
    { id: 'resnet', name: 'ResNet-50', description: 'Image classification model', pricing: '$0.05 per image', icon: <Activity className="h-6 w-6 text-orange-500" /> },
    { id: 'yolo', name: 'YOLOv5', description: 'Real-time object detection', pricing: '$0.08 per image', icon: <Activity className="h-6 w-6 text-pink-500" /> },
    { id: 'mistral', name: 'Mistral 7B', description: 'Multilingual language model', pricing: '$0.07 per 1K tokens', icon: <Globe className="h-6 w-6 text-teal-500" /> },
    { id: 't5', name: 'T5-Base', description: 'Text-to-text transfer learning model', pricing: '$0.03 per 1K tokens', icon: <Server className="h-6 w-6 text-green-500" /> },
    { id: 'whisper', name: 'Whisper Small', description: 'Speech recognition model', pricing: '$0.01 per minute', icon: <Mic className="h-6 w-6 text-blue-500" /> },
    { id: 'clip', name: 'CLIP ViT-B/32', description: 'Contrastive language-image pretraining model', pricing: '$0.06 per image', icon: <Image className="h-6 w-6 text-purple-500" /> },
    { id: 'deberta', name: 'DeBERTa v3', description: 'Enhanced language model', pricing: '$0.05 per 1K tokens', icon: <BarChart className="h-6 w-6 text-indigo-500" /> },
    { id: 'vit', name: 'ViT-B/16', description: 'Vision transformer model', pricing: '$0.04 per image', icon: <Camera className="h-6 w-6 text-orange-500" /> },
    { id: 'swin', name: 'Swin Transformer Tiny', description: 'Hierarchical vision transformer', pricing: '$0.05 per image', icon: <Camera className="h-6 w-6 text-pink-500" /> },
    { id: 'roberta', name: 'RoBERTa Base', description: 'Robustly optimized BERT model', pricing: '$0.04 per 1K tokens', icon: <BarChart className="h-6 w-6 text-teal-500" /> },
    { id: 'opt', name: 'OPT-125M', description: 'Open pre-trained transformer language model', pricing: '$0.07 per 1K tokens', icon: <Server className="h-6 w-6 text-green-500" /> },
    { id: 'llama', name: 'Llama 2 7B', description: 'Open-source large language model', pricing: '$0.08 per 1K tokens', icon: <Zap className="h-6 w-6 text-yellow-500" /> },
    { id: 'falcon', name: 'Falcon 7B', description: 'Open-source large language model', pricing: '$0.09 per 1K tokens', icon: <Zap className="h-6 w-6 text-blue-500" /> },
    { id: 'stable-code', name: 'Stable Code v1.0', description: 'Code generation model', pricing: '$0.06 per 1K tokens', icon: <Code className="h-6 w-6 text-purple-500" /> },
    { id: 'stable-video', name: 'Stable Video v1.0', description: 'Video generation model', pricing: '$0.10 per minute', icon: <Video className="h-6 w-6 text-green-500" /> },
  ];
  

  const gpuOptions = [
    { id: 'v100', name: 'NVIDIA Tesla V100', description: '16GB GPU memory', pricing: '$0.459 per hour' },
    { id: 'a100', name: 'NVIDIA A100', description: '40GB GPU memory', pricing: '$1.224 per hour' },
    { id: 't4', name: 'NVIDIA T4', description: '16GB GPU memory', pricing: '$0.263 per hour' },
    { id: 'k80', name: 'NVIDIA K80', description: '12GB GPU memory', pricing: '$0.142 per hour' },
    { id: 'a6000', name: 'NVIDIA A6000', description: '48GB GPU memory', pricing: '$1.530 per hour' },
    { id: 'rtx8000', name: 'NVIDIA RTX 8000', description: '48GB GPU memory', pricing: '$1.392 per hour' },
    { id: 'a40', name: 'NVIDIA A40', description: '48GB GPU memory', pricing: '$1.440 per hour' },
    { id: 'p100', name: 'NVIDIA Tesla P100', description: '16GB GPU memory', pricing: '$0.731 per hour' },
    { id: 'rtx2080ti', name: 'NVIDIA RTX 2080 Ti', description: '11GB GPU memory', pricing: '$0.300 per hour' },
    { id: 'rtx3080', name: 'NVIDIA RTX 3080', description: '10GB GPU memory', pricing: '$0.200 per hour' },
    { id: 'rtx3090', name: 'NVIDIA RTX 3090', description: '24GB GPU memory', pricing: '$0.400 per hour' },
    { id: 'quadro_rtx6000', name: 'NVIDIA Quadro RTX 6000', description: '24GB GPU memory', pricing: '$0.600 per hour' },
    { id: 'quadro_rtx8000', name: 'NVIDIA Quadro RTX 8000', description: '48GB GPU memory', pricing: '$1.000 per hour' },
    { id: 'quadro_rtx5000', name: 'NVIDIA Quadro RTX 5000', description: '16GB GPU memory', pricing: '$0.400 per hour' },
    { id: 'quadro_rtx4000', name: 'NVIDIA Quadro RTX 4000', description: '8GB GPU memory', pricing: '$0.300 per hour' },
  ];
  

  const locations = [
    { id: 'us-east', name: 'US East (N. Virginia)', description: 'Low latency for Eastern US', pricing: 'Standard rates' },
    { id: 'us-west', name: 'US West (Oregon)', description: 'Low latency for Western US', pricing: 'Standard rates' },
    { id: 'eu-central', name: 'Europe (Frankfurt)', description: 'Compliant with EU data regulations', pricing: '+5% premium' },
    { id: 'ap-south', name: 'Asia Pacific (Mumbai)', description: 'Low latency for Western India', pricing: '+10% premium' },
    { id: 'ap-southeast', name: 'Asia Pacific (Singapore)', description: 'Low latency for Southeast Asia', pricing: '+8% premium' },
    { id: 'sa-east', name: 'South America (São Paulo)', description: 'Low latency for South America', pricing: '+12% premium' },
    { id: 'in-delhi', name: 'India (Delhi)', description: 'Low latency for Northern India', pricing: '+10% premium' },
    { id: 'in-bengaluru', name: 'India (Bengaluru)', description: 'Low latency for Southern India', pricing: '+10% premium' },
  ];

  const frameworks = [
    { id: 'pytorch', name: 'PyTorch', description: 'Open source machine learning framework' },
    { id: 'tensorflow', name: 'TensorFlow', description: 'End-to-end open source platform for machine learning' },
    { id: 'keras', name: 'Keras', description: 'Deep learning API running on top of TensorFlow' },
    { id: 'mxnet', name: 'Apache MXNet', description: 'Flexible and efficient library for deep learning' },
    { id: 'onnx', name: 'ONNX', description: 'Open Neural Network Exchange' },
    { id: 'caffe', name: 'Caffe', description: 'Deep learning framework by Berkeley AI Research' },
  ];

  const SelectionGrid = ({ title, options, selectedOption, setSelectedOption }) => (
    <div className="mb-8">
      <h2 className="text-xl font-bold text-gray-900 mb-4">{title}</h2>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        {options.map((option) => (
          <div
            key={option.id}
            className={`border rounded-lg p-4 cursor-pointer transition-colors duration-150 ${
              selectedOption?.id === option.id
                ? 'border-blue-500 bg-blue-50'
                : 'border-gray-200 hover:border-blue-500'
            }`}
            onClick={() => setSelectedOption(option)}
          >
            <div className="flex items-center mb-2">
              {option.icon && <div className="mr-2">{option.icon}</div>}
              <h3 className="text-lg font-semibold text-gray-900">{option.name}</h3>
            </div>
            <p className="text-sm text-gray-600 mb-4">{option.description}</p>
            <div className="flex items-center justify-between">
              <span className="text-sm font-medium text-gray-900">{option.pricing}</span>
              <Info className="h-5 w-5 text-gray-400" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <div className="min-h-screen bg-gray-100">
      {/* Top navbar */}
      <nav className="bg-blue-900 text-white shadow-lg">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center">
              <img className="h-8 w-auto" src={logo} alt="Logo" />
              <div className="ml-4 flex items-baseline space-x-4">
                <a href="#" className="px-3 py-2 rounded-md text-sm font-medium bg-gray-800">Deploy</a>
                <a href="#" className="px-3 py-2 rounded-md text-sm font-medium">Models</a>
                <a href="#" className="px-3 py-2 rounded-md text-sm font-medium">Billing</a>
                <a href="#" className="px-3 py-2 rounded-md text-sm font-medium">Analytics</a>
                <a href="#" className="px-3 py-2 rounded-md text-sm font-medium">Documentation</a>
              </div>
            </div>
            <div className="flex items-center space-x-4">
              <Bell className="h-5 w-5 text-gray-400 hover:text-white cursor-pointer" />
              <Settings className="h-5 w-5 text-gray-400 hover:text-white cursor-pointer" />
              <HelpCircle className="h-5 w-5 text-gray-400 hover:text-white cursor-pointer" />
              <User className="h-5 w-5 text-gray-400 hover:text-white cursor-pointer" />
            </div>
          </div>
        </div>
      </nav>

      {/* Main content */}
      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <div className="px-4 py-6 sm:px-0">
          
          
          <div className="bg-white shadow-md rounded-lg p-6 mb-8">
  <h2 className="text-xl font-bold text-gray-900 mb-4">Deployment Configuration</h2>
  <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
    <div>
      <label htmlFor="deploymentName" className="block text-sm font-medium text-gray-700 mb-2">
        Deployment Name
      </label>
      <input
        type="text"
        id="deploymentName"
        className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md bg-white"
        placeholder="Enter deployment name"
        value={deploymentName}
        onChange={(e) => setDeploymentName(e.target.value)}
      />
    </div>
    <div>
      <label htmlFor="instanceCount" className="block text-sm font-medium text-gray-700 mb-2">
        Instance Count
      </label>
      <input
        type="number"
        id="instanceCount"
        className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md bg-white"
        min="1"
        value={instanceCount}
        onChange={(e) => setInstanceCount(parseInt(e.target.value))}
      />
    </div>
    <div>
      <label htmlFor="deploymentMode" className="block text-sm font-medium text-gray-700 mb-2">
        Deployment Mode
      </label>
      <select
        id="deploymentMode"
        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md bg-white"
        value={deploymentMode}
        onChange={(e) => setDeploymentMode(e.target.value)}
      >
        <option value="production">Production</option>
        <option value="staging">Staging</option>
        <option value="development">Development</option>
      </select>
    </div>
    <div>
      <label htmlFor="monitoringLevel" className="block text-sm font-medium text-gray-700 mb-2">
        Monitoring Level
      </label>
      <select
        id="monitoringLevel"
        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md bg-white"
        value={monitoringLevel}
        onChange={(e) => setMonitoringLevel(e.target.value)}
      >
        <option value="basic">Basic</option>
        <option value="advanced">Advanced</option>
        <option value="premium">Premium</option>
      </select>
    </div>
  </div>
  <div className="mt-4">
    <div className="flex items-center">
      <input
        id="autoScaling"
        type="checkbox"
        className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
        checked={autoScaling}
        onChange={(e) => setAutoScaling(e.target.checked)}
      />
      <label htmlFor="autoScaling" className="ml-2 block text-sm text-gray-900">
        Enable Auto-scaling
      </label>
    </div>
    <p className="mt-1 text-sm text-gray-500">Automatically adjust resources based on demand</p>
  </div>
</div>


          <SelectionGrid
            title="Choose an AI Model"
            options={aiModels}
            selectedOption={selectedModel}
            setSelectedOption={setSelectedModel}
          />

          <SelectionGrid
            title="Select GPU"
            options={gpuOptions}
            selectedOption={selectedGPU}
            setSelectedOption={setSelectedGPU}
          />

          <SelectionGrid
            title="Choose Location"
            options={locations}
            selectedOption={selectedLocation}
            setSelectedOption={setSelectedLocation}
          />

<SelectionGrid
            title="Select Framework"
            options={frameworks}
            selectedOption={selectedFramework}
            setSelectedOption={setSelectedFramework}
          />

<div className="bg-white shadow-md rounded-lg p-6 mb-8">
  <h2 className="text-xl font-bold text-gray-900 mb-4">Advanced Settings</h2>
  <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
    <div>
      <label htmlFor="vpcConfig" className="block text-sm font-medium text-gray-700 mb-2">
        VPC Configuration
      </label>
      <select
        id="vpcConfig"
        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md bg-white"
        value={vpcConfig}
        onChange={(e) => setVpcConfig(e.target.value)}
      >
        <option value="default">Default VPC</option>
        <option value="custom">Custom VPC</option>
      </select>
    </div>
    <div>
      <label htmlFor="securityGroup" className="block text-sm font-medium text-gray-700 mb-2">
        Security Group
      </label>
      <select
        id="securityGroup"
        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md bg-white"
        value={securityGroup}
        onChange={(e) => setSecurityGroup(e.target.value)}
      >
        <option value="default">Default Security Group</option>
        <option value="custom">Custom Security Group</option>
      </select>
    </div>
    <div>
      <label htmlFor="customDomain" className="block text-sm font-medium text-gray-700 mb-2">
        Custom Domain (optional)
      </label>
      <input
        type="text"
        id="customDomain"
        className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md bg-white"
        placeholder="Enter custom domain"
        value={customDomain}
        onChange={(e) => setCustomDomain(e.target.value)}
      />
    </div>
    <div>
      <label htmlFor="sslCertificate" className="block text-sm font-medium text-gray-700 mb-2">
        SSL Certificate (optional)
      </label>
      <input
        type="text"
        id="sslCertificate"
        className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md bg-white"
        placeholder="Enter SSL certificate ARN"
        value={sslCertificate}
        onChange={(e) => setSslCertificate(e.target.value)}
      />
    </div>
  </div>
</div>


          {/* Deploy button */}
          <div className="mt-8 flex justify-end">
            <button
              onClick={handleDeploy}
              className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
              disabled={!selectedModel || !selectedGPU || !selectedLocation || !selectedFramework || !deploymentName}
            >
              Deploy Model
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AIModelDeployment;